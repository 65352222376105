import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { EventBus } from "@/internal";
import { PurchaseOrderService } from "@/services/BatchTransferManager/PurchaseOrder.service";
import { messagesService } from "@/services/messages.service";
import { fnsFormatDate } from "@/utils/date-fns.utils";
import { Component, Mixins, Prop } from "vue-property-decorator";
import PurchaseOrderPrintComponent from "../../../BatchTransferList/PurchaseOrdersDataTable/print/PurchaseOrderPrint.component";
import TransferValidators from "../../TransferValidators.mixin";
import { FNS_DATE_FORMATS } from "./../../../../../../utils/date-fns.utils";
import Template from "./PurchaseOrderReviewPrint.template.vue";
@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "payment-breakdown-summary": PaymentBreakdownSummary
  },
  filters: {
    date: (date: string) => {
      return date
        ? fnsFormatDate(new Date(date), FNS_DATE_FORMATS.BARS_DEFAULT)
        : "--";
    }
  }
})
export default class PurchaseOrderReviewPrint extends Mixins(
  TransferStep,
  TransferValidators
) {
  @Prop() public transfer!: BatchTransfer.PurchaseOrder;

  /**
   * Gets source label
   */
  get sourceLabel() {
    return (this.transfer as BatchTransfer.PurchaseOrder).source_type ===
      "VENDOR_TRANSFER"
      ? "vendor"
      : "location";
  }

  /**
   * Gets show table label
   */
  get showTableLabel() {
    return this.transfer.pricing_enabled
      ? []
      : ["total", "sub_total", "tax", "price_per_unit"];
  }

  /**
   * Creates or updates a purchase order then prints it
   */
  public async save() {
    this.disabled = true;
    const isValid = await this.$validator.validateAll("purchase_order");
    let order;
    if (isValid) {
      if (this.$route.params.id) {
        order = await (this.service as PurchaseOrderService).update(
          this.transfer
        );
      } else {
        this.transfer.source_id = String(this.transfer.source_id);
        order = await (this.service as PurchaseOrderService).create(
          this.transfer
        );
      }

      if (!order) {
        this.disabled = false;
        return;
      }
      if (this.hasPermission(this.policyList.printPurchaseOrder)) {
        EventBus.$emit("print", {
          component: PurchaseOrderPrintComponent,
          props: {
            data: order.data
          }
        });
      }

      this.$router.push({ name: "batch-transfer" });
    } else {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.whole_process_failed")
        ),
        "red"
      );
      this.disabled = false;
    }
  }
}
