import { Batch } from "@/interfaces/batch";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import map from "lodash/map";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./TraceBatchesList.template.vue";

export enum TABLEACTIONS {
  EDIT,
  DELETE
}
const defaultActions: TABLEACTIONS[] = [TABLEACTIONS.EDIT, TABLEACTIONS.DELETE];
const tableHeaders = [
  {
    text: "product",
    value: "name"
  },
  {
    text: "product_category",
    value: "product_category"
  },
  {
    text: "batch_type",
    value: "batch_type"
  },
  {
    text: "Strain",
    value: "strain"
  },
  {
    text: "quantity",
    value: "quantity"
  },
  {
    text: "batch_id",
    value: "id"
  }
];
@Component({
  mixins: [Template]
})
export default class TraceBatchesList extends Vue {
  /**
   * Getter editable
   */
  public get editable() {
    return this.actions.indexOf(TABLEACTIONS.EDIT) > -1;
  }

  /**
   * Getter filtered headers
   */
  protected get filteredHeaders() {
    return tableHeaders.filter(
      cellIndex => this.cellsToHide.indexOf(cellIndex.value) === -1
    );
  }
  /**
   * Getter filtered items
   */
  public get filteredItems() {
    return tableHeaders
      .filter(
        cellIndex =>
          this.cellsToHide.indexOf(cellIndex.value) === -1 &&
          cellIndex.value !== "actions"
      )
      .map(batch => batch.value);
  }

  /**
   * Getter table data
   */
  public get tableData() {
    this.fillDataTable();
    return {
      items: this.items,
      headers: this.headers
    };
  }
  @Prop({ default: false }) public hideAction: boolean = false;
  @Prop({ default: undefined }) public pagination!: {};
  @Prop({ default: undefined }) public loading!: boolean;
  @Prop() protected batches!: BatchTransfer.Batch[];
  @Prop({ default: () => defaultActions }) protected actions!: TABLEACTIONS[];
  @Prop({ default: () => [] }) protected cellsToHide!: string[];
  @Prop({ default: "" }) protected fromComponent!: string;
  protected items: any[] = [];
  protected headers: any[] = [];
  /**
   * Emits batch index to be edited.
   * @param {number} index
   */
  public edit(index: number) {
    this.$emit("edit", index);
  }

  @Watch("pagination", { deep: true, immediate: true })
  public handler() {
    this.onChangePagination();
  }

  protected onChangePagination() {
    this.$emit("onChangePagination", this.pagination);
  }
  protected mounted() {
    if (this.actions.length === 0) {
      this.cellsToHide.push("actions");
    }

    this.fillDataTable();
  }
  /**
   * Fills data table. Parses batches to match table structure.
   */
  protected fillDataTable() {
    this.headers = this.filteredHeaders.map(batchIndex => {
      batchIndex.text = String(this.$t(batchIndex.text));
      return batchIndex;
    });
    this.items = map(this.batches, (batch: Batch) => {
      return {
        name: batch.product_variant.name,
        product_category:
          (batch.product_variant.category &&
            batch.product_variant.category!.name) ||
          "",
        batch_type:
          (batch.product_variant.batch_type &&
            batch.product_variant.batch_type.name) ||
          "",
        strain: batch.product_variant.strain
          ? batch.product_variant.strain.name
          : "",
        quantity: batch.in_store_quantity_value,
        id: batch.batch_uid,
        is_multiple_room: batch.is_multiple_room,
        is_reserved: batch.is_reserved
      };
    });
  }
}
