import { BiotrackTraceNotification } from "@/interfaces/notification";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "../CustomTraceabilityConfirm/CustomTraceabilityConfirm.template.vue";
@Component({
  mixins: [Template]
})
export default class CustomTraceabilityConfirm extends Vue {
  @Prop({ required: true })
  public modalData!: BiotrackTraceNotification;
  public accept() {
    this.$emit("resolve", true);
  }
}
