import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchDetailsContainerComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsContainer/BatchDetailsContainer.component";
import BatchDetailsContainerSectionComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsSection/BatchDetailsSection.component";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import LabDetailsForm from "@/components/inventory/BatchTransfer/BuildBlocks/LabDetailsForm/LabDetailsForm.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import PricingBreakdownForm from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownForm/PricingBreakdownForm.component";
import PricingBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownSummary/PricingBreakdownSummary.component";
import {
  NewBatchDefault,
  NewBatchItemDefault,
  TransferDefault
} from "@/components/inventory/BatchTransfer/declarations";
import { EventBus } from "@/event-bus";
import {
  BatchTransfer,
  PRICE_POINTS_TYPE,
  PRODUCT_UNIT,
  TRANSFER_SOURCE
} from "@/interfaces/batchTransferManager";
import { messagesService } from "@/services/messages.service";
import differenceInDays from "date-fns/differenceInDays";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Mixins } from "vue-property-decorator";
import ComputeTransfer from "../../ComputeTransfer.mixin";
import { batchDetailsModelKeys } from "../../ModelKeys";
import Template from "./NewBatchBatchDetails.template.vue";

interface StepModel {
  items: BatchTransfer.NewBatchItem[];
  notes: BatchTransfer.TransferNote[];
  prices: BatchTransfer.TransferPrices;
}
@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "pricing-breakdown-form": PricingBreakdownForm,
    "pricing-breakdown-summary": PricingBreakdownSummary,
    "payment-breakdown-summary": PaymentBreakdownSummary,
    "batch-details-container": BatchDetailsContainerComponent,
    "batch-detail-section": BatchDetailsContainerSectionComponent,
    HelixDatePickerComponent,
    "lab-details-form": LabDetailsForm
  }
})
export default class NewBatchBatchDetails extends Mixins(
  TransferStep,
  ComputeTransfer,
  StepsData
) {
  public weighableUnits: Array<{ label: string; value: PRODUCT_UNIT }> = [
    { label: "Grams", value: PRODUCT_UNIT.GRAMS },
    { label: "Milligrams", value: PRODUCT_UNIT.MG },
    { label: "Ounces", value: PRODUCT_UNIT.OZ }
  ];

  public modelKeys = batchDetailsModelKeys;
  public model: StepModel = pick(
    cloneDeep(NewBatchDefault),
    this.modelKeys
  ) as StepModel;
  public selectedBatch: number = -1;
  public editingCache!: BatchTransfer.NewBatchItem;
  public batchDetailForm = false;
  public pickerOptions: Partial<HelixDatePickerOptions> = {
    "picker-options": {
      disabledDate: (date: Date) => {
        return differenceInDays(new Date(), date) > 0;
      }
    },
    "value-format": "yyyy-MM-dd"
  };
  public updateQuantityDebounced = debounce(
    async (quantity: number, context: NewBatchBatchDetails) => {
      context.editingBatch.rooms![0].quantity = +quantity;
      context.editingBatch = (await context.resetProduct(
        context.editingBatch,
        true
      )) as BatchTransfer.NewBatchItem;
    },
    500
  );

  public get isExistingInventory() {
    return this.transfer.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER;
  }
  public get isCannabisProduct() {
    const isCannabis =
      this.editingBatch.product && this.editingBatch.product.marijuana;

    if (isCannabis) {
      this.setPackageWeightLimit();
    }

    return isCannabis;
  }
  /**
   * Checks if current product type is present on the limits config
   * if so, then the package_weight is required
   */
  public get requirePackageWeight(): boolean {
    return !!(
      this.editingBatch &&
      this.editingBatch.product &&
      this.editingBatch.product.batch_type &&
      this.packageWeightTypes &&
      this.packageWeightTypes.includes(this.editingBatch.product.batch_type_id!)
    );
  }

  public async validateAndSave() {
    if (await this.$validator.validateAll("edit-batch")) {
      this.validateListAndEmitChanges();
    }
  }
  public addNewBatch() {
    const batch = cloneDeep(NewBatchItemDefault);
    batch.id = Date.now();
    if (this.defaultRoom) {
      batch.rooms![0].room_id = this.defaultRoom.id!;
    }
    this.model.items.push(batch);
    this.selectedBatch = this.model.items.length - 1;
    this.isNew = true;
  }

  public editBatch(id: number) {
    this.selectedBatch = this.model.items.findIndex(batch => batch.id === id);
    const item = this.model.items[this.selectedBatch];
    this.productsList = [item.product!];
    if (item.tax_category) {
      this.taxCategoriesList = [item.tax_category];
    }
    this.isNew = false;
  }

  public cancelEdition() {
    if (this.transfer.items.length) {
      this.selectedBatch = -1;
      this.model.items = this.model.items.filter(item => item.sku);
    }
  }

  public removeBatch(index: number) {
    this.model.items = this.model.items.filter(item => item.id !== index);
    this.transferChanges();
  }

  public async changeProduct() {
    this.editingBatch.product =
      (this.editingBatch.sku &&
        this.productsList.find(p => p.sku === this.editingBatch.sku)) ||
      undefined;
    this.editingBatch = (await this.resetProduct(
      this.editingBatch
    )) as BatchTransfer.NewBatchItem;
  }

  public get saveLabel() {
    return this.selectedBatch >= 0 && !this.isNew
      ? "update"
      : "batch_transfer_manager_module.labels.add_batch";
  }

  public get editingBatch(): BatchTransfer.NewBatchItem {
    if (!this.modelItems.length) {
      this.addNewBatch();
    }
    const lineItem =
      (this.model.items[this.selectedBatch] as BatchTransfer.NewBatchItem) ||
      false;
    const calculated = lineItem
      ? (this.recalculateBatchPrices(lineItem) as BatchTransfer.NewBatchItem)
      : false;
    return calculated ? calculated : lineItem;
  }

  public set editingBatch(batch: BatchTransfer.NewBatchItem) {
    const items = cloneDeep(this.model.items);
    items[this.selectedBatch] = this.recalculateBatchPrices(
      batch
    ) as BatchTransfer.NewBatchItem;
    this.model.items = items;
  }

  public nullTaxCategory(collected: number) {
    const clone = this.voidTaxCategory(this.editingBatch);
    clone.prices.taxCollected = collected;
    this.editingBatch = clone as BatchTransfer.NewBatchItem;
  }

  public updateQuantity(val: number) {
    this.updateQuantityDebounced(val, this);
  }
  /**
   * Updates editing batch
   */
  public async updateEditingBatch(avoid: boolean) {
    this.editingBatch = (await this.resetProduct(
      this.editingBatch,
      avoid
    )) as BatchTransfer.NewBatchItem;
  }

  public setResults(results: BatchTransfer.LabResults) {
    const batch = cloneDeep(this.editingBatch);
    batch.lab_results = results;
    this.editingBatch = batch;
  }

  public async setTax() {
    this.editingBatch = (await this.setupBatchTaxCategories(
      this.editingBatch
    )) as BatchTransfer.NewBatchItem;
    this.editingBatch = this.processTaxCategory(
      this.editingBatch,
      this.editingBatch.tax_category!
    ) as BatchTransfer.NewBatchItem;
  }

  protected mounted() {
    // when resource is changed , we need to re assign default values
    EventBus.$on("changeInResource", () => {
      this.model.items = [];
      this.transfer = cloneDeep(TransferDefault);
      this.transferChanges();
      this.addNewBatch();
      this.$validator.reset();
    });
    this.pricePointType = PRICE_POINTS_TYPE.WHOLESALE_INBOUND;
    this.loadBatchLevels();
    this.loadProducts();
    this.loadTaxCategories();
    this.loadRooms().then(() => {
      this.defaultRoom = this.roomList.find(
        room => room.room_type === "BACKSTOCK_ROOM"
      );
      this.editingBatch.rooms = [
        {
          room_id: (this.defaultRoom && this.defaultRoom.id) || -1,
          quantity: 1
        }
      ];
    });
  }

  protected validateStep() {
    if (this.model.items.find(item => !item.sku)) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.missing_form_data")
        ),
        "error"
      );
      return;
    }
    if (!this.transfer.items.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("batch_transfer_manager_module.messages.no_items")),
        "warning"
      );
      return;
    }
    this.nextStep();
    this.transferItems.map(item => item.product!.marijuana).includes(1);
    EventBus.$emit(
      "isCannabisBatches",
      this.transferItems.map(item => item.product!.marijuana).includes(1)
    );
  }

  protected validateListAndEmitChanges() {
    this.selectedBatch = -1;
    this.transferChanges();
  }
}
