import cloneDeep from "lodash/cloneDeep";

import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TransferCollectionMixin extends Vue {
  public transfersCollection: BatchTransfer.Transfer[] = [];
  @Prop() protected transfers!: string;

  /**
   * Adds to collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public addToCollection(transfer: BatchTransfer.Transfer) {
    transfer.uid =
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9);
    this.transfersCollection = [...this.transfersCollection, transfer];
  }
  /**
   * Updates on collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public updateOnCollection(transfer: BatchTransfer.Transfer) {
    const index = transfer.uid
      ? this.transfersCollection.findIndex(t => t.uid === transfer.uid)
      : this.transfersCollection.findIndex(t => t.id === transfer.id);
    this.transfersCollection[index] = cloneDeep(transfer);
  }
  /**
   * Removes from collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public removeFromCollection(transfer: BatchTransfer.Transfer) {
    const filteredCollection = transfer.uid
      ? this.transfersCollection.filter(
          persisted => persisted.uid !== transfer.uid
        )
      : this.transfersCollection.filter(
          persisted => persisted.id !== transfer.id
        );
    if (filteredCollection.length < this.transfersCollection.length) {
      this.transfersCollection = filteredCollection;
    }
  }
  /**
   * Gets transfer to edit
   * @param {BatchTransfer.Transfer} transfer
   * @returns A deep copy of the transfer.
   */
  public getTransferToEdit(transfer: BatchTransfer.Transfer) {
    return cloneDeep(
      this.transfersCollection.find(persisted => persisted.id === transfer.id)
    );
  }

  /**
   * Created hook transfer collection mixin
   */
  protected created() {
    this.transfersCollection = !!this.transfers
      ? JSON.parse(this.transfers)
      : [];
  }
}
