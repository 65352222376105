import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import { EventBus } from "@/event-bus";
import { BatchSample, InventoryBatch, PrintBarcode } from "@/interfaces/batch";
import { PrintLabelTableMetadata } from "@/metadata/printNewBatch";
import { i18n } from "@/plugins/i18n";
import NewBatchTransferService from "@/services/BatchTransferManager/NewBatchTransfer.service";
import { labResultsService } from "@/services/labResults.service";
import { batchFormatedforLabResults } from "@/utils/batch-actions.utils";
import {
  TableComponent,
  TableFieldType,
  TableHeader,
  TableItem,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./NewBatchPrint.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export default class NewBatchPrint extends Vue {
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public pagination: TablePagination = {
    totalItems: 0,
    itemsPerPage: 5,
    lastPage: 20,
    itemsPerPageOptions: [5, 10, 15, 20],
    currentPage: 1,
    from: 1,
    to: 5
  };
  public paginationDefault: TablePagination = cloneDeep(this.pagination);
  public headers: TableHeader[] = PrintLabelTableMetadata;
  public batchSamples: BatchSample | null = null;
  public loadingSamples = false;
  public item = {};
  public printData: InventoryBatch[] = [];
  public printTableData: PrintBarcode[] = [];
  public defaultSelectedItems: number[] = [];
  public selected: number = 0;
  public noOfStrain = 0;
  public loading = false;
  public customApiCall!: string;
  public transactionId!: string;

  public finish() {
    this.$router.push({
      name: "batch-transfer",
      params: { type: "inbound-transfer" }
    });
  }
  public get rowActions() {
    return [
      {
        icon: "fal fa-barcode-alt",
        tooltip: String(i18n.t("print_barcode")),
        action: this.printBarcode
      },
      {
        icon: "fal fa-print",
        tooltip: String(i18n.t("print_label.print_inventory_lable")),
        action: this.printLabel
      }
    ];
  }
  public setTableData(item: PrintBarcode[]) {
    const isSelected = true;
    const newBatchPrint = true;
    // @ts-ignore
    this.printTableData = item.map((batch: PrintBarcode) => {
      if (this.customApiCall === "TraceNewBatchBatchDetails") {
        batch.product = batch.product_variant;
        batch.quantity_value! = batch.in_store_quantity_value;
      }
      if (batch.product!.strain) {
        this.noOfStrain++;
      }
      return {
        ...batch,
        product_name:
          batch!.product!.name +
          `(${batch.quantity_value! + batch.quantity_unit})`,
        batch_uid:
          this.bioTrackTraceEnabled && batch.product!.marijuana === 1
            ? batch.batch && batch.batch!.biotrack_traceability_id
            : batch.batch_uid,
        product_category: batch.product ? batch.product.category!.name : "",
        batch_type: batch.product!.batch_type!.name,
        strain: batch.product!.strain ? batch.product!.strain.name : "-",
        quantity: batch.quantity_value!,
        id: batch.id,
        barcodes: "-",
        inventory_labels: "-",
        is_selected: isSelected,
        name: batch!.product!.name,
        sku: batch.sku,
        weighable: batch!.product!.requires_weighing,
        product: batch!.product,
        product_variant: batch!.product,
        new_batch_print: newBatchPrint,
        new_batch_id: batch.biotrack_traceability_id || "",
        old_batch_id: batch.batch_uid
      };
    });
    this.printTableData.forEach(data => {
      if (data.strain && data.strain !== "-") {
        this.noOfStrain++;
      }
    });
    if (this.noOfStrain === 0) {
      this.headers = this.headers.filter(
        (header: any) => header.value !== "strain"
      );
    }
    if (!this.customApiCall) {
      if (this.printTableData.length < 5) {
        this.pagination.to = this.printTableData.length;
      } else {
        this.pagination.to = 5;
      }
    }
  }
  public selectedBatch(data: PrintBarcode) {
    const index = this.printTableData.findIndex(item => item.id === data.id);
    return index;
  }
  public onSelectItem(data: TableItem) {
    if (data.eventItem) {
      const index = this.selectedBatch(data.eventItem.item);
      if (index !== -1) {
        this.printTableData[index].is_selected = data.eventItem.status;
      }
    } else {
      data.currentSelection.map((items: PrintBarcode) => {
        const index = this.selectedBatch(items);
        if (index !== -1) {
          this.printTableData[index].is_selected = data.checkAll;
        }
      });
    }
    this.selected = data.currentSelection.length;
    this.printData = data.currentSelection;
  }
  public get tableData() {
    if (this.customApiCall === "TraceNewBatchBatchDetails") {
      return this.printTableData;
    } else {
      this.pagination.totalItems = this.printTableData.length;
    }
    return (
      (this.pagination &&
        chunk(this.printTableData, this.pagination.itemsPerPage)[
          this.pagination.currentPage - 1
        ]) ||
      []
    );
  }
  public onSort(header: TableHeader) {
    this.printTableData = orderBy(
      this.printTableData,
      header.value,
      header.descending ? "desc" : "asc"
    );
  }
  public onChangePage(pagination: TablePagination) {
    if (this.customApiCall === "TraceNewBatchBatchDetails") {
      this.pagination = pagination;
      this.getAllBatches();
    }
    this.pagination.currentPage = pagination.currentPage;
    const from =
      (pagination.currentPage - 1) * this.pagination.itemsPerPage + 1;
    const to =
      (pagination.currentPage - 1) * this.pagination.itemsPerPage +
      this.tableData.length;
    this.pagination.totalItems = this.printTableData.length;
    this.pagination.from = (from > 0 && from) || 0;
    this.pagination.to = (to > 0 && to) || 0;
  }
  /**
   * Prints barcode
   */
  public printBarcode(item: PrintBarcode) {
    if (this.customApiCall === "TraceNewBatchBatchDetails") {
      item = cloneDeep({
        ...item,
        batch_uid: item.biotrack_traceability_id! || item.batch_uid || ""
      });
    }
    EventBus.$emit("print", {
      component: BarcodeListComponent,
      props: {
        batches: [item]
      }
    });
  }

  public async getSamples() {
    if (!this.batchSamples && this.item && !this.loadingSamples) {
      this.loadingSamples = true;
      this.batchSamples = await labResultsService.getBatchSamples(
        this.printTableData[0].batch_uid!
      );
      this.loadingSamples = false;
    }
  }

  public async printLabel(item: PrintBarcode) {
    if (this.customApiCall === "TraceNewBatchBatchDetails") {
      item = cloneDeep({
        ...item,
        batch_uid: item.biotrack_traceability_id! || item.batch_uid || ""
      });
    }
    await this.getSamples();
    const hasLab = !!this.batchSamples!.laboratory;
    EventBus.$emit("print", {
      component: InventoryLabelListComponent,
      props: {
        batches: [
          {
            ...item,
            laboratory: hasLab
              ? batchFormatedforLabResults(this.batchSamples!)
              : null,
            sample: hasLab ? this.batchSamples : null,
            product: item,
            fatherItem: {
              laboratory_name: hasLab
                ? this.batchSamples!.laboratory!.name
                : "N/A",
              laboratory_license: hasLab
                ? this.batchSamples!.laboratory!.license
                : "N/A",
              laboratory_testing_date: hasLab
                ? this.batchSamples!.testing_date
                : "N/A"
            }
          }
        ],
        labelType: "INVENTORY"
      }
    });
  }
  public mounted() {
    EventBus.$on(
      "loadingPrintLabels",
      (value: boolean) => (this.loading = value)
    );
    EventBus.$on("newBatchPrintResponse", (code: any) => {
      this.setTableData(code);
    });
    EventBus.$on(
      "onChangeCustomApiCall",
      (response: {
        changeNewBatchReviewComponent: string;
        transactionId: string;
      }) => {
        this.customApiCall = response.changeNewBatchReviewComponent;
        this.transactionId = response.transactionId;
        if (this.customApiCall === "TraceNewBatchBatchDetails") {
          this.assignCurrentInventoryBatches();
        }
      }
    );
  }

  protected assignCurrentInventoryBatches() {
    this.pagination.itemsPerPage = 10;
    this.getAllBatches();
    this.headers[5].label = i18n.t("print_label.old_batch_id").toString();
    this.headers[5].value = "old_batch_id";
    this.headers[6] = {
      value: "new_batch_id",
      label: i18n.t("print_label.new_batch_id").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true
    };
  }

  protected async getAllBatches() {
    this.loading = true;
    const response = await NewBatchTransferService.getAllBatches(
      this.pagination!.currentPage,
      this.pagination!.itemsPerPage,
      "",
      false,
      "filterbatches",
      "yes",
      "yes",
      this.transactionId
    );
    if (response) {
      this.pagination.totalItems = response.data.total;
      this.pagination.from = response.data.from;
      this.pagination.to = response.data.to;
      this.setTableData(response.data.data);
    }
    this.loading = false;
    return response;
  }
  protected destroyed() {
    EventBus.$off("onChangeCustomApiCall");
  }
}
