import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import PaymentBreakdown from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdown/PaymentBreakdown.component";
import { OutboundTransferDefault } from "@/components/inventory/BatchTransfer/declarations";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { paymentMethodsModelKeys } from "../../ModelKeys";
import Template from "./OutboundTransferPaymentMethods.template.vue";

interface StepModel {
  payment_methods_breakdown: BatchTransfer.PaymentMethodsBreakdown;
}

@Component({
  mixins: [Template],
  components: {
    "payment-breakdown": PaymentBreakdown
  }
})
export default class OutboundTransferPaymentMethods extends Mixins(
  TransferStep
) {
  @Prop() public collection!: BatchTransfer.OutboundTransfer[];

  public modelKeys = paymentMethodsModelKeys;
  public model: StepModel = pick(
    OutboundTransferDefault,
    this.modelKeys
  ) as StepModel;

  /**
   * Gets payments
   */
  public get payments() {
    return this.transfer.payment_methods_breakdown;
  }

  /**
   * Payments change handler
   * @param {BatchTransfer.PaymentMethodsBreakdown }payments
   */
  public paymentsChange(payments: BatchTransfer.PaymentMethodsBreakdown) {
    this.model = { payment_methods_breakdown: cloneDeep(payments) };
    this.transferChanges();
  }

  /**
   * Adds new transfer
   */
  public addNewTransfer() {
    this.transferChanges();
    delete this.$route.params.batches;
    if (this.editing) {
      this.updateOnCollection(this.transfer);
    } else {
      this.saveToCollection(this.transfer);
    }
  }

  /**
   * Adds a new transfer and emits createManifest
   */
  public createManifest() {
    this.addNewTransfer();
    this.$emit("createManifest");
  }

  protected mounted() {
    this.setModel();
  }

  /**
   * Sets model
   */
  protected setModel() {
    if (this.payments) {
      this.paymentsChange(this.payments);
    }
  }
}
