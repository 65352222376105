import {
  BatchSamplesModel,
  Laboratory,
  LaboratoryResult,
  SampleResultProfile
} from "@/interfaces/batch";
import { defaultLaboratoryResult } from "@/interfaces/batchTransferManager";
import { batchService } from "@/services/batch.service";
import { messagesService } from "@/services/messages.service";
import { Callback } from "@/types/types";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./LabDetailsForm.template.vue";

enum ProfileBreakdown {
  potency = "POTENCY",
  terpene = "TERPENE"
}
const namespace: string = "BatchModule";

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class LabDetailsForm extends Vue {
  @Prop({ required: true })
  public samples!: BatchSamplesModel;
  @Prop() public disabled!: boolean;

  @Action("findSampleResultProfiles", { namespace })
  public findSampleResultProfilesAction!: Callback;

  @Getter("sampleResultProfiles", { namespace })
  public sampleResultProfiles!: SampleResultProfile[];
  public labs: Laboratory[] = [];
  public dateMenu = false;
  public units: string[] = ["%", "mg/g"];
  public currentLabResult: LaboratoryResult = { ...defaultLaboratoryResult };
  public isLoadingLaboratories: boolean = false;
  public dLabsSearch!: Callback;
  public dProfilesSearch!: Callback;
  public isLoadingResultProfiles: boolean = false;
  public searchLaboratoriesField: string = "";
  // public profiles: SampleResultProfile[] = [];

  /**
   * Debounced lab search for autocomplete field.
   */
  public debounceLabSearch = debounce(async (scope: any, labName: string) => {
    if (!labName || labName.length) {
      scope.isLoadingLaboratories = true;
      const query =
        typeof labName === "number"
          ? { "q[id_equals]": labName }
          : { "q[name_contains]": labName };
      scope.labs = await batchService.getLaboratories(query);
      scope.isLoadingLaboratories = false;
    }
  }, 500);
  public get pickerOptions(): Partial<HelixDatePickerOptions> {
    return {
      required: true,
      "value-format": "yyyy-MM-dd",
      disabled: this.disabled
    };
  }

  /**
   * Adds new result
   */
  public async addNewResult() {
    const pickerIsValid = await (this.$refs[
      "hlx-datepicker"
    ] as HelixDatePickerComponent).validate();
    const validator = await this.$validator.validateAll();
    if (validator && pickerIsValid) {
      this.$validator.pause();
      this.model.results.push(
        pick(this.currentLabResult, [
          "profile_id",
          "profile",
          "value",
          "unit_representation"
        ])
      );

      this.currentLabResult = {
        ...defaultLaboratoryResult
      };
      setTimeout(this.$validator.resume.bind(this.$validator));
      this.$emit("updateLabResults", this.model);
    }
  }

  /**
   * Removes result
   * @param {string} type. "potencies" or "terpenes"
   * @param {number} index
   */
  public removeResult(result: LaboratoryResult) {
    const elementAt = this.model.results.findIndex(
      r => r.profile_id === result.profile_id
    );
    if (elementAt !== -1) {
      this.model.results.splice(elementAt, 1);
      this.$emit("updateLabResults", this.model);
    }
  }

  /**
   * Watchs search laboratory autocomplete.
   * @param {string} labName
   */
  @Watch("searchLaboratoriesField")
  public async searchLaboratories(labName: string) {
    this.debounceLabSearch(this, labName);
  }

  /**
   * Gets profiles
   */
  public async getProfiles() {
    try {
      this.isLoadingResultProfiles = true;
      await this.findSampleResultProfilesAction();
      this.isLoadingResultProfiles = false;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public get profiles(): SampleResultProfile[] {
    return this.samples && this.samples.results.length
      ? this.sampleResultProfiles.filter(
          s => !this.samples.results.find(rs => rs.profile_id === s.id)
        )
      : this.sampleResultProfiles;
  }

  public mounted() {
    this.debounceLabSearch(this, "");
    if (!this.sampleResultProfiles.length) {
      this.getProfiles();
    }
  }

  /**
   * Handler for current Lab result profile.
   */
  public async setSampleProfileId() {
    this.currentLabResult.profile_id =
      this.currentLabResult.profile! && this.currentLabResult.profile!.id;
  }

  public get model() {
    return cloneDeep(this.samples);
  }

  public get formatnewResultDate() {
    return this.currentLabResult.testing_date
      ? fnsFormatDate(
          this.currentLabResult.testing_date,
          FNS_DATE_FORMATS.BARS_DEFAULT
        )
      : "";
  }

  /**
   * Gets profile placeholder
   */
  public get profilePlaceholder() {
    return (
      (this.currentLabResult.profile && this.currentLabResult.profile.type) ||
      this.$t("select_type")
    );
  }

  /**
   * Gets breakdown data
   */
  public get breakdownData() {
    return this.samples.results.reduce(
      (
        acc: { potencies: LaboratoryResult[]; terpenes: LaboratoryResult[] },
        result
      ) => {
        if (result.profile) {
          if (result.profile.breakdown === ProfileBreakdown.potency) {
            acc.potencies.push(result);
          } else {
            acc.terpenes.push(result);
          }
        }
        return acc;
      },
      {
        potencies: [],
        terpenes: []
      }
    );
  }
}
