import { Batch, InventoryBatch } from "@/interfaces/batch";
import { Brand } from "@/interfaces/brand";
import { Category } from "@/interfaces/category";
import { Location } from "@/interfaces/location";
import { OrderItem } from "@/interfaces/order";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { ProductCategory } from "@/interfaces/productCategoy";
import { TaxCategory } from "@/interfaces/taxCategory";
import { Type } from "@/interfaces/type";
import { Vendor } from "@/interfaces/vendor";
import { labResultTable } from "@/metadata/batch";
import { BooleanCheck } from "helix-vue-components/types";
import { BatchType } from "./batchType";
import { Strain } from "./strain";

export interface Product {
  atLocation?: boolean;
  rooms?: any;
  state_reporting_type?: any;
  addedNew?: boolean;
  fromApi?: boolean;
  editedProduct?: boolean;
  tax_category_id?: number | null;
  lab_results?: string | any;
  prices?: any;
  inventorytype?: string | number;
  barcode_id?: string;
  currentProduct?: string;
  sku: string;
  account_id: string;
  batches: Batch[] | InventoryBatch[];
  brand: Brand | null;
  brand_id: number | null;
  category: Category | null;
  concrete_sku: string;
  created_at: string | null;
  barcode?: string | null;
  deleted_at: string | null;
  deleted_by: string | null;
  id: number | null;
  require_inventory: number;
  location_products?: Location[];
  locationProducts?: LocationProduct[];
  lasting_effect_time: string;
  lasting_effect_time_unit: string;
  ingredients: string;
  allergens: string;
  marijuana: number;
  medicated: number;
  member_discount: number;
  member_only: number;
  name: string;
  price: number;
  product_category_id: string | null;
  product_id: number | null;
  serving_size_of_the_container: number | null;
  serving_size: number | null;
  serving_size_unit: string;
  strain_id: string | null;
  strain: Strain | null;
  suggested_amount: number | null;
  tax_id: number | null;
  tax?: object | null | number | string; // to assign to prices
  type: Type | null;
  updated_at: string | null;
  vendor: Vendor | null;
  vendor_id: number | null;
  requires_weighing: number;
  orderItem?: OrderItem;
  shareable?: boolean;
  metrc_weighable?: boolean;
  metrc_item_association?: string | null;
  nutrition_information: string | null;
  usable_weight_value?: number | null;
  batch_type?: BatchType | null;
  batch_type_id?: string | null;
  pricing: PricingRule[];
  total_quantity?: string | number;
  price_settings: PriceSetting;
  packaging_detail: PackageDetail | null;
  prescription_detail: PrescriptionDetails | null;
  thc?: {
    min?: number;
    max?: number;
  };
  cbd?: {
    min?: number;
    max?: number;
  };
  sampleResults?: SampleResult[];
  cost?: Cost;
  images?: { [key: string]: any } | null;
  image_thumb_url?: string;
  file?: File;
  image?: File;
  _method?: string;
  room_quantity?: string;
  room_id?: number;
  usable_weight_unit?: "g" | "mg" | "oz" | "u" | null;
  unit: "g" | "mg" | "oz" | "u" | null;
  weight_per_unit_unit: "g" | "mg" | "oz" | "u" | null;
  weight_per_unit_value?: number | null;
  quantity?: number;
  description?: string;
  media?: ProductMedia[];
  nameQty?: string;
  status?: {
    published: boolean | null;
    is_active: boolean;
    touched: 0 | 1;
    disabled: boolean | null;
  };
  has_batches?: boolean;
  ndc_number?: string;
  activation_time: string | number;
  activation_time_unit: string;
  nutrition_total_calories?: number | null;
  nutrition_calories_per_serving?: number | null;
  available_reserved?: string | null;
  member_discount_eligible?: boolean;
  product?: {
    marijuana?: number;
    batch_type_id?: string | null;
    unit?: "g" | "mg" | "oz" | "u" | null;
    sku?: string;
    strain?: {
      name?: string;
    };
  };
}

export interface Cost {
  current_total_cost: number;
  average_cost_per_unit: number;
  total_potential_profit: number;
}

export interface SampleResult {
  type?: string;
  unit?: string;
  value?: number | null;
  name?: string;
  minValue: number;
  maxValue: number;
  breakdown?:
    | "POTENCY"
    | "TERPENE"
    | "MOISTURE_CONTENT"
    | "FOREIGN_MATTER_INSPECTION"
    | "MICROBIOLOGICAL_SCREENING"
    | "RESIDUAL_SOLVENT"
    | "MYCOTOXIN_SCREENING"
    | "PESTICIDE_RESIDUE"
    | "HEAVY_METAL";
}

export interface Cost {
  current_total_cost: number;
  average_cost_per_unit: number;
  total_potential_profit: number;
}

export interface LocationProduct {
  location_id?: number | null;
  product_category_id?: string | null;
  price?: number | null;
  tax_id?: number | null;
  locationProductId?: number | null;
}

export interface PriceSetting {
  id: number | null;
  cost_per_unit: number | null;
  price_point_by_usable_weight: number;
  block_item_from_being_sold_below_cost_per_unit: number;
  member_discount_eligible: number;
  block_item_from_being_sold_at_zero_cost: number;
  members_only_product: number;
  pricing_type: string;
  price_group?: string | null;
  pricing_group_id: number | string | null;
  product_id?: number | null;
  location_id?: number | null;
  account_id?: number;
  created_by?: string | null;
  updated_by?: string | null;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  uid?: string | null;
}

export interface PackageDetail {
  id: number | null;
  ingredients: string;
  allergens: string;
  manufacturer_id?: number | null;
  cultivator_id?: number | null;
  activation_time?: string | number;
  activation_time_unit: string;
  lasting_effect_time: string;
  lasting_effect_time_unit: string;
  suggested_amount: number | null;
  recreational_customer_label_template_id?: number | null;
  medical_customer_label_template_id?: number | null;
  inventory_label_template_id?: number | null;
  serving_size: number | null;
  serving_size_unit: string;
  serving_size_of_the_container: number | null;
  product_id: number | null;
  created_by?: string | null;
  updated_by?: string | null;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  nutrition_information: string | null;
  nutrition_total_calories?: number | null;
  nutrition_calories_per_serving?: number | null;
}

export interface ProductToAudit {
  id?: number;
  item?: string;
  metrc_id?: string;
  new?: number;
  difference?: number;
  batches?: BatchToAudit[];
  original?: number;
  status?: string;
  unit?: string;
  category?: ProductCategory;
}

export interface BatchToAudit {
  id?: number;
  item?: string | number;
  metrc_id?: string;
  new?: number;
  difference?: number;
  original?: number;
  reason_note?: string;
  status?: string;
}

export const packagingDetailsDefault = {
  id: null,
  ingredients: "",
  allergens: "",
  manufacturer_id: null,
  cultivator_id: null,
  activation_time: "",
  activation_time_unit: "",
  lasting_effect_time: "",
  lasting_effect_time_unit: "",
  recreational_customer_label_template_id: null,
  medical_customer_label_template_id: null,
  inventory_label_template_id: null,
  suggested_amount: null,
  serving_size: null,
  serving_size_unit: "",
  serving_size_of_the_container: null,
  product_id: null,
  nutrition_information: "",
  nutrition_total_calories: null,
  nutrition_calories_per_serving: null
};

export const priceSettingsDefault = {
  id: null,
  cost_per_unit: null,
  price_point_by_usable_weight: 0,
  block_item_from_being_sold_below_cost_per_unit: 0,
  member_discount_eligible: 0,
  block_item_from_being_sold_at_zero_cost: 0,
  members_only_product: 0,
  pricing_type: "CUSTOM",
  pricing_group_id: null,
  uid: ""
};

export const productDefault: Product = {
  sku: "",
  account_id: "",
  batches: [],
  brand: null,
  brand_id: null,
  category: null,
  concrete_sku: "",
  product_category_id: null,
  created_at: null,
  deleted_at: null,
  deleted_by: null,
  id: null,
  require_inventory: 0,
  marijuana: 1,
  medicated: 0,
  member_discount: 0,
  member_only: 0,
  name: "",
  price: 0,
  strain_id: null,
  strain: null,
  tax_id: null,
  tax: {},
  type: null,
  updated_at: null,
  vendor: null,
  vendor_id: null,
  requires_weighing: 1,
  shareable: false,
  metrc_weighable: false,
  metrc_item_association: null,
  pricing: [],
  product_id: null,
  price_settings: priceSettingsDefault,
  packaging_detail: packagingDetailsDefault,
  prescription_detail: defaultPrescriptionDetails,
  usable_weight_unit: "g",
  unit: "g",
  weight_per_unit_unit: "g",
  barcode: null,
  description: "",
  ndc_number: "",
  activation_time: "",
  activation_time_unit: "",
  lasting_effect_time: "",
  lasting_effect_time_unit: "",
  ingredients: "",
  allergens: "",
  serving_size_of_the_container: null,
  serving_size: null,
  serving_size_unit: "",
  suggested_amount: null,
  nutrition_information: null,
  inventorytype: "",
  barcode_id: "",
  currentProduct: "",
  product: {
    batch_type_id: "",
    unit: null,
    sku: "",
    strain: {
      name: ""
    }
  },
  lab_results: "",
  fromApi: false,
  editedProduct: false
};

export interface PriceModel {
  quantity: number;
  price: number;
  post_tax?: number;
  pre_excise?: number;
  pricing_type?: string;
  tax_category_id: number | null;
  member_level_code: string | null;
  member_level_name?: string | null;
  member_discount_id: number | null;
  tax_category?: TaxCategory;
  isEditing?: boolean;
  editingIndex?: number;
}

export const defaultPriceModel: PriceModel = {
  quantity: 0,
  price: 0,
  post_tax: 0,
  pre_excise: 0,
  tax_category_id: null,
  member_level_code: null,
  member_discount_id: null
};

export interface PricingRule {
  id?: number | null;
  pricing_type:
    | "RETAIL_MEDICAL"
    | "RETAIL_RECREATIONAL"
    | "WHOLESALE_INBOUND"
    | "WHOLESALE_OUTBOUND"
    | "RETAIL_MEDICAL_RECREATIONAL";
  tax_category_id?: number;
  member_level_code?: string | null;
  is_active: 0 | 1;
  // Only for price break rules
  price_breaks?: Pricebreak[];
  // Only for mark-up price rules
  effect?: string;
  rate?: number;
  // probably not needed
  _destroy?: boolean;
  member_level_name?: string | null;
  unit?: string;
  unit_price?: number;
  pricing_style?: string | null;
}

export interface Pricebreak {
  id?: number;
  price: number;
  quantity: number;
  pre_excise?: number;
  post_tax?: number;
  member_level_name?: string | null;
  taxes?: number;
}

export interface PriceGroup {
  id?: number;
  name: string;
  location_id?: number;
  account_id?: number;
  created_at?: string;
  updated_at?: string;
  is_visible: 1 | 0;
  group_type?: string;
  uid?: string;
  rule_type: "PRICE_BREAK_RULE" | "MARKUP_RULE";
  is_active: 1 | 0;
  rules: PricingRule[] | MarkupRule[];
  price_group_item?: {
    sku: string;
  };
}
export interface PriceGroupRules {
  is_active: boolean;
  code: string;
  location_id: number | null;
  rules: PricingRule[];
}

export interface PriceTab {
  title: string;
  type: string;
  isWholesale?: boolean;
  ruleModel?: Partial<MarkupRule | PricingRule>;
}

export interface MarkupRule {
  id?: number | null;
  is_active: 0 | 1;
  pricing_type:
    | "RETAIL_MEDICAL"
    | "RETAIL_RECREATIONAL"
    | "WHOLESALE_INBOUND"
    | "WHOLESALE_OUTBOUND"
    | "RETAIL_MEDICAL_RECREATIONAL";
  effect: "INCREASING" | "DECREASING";
  rate: number;
}

export interface PriceGroupModel {
  id?: number | string;
  uid?: string;
  name: string;
  rule_type: "PRICE_BREAK_RULE" | "MARKUP_RULE";
  markup_rules: MarkupRule[];
  break_rules: PricingRule[];
}

export interface ProductMedia {
  id: number;
  media_content_type: string;
  media_file_name: string;
  media_file_size: number;
  media_medium_url: string;
  media_original_url: string;
  media_thumb_url: string;
  media_updated_at: string;
  media_variants: string;
}

export interface TaxDetails {
  quantity: string;
  price: number;
  preTax: number;
  postPreTax: number;
  normalTax: number;
  postNormalTax: number;
  excisePostTax: number;
  post_tax?: number;
}

export interface ProductPricing {
  code: string;
  rule_type: string;
  rules: PricingRule[];
  pricing: PricingRule[];
}

export interface ProductCost {
  current_total_cost: number;
  average_cost_per_unit: number;
  total_potential_profit: number;
}

export interface BatchLevelAssign {
  id: number;
  code: string;
  pricing_group_id: number;
}

export interface CompiledPrices {
  sku: string;
  concrete_sku: string;
  is_active: 0 | 1;
  pricing: PricingRule[];
  unit: string;
}
export interface MinPrice {
  quantity: number;
  price_pre_tax: number;
  price: number;
  price_post_tax: number;
  batch_level_price: string | null;
  post_formatted?: string;
  pre_formatted?: string;
}
export interface ProductBasicInfo {
  name: string;
  strain_id?: string | null;
  batch_type_id?: string | null;
  product_category_id?: string | null;
  brand_id?: number | null;
  vendor_id?: number | null;
  barcode?: string | null;
  ndc_number?: string;
  description?: string;
  marijuana: number;
  requires_weighing: number;
  require_inventory: number;
  metrc_weighable: boolean;
  usable_weight_value: number | null;
  usable_weight_unit?: "g" | "mg" | "oz" | "u" | null;
  weight_per_unit_value: number | null;
  weight_per_unit_unit: "g" | "mg" | "oz" | "u" | null;
}

export interface ScanResult {
  products: Product[];
  status:
    | "FOUND"
    | "FOUND_IN_OTHER_ROOMS"
    | "FOUND_WITH_ZERO_QUANTITY"
    | "NOT_FOUND";
}
