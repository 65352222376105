import BatchDetailsContainerSectionComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsSection/BatchDetailsSection.component";
import { Component, Vue } from "vue-property-decorator";
import Template from "./BatchDetailsContainer.template.vue";

@Component({
  mixins: [Template],
  components: {
    BatchDetailsContainerSectionComponent
  }
})
export default class BatchDetailsContainerComponent extends Vue {}
