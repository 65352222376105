import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const PrintLabelTableMetadata: TableHeader[] = [
  {
    value: "product_name",
    label: i18n.t("product").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    size: 15
  },
  {
    value: "product_category",
    label: i18n.t("print_label.product_category").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    size: 10.5
  },
  {
    value: "batch_type",
    label: i18n.t("print_label.batch_type").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    size: 10
  },
  {
    value: "strain",
    label: i18n.t("print_label.strain").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    size: 8
  },
  {
    value: "quantity",
    label: i18n.t("print_label.quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    size: 10
  },
  {
    value: "batch_uid",
    label: i18n.t("print_label.batch_id").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true,
    size: 12
  }
];
