import { BatchDefault } from "@/components/inventory/BatchTransfer/declarations";
import { currencyFilter } from "@/filters/currency.filter";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES
} from "@/interfaces/batchTransferManager";
import cloneDeep from "lodash/cloneDeep";
import { Validator } from "vee-validate";
import { Component, Inject, Mixins, Prop, Watch } from "vue-property-decorator";
import ComputeTransfer from "../../BatchTransferManager/ComputeTransfer.mixin";
import Template from "./PricingBreakdownForm.template.vue";

@Component({
  mixins: [Template]
})
export default class PricingBreakdownForm extends Mixins(ComputeTransfer) {
  @Inject("validator") public $validator!: Validator;

  @Prop({ required: true })
  public batch!: BatchTransfer.Batch;
  @Prop({ default: false })
  public disabled!: boolean;
  protected prices: BatchTransfer.BatchPrices = BatchDefault.prices;

  protected discountFeeTypes: Array<{
    label: string;
    value: DISCOUNT_FEE_TYPES;
  }> = [
    { label: "$", value: DISCOUNT_FEE_TYPES.FIXED },
    { label: "%", value: DISCOUNT_FEE_TYPES.PERCENTAGE }
  ];

  /**
   * Gets batch price
   */
  public get batchPrice() {
    return +String(
      (this.prices.price_per_unit * this.batch.quantity).toFixed(2)
    );
  }
  /**
   * Sets batch price
   */
  public set batchPrice(value: number) {
    this.prices.price_per_unit = value / this.batch.quantity;
    this.onChange();
  }

  /**
   * Applies currency filter and returns its result.
   * @param {number} value
   * @returns {string} A string resulting from the filter.
   */
  public asCurrency(value: number) {
    const filtered = currencyFilter(value, 2).replace(/\$|,/g, "");
    return parseFloat(filtered);
  }

  /**
   * Emits change event.
   */
  public onChange() {
    this.$emit("change", {
      ...cloneDeep(this.batch),
      ...{ prices: this.prices }
    });
  }

  /**
   * Overrides tax collected
   * @param {number} value
   */
  public overrideTaxCollected(value: number) {
    this.$emit("overrideTaxCollected", Number(value));
  }

  protected mounted() {
    this.prices = cloneDeep(this.batch.prices);
  }

  /**
   * Watchs batch price.
   * @param {BatchTransfer.BatchPrices} prices
   */
  @Watch("batch.prices", { deep: true })
  protected onPricesExternalChange(prices: BatchTransfer.BatchPrices) {
    this.prices = cloneDeep(prices);
  }
}
