import { EventBus } from "@/event-bus";
import { currencyFilter } from "@/filters/currency.filter";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES
} from "@/interfaces/batchTransferManager";
import { Room } from "@/interfaces/room";
import InboundTransferService from "@/services/BatchTransferManager/InboundTransfer.service";
import { productService } from "@/services/product.service";
import map from "lodash/map";
import { LOG10E } from "mathjs";
import { Component, Prop, Vue } from "vue-property-decorator";
import InboundAddNewProduct from "../InboundAddNewProduct/InboundAddNewProduct.component";
import Template from "./InboundBatchList.template.vue";
export enum INBOUNDTABLEACTIONS {
  EDIT,
  DELETE
}
const defaultActions: INBOUNDTABLEACTIONS[] = [
  INBOUNDTABLEACTIONS.EDIT,
  INBOUNDTABLEACTIONS.DELETE
];

const tableHeadersforInbound = [
  {
    text: "product",
    value: "name",
    sortable: false
  },
  {
    text: "batch_id",
    value: "id",
    sortable: false
  },
  {
    text: "quantity",
    value: "quantity",
    sortable: false
  },
  {
    text: "sub_total",
    sortable: false,
    value: "sub_total"
  },
  {
    text: "tax",
    sortable: false,
    value: "tax"
  },
  {
    text: "total",
    sortable: false,
    value: "total"
  },
  {
    text: "cost_per_unit",
    sortable: false,
    value: "cost_per_unit",
    width: "120",
    tooltip: "batch_transfer_manager_module.messages.not_accurate_cost"
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];
@Component({
  mixins: [Template],
  components: { InboundAddNewProduct }
})
export default class InboundBatchList extends Vue {
  /**
   * Getter editable
   */
  public get editable() {
    return this.actions.indexOf(INBOUNDTABLEACTIONS.EDIT) > -1;
  }
  /**
   * Getter removable
   */
  public get removable() {
    return this.actions.indexOf(INBOUNDTABLEACTIONS.DELETE) > -1;
  }

  /**
   * Getter filtered headers
   */
  protected get filteredHeaders() {
    if (
      this.bioTrackTraceEnabled === true &&
      (this.sourceType === "LOCATION_TRANSFER" ||
        this.sourceType === "CANNABIS_VENDOR_TRANSFER")
    ) {
      return tableHeadersforInbound.filter(header => {
        return this.cellsToHide.indexOf(header.value) === -1;
      });
    }
  }
  /**
   * Getter filtered items
   */
  public get filteredItems() {
    if (
      this.bioTrackTraceEnabled === true &&
      (this.sourceType === "LOCATION_TRANSFER" ||
        this.sourceType === "CANNABIS_VENDOR_TRANSFER")
    ) {
      return tableHeadersforInbound
        .filter(
          header =>
            this.cellsToHide.indexOf(header.value) === -1 &&
            header.value !== "actions"
        )
        .map(item => item.value);
    }
  }

  /**
   * Getter table data
   */
  public get tableData() {
    this.fillDataTable();
    this.items.forEach((item: { strain: string }) => {
      if (item.strain && item.strain !== "-") {
        this.noOfStrain++;
      }
    });
    if (this.noOfStrain === 0) {
      this.items.forEach((item: any) => delete item.strain);
    }
    return {
      items: this.items,
      headers: this.headers
    };
  }
  public sortEnabled: boolean = false;
  @Prop() public defaultRoom?: Room;
  @Prop() public page!: boolean;
  @Prop() public addNewButton!: boolean;
  @Prop() public length!: any;
  @Prop() public sourceType!: string;
  @Prop() public bioTrackTraceEnabled!: boolean;
  @Prop({ default: false }) public hideAction: boolean = false;
  public noOfStrain = 0;
  public selectedInventoryType = [];
  public selectedProductName = "";
  public slectedIndex = "";
  public loading = false;
  public addNew = false;
  public currentSku: any;
  public nameFromResponse = "";
  public skuFromResponse = "";
  public unitFromResponse = "";
  public batchTypeidFromresponse = "";
  public inventoryTypeFromResponse = "";
  public strainFromResponse = "";
  public addedNew = false;
  public strainNeeded: any;
  public allProducts: any[] = [];
  public labresultsFromResponse: any;
  public labData: any;
  public reqLength: any;
  public fromApiNeeded!: boolean;
  @Prop() protected batches!: BatchTransfer.Batch[];
  @Prop({ default: () => defaultActions })
  protected actions!: INBOUNDTABLEACTIONS[];
  @Prop({ default: () => [] }) protected cellsToHide!: string[];
  protected items: any[] = [];
  protected headers: any[] = [];

  public editBatchForTrace(
    index: number,
    sku: string,
    atLocation: boolean,
    name: string,
    reqIndex: number
  ) {
    this.$emit("edit", index, sku, atLocation, name, reqIndex);
  }
  /**
   * Emits remove event with batch index to be removed.
   * @param {number} index
   */
  public remove(index: number) {
    this.$emit("remove", index);
  }

  public selectedName(
    selectedName: string,
    selectedIndex: any,
    currentIndex: number,
    id: string
  ) {
    this.addedNew = true;
    this.items.map((batch: any, index: number) => {
      if (this.addNew) {
        if (batch.id === id) {
          const data: any = this.batches.filter((item: any) => {
            return item.id === id || item.barcode_id === id;
          });
          batch.prices = data[0].prices;
          batch.lab_results = data[0].lab_results;
        }
        this.nameFromResponse = selectedIndex.name;
        this.skuFromResponse = selectedIndex.sku;
        this.unitFromResponse = selectedIndex.unit;
        this.batchTypeidFromresponse = selectedIndex.batch_type_id;
        this.strainFromResponse =
          selectedIndex.strain !== null ? selectedIndex.strain.name : "";
        this.strainNeeded = this.strainFromResponse;
        this.inventoryTypeFromResponse = batch.inventorytype;
      } else {
        this.currentSku = this.selectedInventoryType.filter(
          (item: { name: string; sku: string }) => {
            if (selectedName === item.name) {
              return item.sku;
            }
          }
        );
        if (this.currentSku[0].strain !== null) {
          this.strainNeeded = this.currentSku[0].strain.name;
        } else {
          this.strainNeeded = "";
        }
        if (batch.id === id) {
          const data: any = this.batches.filter((item: any) => {
            return item.id === id || item.barcode_id === id;
          });
          this.currentSku[0].lab_results = data[0].lab_results;
        }
      }
      if (id === batch.id) {
        batch.name = this.addNew
          ? this.nameFromResponse
          : this.currentSku[0].name;
        batch.strain = this.strainNeeded;
        batch.product.batch_type_id = this.addNew
          ? this.batchTypeidFromresponse
          : this.currentSku[0].batch_type_id;
        batch.sku = this.addNew ? this.skuFromResponse : this.currentSku[0].sku;
        batch.product.sku = this.addNew
          ? this.skuFromResponse
          : this.currentSku[0].sku;
        batch.product.unit = this.addNew
          ? this.unitFromResponse
          : this.currentSku[0].unit;
        batch.product.strain.name = this.strainNeeded;
        batch.inventorytype = this.addNew
          ? this.inventoryTypeFromResponse
          : batch.inventorytype;
        batch.lab_results = this.addNew
          ? batch.lab_results
          : this.currentSku[0].lab_results;
        batch.addedNew = true;
        batch.notatLocation = true;
      }
    });
    EventBus.$emit("addedProductName", this.items, id);
    this.$nextTick(() => {
      const sortStatus = this.tableData.items.map(batch => {
        if (batch.name) {
          return true;
        } else {
          return false;
        }
      });
      if (sortStatus.includes(false)) {
        tableHeadersforInbound[0].sortable = false;
        tableHeadersforInbound[1].sortable = false;
        tableHeadersforInbound[2].sortable = false;
      } else {
        tableHeadersforInbound[0].sortable = true;
        tableHeadersforInbound[1].sortable = true;
        tableHeadersforInbound[2].sortable = true;
      }
    });
    this.addNew = false;
  }

  public async productsName(data: { inventorytype: string }) {
    this.loading = true;
    const stateProd = await InboundTransferService.getSelectedStateProductData(
      data.inventorytype
    );
    this.selectedInventoryType = stateProd.data;
    this.loading = false;
  }
  public async addNewProduct(
    currentIndex: number,
    currentItem: { inventorytype: string },
    id: string
  ) {
    this.addNew = true;
    this.addedNew = true;
    const response: boolean = await this.$modals.load(
      InboundAddNewProduct,
      {
        size: "normal",
        style: "max-height : 100%; max-width : 85%"
      },
      {
        stateReportingType: currentItem.inventorytype
      }
    );
    if (response) {
      // @ts-ignore
      this.selectedName(response.name, response, currentIndex, id);
    }
  }

  public closeModal() {
    this.$emit("reject");
  }
  protected async created() {
    // GOT ALL PRODUCT DATA
    this.allProducts = await productService.getAll();
    if (this.actions.length === 0) {
      this.cellsToHide.push("actions");
    }
    EventBus.$on("initiallength", (data: number) => {
      this.reqLength = data;
    });
    this.fillDataTable();
  }

  protected fillDataTable() {
    // @ts-ignore
    const filteredHeaders: Array<{}> = this.filteredHeaders; // code to remove linting Error
    this.headers = filteredHeaders.map((header: any) => {
      header.text = String(this.$t(header.text));
      return header;
    });
    if (
      this.sourceType === "LOCATION_TRANSFER" ||
      this.sourceType === "CANNABIS_VENDOR_TRANSFER"
    ) {
      this.items = map(this.batches, (batch, index) => {
        const currentProduct = this.allProducts.filter(item => {
          if (
            batch.currentProduct === item.name &&
            batch.inventorytype === item.inventorytype
          ) {
            return item;
          }
        });
        if (currentProduct.length) {
          this.strainNeeded = batch.strain;
        }
        if (index > this.reqLength - 1) {
          this.fromApiNeeded = true;
        } else {
          this.fromApiNeeded = false;
        }
        if (batch.product!.sku === "") {
          // @ts-ignore
          batch.product!.sku = batch.sku;
        }
        return {
          atLocation:
            currentProduct.length && !batch.notatLocation ? true : false,
          name: currentProduct.length ? currentProduct[0].name : batch.name,
          sku: currentProduct.length
            ? currentProduct[0].sku
            : batch.product!.sku,
          unit: currentProduct.length
            ? currentProduct[0].unit
            : batch.product!.unit,
          fromApi:
            currentProduct.length && !batch.notatLocation
              ? true
              : this.fromApiNeeded,
          barcode_id: batch.id,
          id: batch.barcode_id || batch.id,
          addedNew: false,
          priceUpdated: false,
          editedProduct: false,
          quantity: Number(batch.quantity),
          sale_price: batch.sale_price,
          is_return: batch.is_return ? batch.is_return : 0,
          sub_total: currencyFilter(batch.sale_price),
          tax: currencyFilter(batch.prices.taxCollected),
          total: currencyFilter(batch.prices.total),
          cost_per_unit: currencyFilter(batch.prices.cost_per_unit),
          inventorytype: currentProduct.length
            ? currentProduct[0].inventorytype
            : batch.inventorytype,
          tax_category_id: -1,
          rooms: [
            {
              name: this.defaultRoom! ? this.defaultRoom!.name! : "",
              room_id: this.defaultRoom! ? this.defaultRoom!.id! : 0,
              quantity: Number(batch.quantity)
            }
          ],
          usable_weight_value: 0,
          usable_weight_unit: "",
          weight_per_unit_value: 0,
          weight_per_unit_unit: "",
          batch_level_pricing: "",
          secondary_id: "",
          lab_results: {
            laboratory_id: 0,
            testing_date: "",
            results: []
          },
          prices: {
            price_per_unit: batch.prices.price_per_unit
              ? batch.prices.price_per_unit
              : 0,
            cost_per_unit: batch.prices.cost_per_unit
              ? batch.prices.cost_per_unit
              : 0,
            subTotal: batch.prices.subTotal ? batch.prices.subTotal : 0,
            total: batch.prices.total ? batch.prices.total : 0,
            discount: {
              description: batch.prices.discount.description
                ? batch.prices.discount.description
                : "",
              amount: batch.prices.discount.amount
                ? batch.prices.discount.amount
                : 0,
              type: batch.prices.discount.type
                ? batch.prices.discount.type
                : DISCOUNT_FEE_TYPES.PERCENTAGE,
              value: batch.prices.discount.value
                ? batch.prices.discount.value
                : 0,
              postTaxDiscount: batch.prices.discount.postTaxDiscount
                ? batch.prices.discount.postTaxDiscount
                : false
            },
            fee: {
              description: batch.prices.fee.description
                ? batch.prices.fee.description
                : "",
              amount: batch.prices.fee.amount ? batch.prices.fee.amount : 0,
              type: batch.prices.fee.type
                ? batch.prices.fee.type
                : DISCOUNT_FEE_TYPES.PERCENTAGE,
              value: batch.prices.fee.value ? batch.prices.fee.value : 0,
              postTaxFee: batch.prices.fee.postTaxFee
                ? batch.prices.fee.postTaxFee
                : false
            },
            taxCollected: batch.prices.taxCollected
              ? batch.prices.taxCollected
              : 0
          },
          tax_count: {
            pre_excise: 0,
            excise: 0,
            post_excise: 0,
            per_transaction: 0
          },
          product: {
            marijuana: index > this.reqLength - 1 ? 0 : 1,
            batch_type_id: currentProduct.length
              ? currentProduct[0].batch_type_id
              : batch.product!.batch_type_id,
            unit: currentProduct.length
              ? currentProduct[0].unit
              : batch.product!.unit,
            sku: currentProduct.length
              ? currentProduct[0].sku
              : batch.product!.sku,
            strain: {
              name: this.strainNeeded || ""
            }
          }
        };
      });
    }
    this.$emit("productexisting", this.items);
  }
}
