import { policyList } from "@/enums/permissions";
import { BatchType } from "@/interfaces/batchType";
import { Brand } from "@/interfaces/brand";
import { Category } from "@/interfaces/category";
import { Location, LocationSalesLimits } from "@/interfaces/location";
import {
  Pricebreak,
  PricingRule,
  Product,
  productDefault
} from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { Strain } from "@/interfaces/strain";
import { TaxCategory } from "@/interfaces/taxCategory";
import { ProductMetrcItem } from "@/interfaces/traceability";
import { WmProductModel } from "@/interfaces/WeedmapsData";
import { EventBus } from "@/internal";
import { batchTypeService } from "@/services/batchType.service";
import { messagesService } from "@/services/messages.service";
import { pricePointService } from "@/services/pricePoint.service";
import { productService } from "@/services/product.service";
import { traceabilityService } from "@/services/traceability.service";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import { PageNavAction } from "@/types/types";
import { BooleanCheck, CallbackPromise } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import omit from "lodash/omit";
import { Validator } from "vee-validate";
import { TranslateResult } from "vue-i18n";
import {
  Component,
  Inject,
  Prop,
  Provide,
  Vue,
  Watch
} from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./InboundAddNewProduct.template.vue";

/**
 * namespace to dispatch vuex actions and getters
 * @const string namespace
 */
const namespace: string = "ProductModule";
const WeedMapsProduct = {
  isVerified: true,
  isloaded: false,
  verifiedModel: { weedmaps_product: null, weedmaps_product_variant: null },
  nonVerifiedModel: {
    name: null,
    attribute: null,
    unit: null,
    tags: []
  },
  changed: false
};
/**
 * product basic info form component
 */
@Component({
  mixins: [Template]
})
export default class InboundAddNewProduct extends Vue {
  public get requirePackageWeight() {
    if (this.packageWeightTypes) {
      return (
        this.product &&
        this.packageWeightTypes.includes(this.product.batch_type_id!)
      );
    }
    return false;
  }
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("limitConfig", { namespace: "AuthModule" })
  public limitConfig!: LocationSalesLimits[];
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("metrcConfig", { namespace: "TraceabilityModule" })
  public metrcConfig!: boolean;
  public units: Array<{ label: string | TranslateResult; value: string }> = [];
  public debounceTime = 500;
  public packageWeightTypes: string[] | null = null;
  public isLoading = false;
  public isSaving = false;
  public weedmapsModel: WmProductModel = cloneDeep(WeedMapsProduct);
  public savePricing = false;
  public pricingRules: PricingRule[] = [];
  public hasPrices = false;
  public productMetrcInfo: ProductMetrcItem = {
    biotrack_sku: null,
    metrc_id: null,
    isNewItem: true
  };
  @Prop({ required: true })
  public stateReportingType!: any;

  public debounceStrainsSearch = debounce(async (strainName: string) => {
    if (!strainName || strainName.length > 2) {
      this.isLoadingStrains = true;
      await this.findStrainsAction({ strainName });
      this.isLoadingStrains = false;
    }
  }, this.debounceTime);
  public debounceBatchTypeSearch = debounce(async (batchTypeName: string) => {
    if (!batchTypeName || batchTypeName.length > 2) {
      this.isLoadingBatchType = true;
      await this.findBatchTypeAction({ batchTypeName });
      this.isLoadingBatchType = false;
    }
  }, this.debounceTime);
  public debounceCategoriesSearch = debounce(async (categoryName: string) => {
    if (!categoryName || categoryName.length > 2) {
      this.isLoadingCategories = true;
      await this.findCategoriesAction({ categoryName });
      this.isLoadingCategories = false;
    }
  }, this.debounceTime);
  public debounceBrandsSearch = debounce(async (brandName: string) => {
    if (!brandName || brandName.length > 2) {
      this.isLoadingBrands = true;
      await this.findBrandsAction({ brandName });
      this.isLoadingBrands = false;
    }
  }, this.debounceTime);
  public debounceVendorsSearch = debounce(async (vendorName: string) => {
    if (!vendorName || vendorName.length > 2) {
      this.isLoadingVendors = true;
      await this.findVendorsAction({ vendorName, mutator: "setVendors" });
      this.isLoadingVendors = false;
    }
  }, this.debounceTime);
  @Inject("validator") public $validator!: Validator;
  @Provide()
  public validator = this.$validator;
  /**
   * action to find the strains to the autocomplete list
   */
  @Action("findStrains", { namespace })
  public findStrainsAction!: CallbackPromise<void>;

  /**
   * action to find the batch types to the autocomplete list
   */
  @Action("findBatchType", { namespace })
  public findBatchTypeAction!: CallbackPromise<void>;

  /**
   * action to find the categories to the autocomplete list
   */
  @Action("findCategories", { namespace })
  public findCategoriesAction!: CallbackPromise<void>;

  /**
   * action to find the brands to the autocomplete list
   */
  @Action("findBrands", { namespace })
  public findBrandsAction!: CallbackPromise<void>;

  /**
   * action to find the vendors to the autocomplete list
   */
  @Action("findVendors", { namespace })
  public findVendorsAction!: CallbackPromise<void>;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  protected taxCategories!: TaxCategory[];
  /**
   * model to save
   * @var Product product
   */
  protected product: Product = { ...productDefault };

  /**
   * list of strain to display in the autocomplete field
   * @var Strain[] strain
   */
  @Getter("strains", { namespace })
  protected strainItems!: Strain[];

  /**
   * property to search the strains in the autocomplete
   * @var string searchStrainField
   */
  protected searchStrainField: string | null = null;

  /**
   * autocomplete is loading state
   * @var boolean isLoadingStrains
   */
  protected isLoadingStrains: boolean = false;

  protected batchTypeItems!: BatchType[];

  /**
   * property to search the batch type in the autocomplete
   * @var string searchBatchTypeField
   */
  protected searchBatchTypeField: string | null = null;

  /**
   * autocomplete is loading state
   * @var boolean isLoadingBatchType
   */
  protected isLoadingBatchType: boolean = false;

  /**
   * list of categories to display in the autocomplete field
   * @var Category[] categoryItems
   */
  @Getter("categories", { namespace })
  protected categoryItems!: Category[];

  /**
   * property to search the categories in the autocomplete
   * @var string searchCategoryField
   */
  protected searchCategoryField: string | null = null;

  /**
   * autocomplete is loading state
   * @var boolean isLoadingCategories
   */
  protected isLoadingCategories: boolean = false;

  protected notRunWatch = true;

  /**
   * list of Brand to display in the autocomplete field
   * @var Brand[] brandItems
   */
  @Getter("brands", { namespace })
  protected brandItems!: Brand[];

  /**
   * property to search the brands in the autocomplete
   * @var string searchBrandField
   */
  protected searchBrandField: string | null = null;

  /**
   * autocomplete is loading state
   * @var boolean isLoadingBrands
   */
  protected isLoadingBrands: boolean = false;

  /**
   * list of strain to display in the autocomplete field
   * @var Vendors[] vendorsItems
   */
  @Getter("vendors", { namespace })
  protected vendorItems!: Brand[];

  /**
   * property to search the vendors in the autocomplete
   * @var string searchVendorField
   */
  protected searchVendorField: string | null = null;

  /**
   * autocomplete is loading state
   * @var boolean isLoadingVendors
   */
  protected isLoadingVendors: boolean = false;

  /**
   * watcher to dispatch the findStrain action, when the autocomplete field is change
   * @param strainName
   */
  @Watch("searchStrainField")
  public async searchStrain(strainName: string) {
    this.debounceStrainsSearch(strainName);
  }

  @Watch("categoryItems")
  public disabledButtonsActions() {
    this.$emit("disabled", !!this.categoryItems.length);
  }

  /**
   * watcher to dispatch the findBatchType action, when the autocomplete field is change
   * @param batchTypeName
   */
  @Watch("searchBatchTypeField")
  public async searchBatchType(batchTypeName: string) {
    this.debounceBatchTypeSearch(batchTypeName);
  }

  public goTo() {
    this.$router.push("/inventory/product-category");
  }

  /**
   * watcher to dispatch the findCategoriesAction action, when the autocomplete field is change
   * @param categoryName
   */
  @Watch("searchCategoryField")
  public async searchCategories(categoryName: string) {
    this.debounceCategoriesSearch(categoryName);
  }

  /**
   * watcher to dispatch the findBrandsAction action, when the autocomplete field is change
   * @param brandName
   */
  @Watch("searchBrandField")
  public async searchBrands(brandName: string) {
    this.debounceBrandsSearch(brandName);
  }

  /**
   * watcher to dispatch the findVendorsAction action, when the autocomplete field is change
   * @param vendorName
   */
  @Watch("searchVendorField")
  public async searchVendors(vendorName: string) {
    this.debounceVendorsSearch(vendorName);
  }

  /**
   * watcher to display require inventory field
   * @param isCannabis
   */
  @Watch("product.marijuana")
  public isCannabis(isCannabis: boolean, old: any) {
    this.product.metrc_weighable = false;
    this.product.require_inventory = 0;
    // @ts-ignore
    if (this.product.editing) {
      // @ts-ignore
      delete this.product.editing;
      return;
    }
  }

  public metrcAction() {
    EventBus.$emit("notify", { text: "not_implemented_yet", color: "warning" });
  }

  public validateNumber(event: Event) {
    // @ts-ignore
    if (event.key !== ".") {
      // @ts-ignore
      if (isNaN(event.key)) {
        event.preventDefault();
      }
    }
    return true;
  }

  public visbleCannabisProduct() {
    return !this.product.requires_weighing && this.product.marijuana;
  }

  public updateValueWeighing() {
    this.product.requires_weighing = 0;
    this.updateValue();
  }

  public updateValue() {
    this.product.strain_id = this.product.strain_id || null;
    this.$emit("input", this.product);
  }
  public async save(share: boolean = false) {
    if (!this.hasPermission(policyList.modifyProducts) && this.product.sku) {
      this.cancel();
      return;
    }
    const isValid = true;

    if (
      this.currentLocation.state &&
      this.currentLocation.state.code === "CT" &&
      !this.product.marijuana &&
      this.product.ndc_number
    ) {
      this.product.ndc_number = "";
    }
    if (isValid) {
      this.isSaving = true;
      this.product.shareable = share;
      this.product = omit(this.product, ["cost_per_unit"]) as Product;
      this.product.unit = (this.product.requires_weighing && "g") || "u";
      this.product.price_settings.price_point_by_usable_weight =
        (this.product.marijuana &&
          this.product.price_settings.price_point_by_usable_weight) ||
        0;
      if (this.product.requires_weighing || !this.product.marijuana) {
        this.product.usable_weight_value = null;
        this.product.usable_weight_unit = null;
      }

      this.product.metrc_weighable = this.product.requires_weighing
        ? true
        : this.product.metrc_weighable;

      try {
        const saved = await productService.save(this.product);
        messagesService.renderSuccessMessage("product_saved");

        // WM Integration
        if (saved) {
          this.$emit("resolve", saved);
        }

        if (saved && this.weedmapsModel.changed) {
          const integrated = await weedmapsInfoService.integrateProduct(
            { ...saved, price_settings: this.product.price_settings },
            this.weedmapsModel
          );
          // Associate TAGs for non verified
          if (integrated && !this.weedmapsModel.isVerified) {
            await weedmapsInfoService.assignTagsToProduct(
              saved,
              this.weedmapsModel.nonVerifiedModel.tags
            );
          }
        }
        // Prices
        if (this.savePricing && saved) {
          if (this.currentRetailSettings.retail_pre_tax_pricing) {
            this.setAndCalculeCurrentPricingDefault();
          }
          await productService.setProductPricing(
            saved.sku,
            this.pricingRules,
            this.hasPrices
          );
        }
        // metrc
        if (this.productMetrcInfo && this.productMetrcInfo.hasChanged) {
          if (
            !this.productMetrcInfo.metrc_id &&
            !this.productMetrcInfo.isNewItem
          ) {
            await traceabilityService.unlinkProduct(this.product.sku);
          } else if (this.productMetrcInfo.metrc_id) {
            await traceabilityService.setProductMetrcMappedItem({
              biotrack_sku: saved.sku,
              metrc_id: this.productMetrcInfo.metrc_id,
              isNewItem: this.productMetrcInfo.isNewItem
            });
          }
        }
        this.$emit("reject");
      } catch (e) {
        messagesService.renderErrorMessage(e);
      } finally {
        this.isSaving = false;
      }
    } else {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.missing_form_data")
        ),
        "error"
      );
    }
  }
  public groupedTaxes(taxId: number | null) {
    const currentTax = this.taxCategories.find(
      (tax: TaxCategory) => tax.id === taxId
    );
    return pricePointService.getGroupedTaxes(currentTax!.taxes!);
  }
  public cancel() {
    this.$router.back();
  }

  protected setPackageWeightLimit() {
    const cannabisRules = this.limitConfig.filter(
      r => r.type === "cannabis_product"
    );
    if (cannabisRules.length) {
      const packageConfig = cannabisRules.find(
        r =>
          r.group ===
          (this.currentLocation.location_type === "RETAIL_MEDICAL"
            ? "medicinal"
            : "recreational")
      );
      if (packageConfig) {
        this.packageWeightTypes = packageConfig.configs.reduce(
          (acc: string[], c) => {
            if (c.config.rule_field === "package" && c.config.batch_types) {
              acc = [...acc, ...c.config.batch_types];
            }
            return acc;
          },
          []
        );
      }
    }
  }

  protected setEditFields(avoidRequest: boolean) {
    this.findBrandsAction({
      brandName: "",
      itemBrand: this.product.brand,
      avoidRequest: avoidRequest && this.product.brand_id
    });
    this.findBatchTypeAction({
      batchTypeName: "",
      itemBatchType: this.product.batch_type,
      avoidRequest: avoidRequest && this.product.batch_type_id
    });
    this.findStrainsAction({
      strainName: "",
      batchStrain: this.product.strain,
      avoidRequest: avoidRequest && this.product.strain_id
    });
    this.findCategoriesAction({
      categoryName: "",
      itemCategory: this.product.category,
      avoidRequest: avoidRequest && this.product.product_category_id
    });
    this.findVendorsAction({
      vendorName: "",
      itemVendor: this.product.vendor,
      mutator: "setVendors",
      avoidRequest: avoidRequest && this.product.vendor_id
    });
  }

  protected setAndCalculeCurrentPricingDefault() {
    this.pricingRules.map(price => {
      const settingPriceType =
        price.pricing_type === "RETAIL_MEDICAL"
          ? "retail_pre_tax_pricing"
          : "wholesale_pre_tax_pricing";
      const goForward = !!this.currentRetailSettings[settingPriceType];
      if (price.price_breaks) {
        price.price_breaks!.map((pricebreak: Pricebreak) => {
          if (!(pricebreak.post_tax || pricebreak.pre_excise)) {
            if (goForward) {
              return pricePointService.calculateForward(
                pricebreak,
                this.groupedTaxes(price.tax_category_id!)
              );
            } else {
              return pricePointService.calculateBackwards(
                pricebreak,
                this.groupedTaxes(price.tax_category_id!),
                true
              );
            }
          }
        });
      }
    });
  }
  protected async mounted() {
    const response = await batchTypeService.get(
      {
        no_pagination: true,
        per_page: Infinity
      },
      true
    );
    this.batchTypeItems = response.filter((item: any) => {
      if (item.inventory_type_id === this.stateReportingType) {
        return item;
      }
    });

    this.setEditFields(!!this.$route.params.id);

    this.setPackageWeightLimit();
    this.units = [
      {
        label: this.$t("limit.grams"),
        value: "g"
      },
      {
        label: this.$t("limit.milligrams"),
        value: "mg"
      },
      {
        label: this.$t("limit.ounces"),
        value: "oz"
      }
    ];
  }
}
