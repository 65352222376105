import ComputeTransfer from "@/components/inventory/BatchTransfer/BatchTransferManager/ComputeTransfer.mixin";
import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchDetailsContainerComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsContainer/BatchDetailsContainer.component";
import BatchDetailsContainerSectionComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsSection/BatchDetailsSection.component";
import BatchesList, {
  TABLEACTIONS
} from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import LabDetailsForm from "@/components/inventory/BatchTransfer/BuildBlocks/LabDetailsForm/LabDetailsForm.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import PricingBreakdownForm from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownForm/PricingBreakdownForm.component";
import PricingBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownSummary/PricingBreakdownSummary.component";
import {
  OutboundTransferDefault,
  OutboundTransferItemDefault
} from "@/components/inventory/BatchTransfer/declarations";
import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import { Batch, InventoryBatch } from "@/interfaces/batch";
import {
  BatchTransfer,
  PRICE_POINTS_TYPE
} from "@/interfaces/batchTransferManager";
import { Product } from "@/interfaces/product";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { batchDetailsModelKeys } from "../../ModelKeys";
import Template from "./OutboundTransferBatchDetails.template.vue";
const DEFAULT_BATCH = {
  batch_uid: "",
  secondary_id: "",
  quantity: 0
};
interface StepModel {
  items: BatchTransfer.OutboundTransferItem[];
  notes: BatchTransfer.TransferNote[];
  prices: BatchTransfer.TransferPrices;
}

@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "pricing-breakdown-form": PricingBreakdownForm,
    "batch-details-container": BatchDetailsContainerComponent,
    "pricing-breakdown-summary": PricingBreakdownSummary,
    "batch-detail-section": BatchDetailsContainerSectionComponent,
    "payment-breakdown-summary": PaymentBreakdownSummary,
    "lab-details-form": LabDetailsForm
  }
})
export default class OutboundTransferBatchDetails extends Mixins(
  TransferStep,
  ComputeTransfer,
  StepsData
) {
  public get getProducts() {
    return this.editFromInventory && this.editingBatch.product
      ? [this.editingBatch.product]
      : this.productsList;
  }

  /**
   * List of batches used in other transfers
   * @protected
   */
  protected get batchesUsed(): string[] {
    return this.collection
      .filter(destination => destination.uid !== this.transfer.uid)
      .reduce((batches: string[], destination) => {
        return [...batches, ...destination.items.map(batch => batch.batch_uid)];
      }, []);
  }

  /**
   * Getter editing batch
   */
  public get editingBatch(): BatchTransfer.OutboundTransferItem {
    if (this.active && !this.modelItems.length) {
      this.addNewBatch();
    }
    return this.model.items[this.selectedBatch];
  }

  /**
   * Setter editing batch
   */
  public set editingBatch(batch: BatchTransfer.OutboundTransferItem) {
    const items = cloneDeep(this.transfer
      .items as BatchTransfer.OutboundTransferItem[]);
    items[this.selectedBatch] = this.recalculateBatchPrices(
      batch
    ) as BatchTransfer.OutboundTransferItem;
    this.model.items = items;
  }
  /**
   * Gets show table label
   */
  get showTableLabel() {
    return this.transfer.pricing_enabled
      ? []
      : ["total", "sub_total", "tax", "price_per_unit"];
  }
  public modelKeys = batchDetailsModelKeys;
  public model: StepModel = pick(
    OutboundTransferDefault,
    this.modelKeys
  ) as StepModel;

  public batches: Batch[] = [];
  public selectedBatch: number = -1;
  public editingCache: BatchTransfer.OutboundTransferItem | null = null;
  public readonly tableActions: TABLEACTIONS[] = [TABLEACTIONS.EDIT];
  public editFromInventory = false;
  public loadingItems = false;
  public currentProductBatches: InventoryBatch[] = [];
  @Prop() public collection!: BatchTransfer.OutboundTransfer[];
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ default: false }) protected active!: boolean;
  /**
   * Validates the form then emit the transfer changes.
   */
  public async validateAndSave() {
    if (await this.$validator.validateAll("edit-batch")) {
      this.validateListAndEmitChanges();
    }
  }
  /**
   * Adds new batch. With default values as model.
   */
  public addNewBatch() {
    const item = cloneDeep(OutboundTransferItemDefault);
    item.id = Date.now();
    this.model.items.push(item);
    this.selectedBatch = this.model.items.length - 1;
    this.currentProductBatches = [];
    this.editingCache = null;
  }

  /**
   * Edits batch. Sets the model of the selected batch on the Batch List component edit.
   * @param {number} id Batch ID (not uid).
   */
  public async editBatch(id: number) {
    this.selectedBatch = this.model.items.findIndex(batch => batch.id === id);
    this.editingCache = cloneDeep(
      this.transferItems[this.selectedBatch]
    ) as BatchTransfer.OutboundTransferItem;
    this.editingBatch = this.editingCache;
    this.editFromInventory =
      this.$route.params.batches &&
      Object(this.$route.params.batches).find(
        (item: InventoryBatch) => item.batch_uid === this.editingBatch.batch_uid
      );
    this.editingBatch.sku =
      this.editingBatch.product!.concrete_sku || this.editingBatch.product!.sku;
    this.currentProductBatches = await this.getProductBatches(null, true);
  }

  /**
   * Removes batch
   * @param {number} index Batch index to be removed from transfer.
   */
  public removeBatch(index: number) {
    this.selectedBatch = -1;
    this.model.items.find(item => item.id === index)!.destroy = true;
    this.transferChanges();
  }

  /**
   * Cancels edition.
   */
  public cancelEdition() {
    this.editingCache = null;
    this.selectedBatch = -1;
    this.model.items = this.model.items.filter(item => item.batch_uid);
  }

  /**
   * Undos outbound transfer batch edition.
   */
  public undo() {
    this.editingBatch = this.editingCache
      ? cloneDeep(this.editingCache)
      : OutboundTransferItemDefault;
  }

  /**
   * Changes product. Handler for product field.
   */
  public async changeProduct() {
    this.editingBatch.product =
      (this.editingBatch.sku &&
        this.productsList.find(p => p.sku === this.editingBatch.sku)) ||
      undefined;
    this.editingBatch = this.resetBatch((await this.resetProduct(
      this.editingBatch
    )) as BatchTransfer.OutboundTransferItem) as BatchTransfer.OutboundTransferItem;
    this.currentProductBatches = await this.getProductBatches(null, true);
  }

  public get getBatchItems() {
    const productBatches = cloneDeep(this.currentProductBatches);
    productBatches.map(item => {
      if (this.bioTrackTraceEnabled && item.biotrack_traceability_id !== "") {
        item.batchUid = item.biotrack_traceability_id;
      } else {
        item.batchUid = item.batch_uid;
      }
    });
    return productBatches;
  }
  public batchSelectedIsInvalid(batchSelected: InventoryBatch) {
    const currentBatch = this.transferItems[
      this.selectedBatch
    ] as BatchTransfer.OutboundTransferItem;
    const invalidBatch =
      batchSelected &&
      this.transferItems.find(
        p =>
          (p as BatchTransfer.OutboundTransferItem).batch!.batch_uid ===
          batchSelected.batch_uid
      ) &&
      (!currentBatch ||
        (currentBatch &&
          currentBatch.batch!.batch_uid !== batchSelected.batch_uid));
    const isInOtherTransfer = this.batchesUsed.includes(
      batchSelected.batch_uid
    );
    if (invalidBatch || isInOtherTransfer) {
      const editingBatch = currentBatch || {
        ...this.setBatch(DEFAULT_BATCH as BatchTransfer.OutboundTransferItem),
        batch: undefined
      };
      (this.$refs.batchesAutocomplete as HTMLFormElement).setValue(
        editingBatch.batch
      );
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        this.$t(
          isInOtherTransfer
            ? "batch_transfer_manager_module.messages.batch_already_in_other_transfers"
            : "batch_transfer_manager_module.messages.batch_already"
        ).toString(),
        "warning"
      );
      return editingBatch;
    }
    return null;
  }
  /**
   * Selects item batch
   * @param {Batch} batchSelected
   */
  public selectItemBatch(batchSelected: InventoryBatch) {
    const batch =
      (batchSelected &&
        (this.batchSelectedIsInvalid(batchSelected) ||
          this.setBatch(batchSelected as InventoryBatch))) ||
      this.setBatch(DEFAULT_BATCH as BatchTransfer.OutboundTransferItem);
    this.editingBatch = batch;
  }

  /**
   * Sets tax. Handler for Tax Category form field. Updates editinfBatch.
   */
  public async setTax() {
    this.editingBatch = (await this.setupBatchTaxCategories(
      this.editingBatch
    )) as BatchTransfer.OutboundTransferItem;
    this.editingBatch = this.processTaxCategory(
      this.editingBatch,
      this.editingBatch.tax_category!
    ) as BatchTransfer.OutboundTransferItem;
  }

  /**
   * Updates date. Handler for date picker field. Updates editingBatch expiration date.
   * @param {string} date
   */
  public updateDate(date: string) {
    const batch = cloneDeep(this.editingBatch);
    batch.expiration_date = date;
    this.editingBatch = batch;
  }
  /**
   * Nulls tax category. Handler for manual input of tax value at Pricing form.
   * @param {number} collected
   */
  public nullTaxCategory(collected: number) {
    const clone = this.voidTaxCategory(this.editingBatch);
    clone.prices.taxCollected = collected;
    this.editingBatch = clone as BatchTransfer.OutboundTransferItem;
  }

  /**
   * Sets lab_results on editingBatch.
   * @param {BatchTransfer.LabResults} results
   */
  public setResults(results: BatchTransfer.LabResults) {
    const batch = cloneDeep(this.editingBatch);
    batch.lab_results = results;
    this.editingBatch = batch;
  }

  /**
   * Adds new transfer to manifest.
   */
  public addNewTransfer() {
    if (!this.validateItems()) {
      return;
    }

    this.transferChanges();
    delete this.$route.params.batches;
    if (this.editing) {
      this.updateOnCollection(this.transfer);
    } else {
      this.saveToCollection(this.transfer);
    }
  }

  /**
   * Creates manifest
   */
  public createManifest() {
    if (this.validateItems()) {
      this.addNewTransfer();
      this.$emit("createManifest");
    }
  }

  /**
   * Prints barcode
   */
  public printBarcode() {
    EventBus.$emit("print", {
      component: BarcodeListComponent,
      props: {
        batches: [this.editingBatch.batch]
      }
    });
  }

  /**
   * Prints label
   */
  public printLabel() {
    EventBus.$emit("print", {
      component: InventoryLabelListComponent,
      props: {
        batches: [this.editingBatch.batch],
        labelType: "INVENTORY"
      }
    });
  }

  /**
   * Updates editing batch
   */
  public async updateEditingBatch() {
    this.editingBatch = (await this.resetProduct(
      this.editingBatch
    )) as BatchTransfer.OutboundTransferItem;
  }

  protected setBatch(
    batchSelected: InventoryBatch | BatchTransfer.OutboundTransferItem,
    newOutboundItem?: BatchTransfer.OutboundTransferItem
  ) {
    const batch = cloneDeep(newOutboundItem || this.editingBatch);
    batch.batch_uid = (batchSelected && batchSelected.batch_uid) || "";
    batch.secondary_id =
      (batchSelected && (batchSelected as InventoryBatch).tracking_id) || "";
    batch.quantity =
      (batchSelected &&
        (batchSelected as InventoryBatch).in_store_quantity_value) ||
      (batchSelected as BatchTransfer.OutboundTransferItem).quantity ||
      0;
    const pricePoint = this.getPricePoint(
      PRICE_POINTS_TYPE.WHOLESALE_OUTBOUND,
      this.currentRules,
      batch.quantity
    );
    batch.prices.price_per_unit =
      (pricePoint &&
        pricePoint.break &&
        parseFloat(
          Number(pricePoint.break.price / pricePoint.break.quantity).toFixed(2)
        )) ||
      0;
    return batch;
  }

  protected async initModelfromInventory() {
    const items: InventoryBatch[] = Object(this.$route.params.batches);
    if (items.length && !this.transfer.items.length) {
      this.loadingItems = true;
      this.model.items = await Promise.all(
        items.map(async (b: InventoryBatch) => {
          const newOutboundItem: BatchTransfer.OutboundTransferItem = {
            ...OutboundTransferItemDefault,
            id: Date.now(),
            usable_weight_value:
              (b.product_variant && b.product_variant.usable_weight_value) ||
              undefined,
            usable_weight_unit:
              (b.product_variant && b.product_variant.usable_weight_unit) ||
              undefined,
            weight_per_unit_value:
              (b.product_variant && b.product_variant.weight_per_unit_value) ||
              undefined,
            weight_per_unit_unit:
              (b.product_variant && b.product_variant.weight_per_unit_unit) ||
              undefined,
            sku: b.product!.sku,
            batch: b,
            product: b.product! as Product
          };
          const formatted = this.setBatch(b, newOutboundItem);
          return this.patchBatchPrices(formatted) as Promise<
            BatchTransfer.OutboundTransferItem
          >;
        })
      );
      this.transferChanges();
      this.selectedBatch = -1;
    }
    this.loadingItems = false;
    return items.length;
  }

  protected mounted() {
    this.pricePointType = PRICE_POINTS_TYPE.WHOLESALE_OUTBOUND;
    this.loadBatchLevels();
    this.loadProducts();
    this.loadTaxCategories();
    this.$watch("active", value => {
      if (value) {
        this.cloneModel();
        this.editFromInventory = !!this.initModelfromInventory();
        this.assignProducts().finally(this.assignTaxCategory);
        this.selectedBatch = -1;
      }
    });
  }

  /**
   * Validates step
   * @returns {void}
   */
  protected validateStep() {
    if (!this.validateItems()) {
      return;
    }
    if (!this.transferItems.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.missing_form_data")
        ),
        "error"
      );
      return;
    }
    this.nextStep();
  }

  /**
   * Validates list and emit changes
   */
  protected validateListAndEmitChanges() {
    this.selectedBatch = -1;
    this.model.items = this.model.items.filter(item => item.sku);
    this.transferChanges();
  }

  protected validateItems(): boolean {
    if (!this.transfer.items.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("batch_transfer_manager_module.messages.no_items")),
        "warning"
      );
      return false;
    } else {
      return true;
    }
  }
}
