import { WeedmapsCreate } from "@/interfaces/brandWeedmaps";
import { Location } from "@/interfaces/location";
import { Product } from "@/interfaces/product";
import {
  WeedmapsData,
  WmBrand,
  WmProductModel,
  WmTag,
  WmVariant
} from "@/interfaces/WeedmapsData";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { messagesService } from "./messages.service";

/*
 * all brands are obtained from the side of weedmaps
 * @returns Promise BrandWeedmaps
 */
class WeedmapsInfoService extends HttpService {
  public async getWeedmapsBrands(name: string = ""): Promise<WmBrand[]> {
    const currentLocation: Location =
      store.getters["AuthModule/currentLocation"];
    this.uri = `${
      process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL
    }/api/v1/brands/masters${name ? "?filter[name]=" + name : ""}`;
    try {
      const headers = {
        location: currentLocation.id
      };
      const response = await super.getWithHeaders(headers, null);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getBrand(id: string): Promise<null | WmBrand> {
    const currentLocation: Location =
      store.getters["AuthModule/currentLocation"];
    this.uri = `${
      process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL
    }/api/v1/brands/${id}`;
    try {
      const headers = {
        location: currentLocation.id
      };
      const response = await super.getWithHeaders(headers, null);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  /*
   * brand creation on the side of weedmaps
   * @param data: WeedmapsData
   * @returns Promise WeedmapsCreate
   */

  public async createBrandsWeedmaps(
    data: WeedmapsData
  ): Promise<WeedmapsCreate | void> {
    this.uri = `${process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL}/api/v1/brands`;
    const currentLocation: Location =
      store.getters["AuthModule/currentLocation"];
    try {
      const headers = {
        location: currentLocation.id
      };
      const response = await super.postWithHeaders(headers, data);
      return response.data.data;
    } catch (e) {
      if (e.response.status !== 404) {
        messagesService.renderWarningMessage(e);
      }
    }
  }
  public async getTags(
    query: {
      "filter[name]"?: string;
      "filter[group_id]"?: string;
      "filter[is_category]"?: 0 | 1;
      "sort[name]"?: "asc" | "desc";
      per_page?: number;
    } | null,
    paginate: boolean = true
  ) {
    this.uri = `${process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL}/api/v1/tags`;
    try {
      const response = await super.get(query, paginate);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }
  /**
   * Gets categories tree
   * The category Pets will not be used because it breaks the logic
   * of the category component. Taking it out was proposed by Christian Cohelo.
   * @returns  Categories Tree without Pets Category
   */
  public async getCategoriesTree() {
    this.uri = `${
      process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL
    }/api/v1/tags/category/tree`;
    try {
      const response = await super.get();
      return response.data.filter((d: WmTag) => d.name !== "Pets");
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getProductsByBrand(
    query: {
      "filter[name]"?: string;
      "sort[name]"?: "asc" | "desc";
    } | null,
    biotrackBrandId: number
  ) {
    this.uri = `${
      process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL
    }/api/v1/weedmaps/products/masters/${biotrackBrandId}`;
    const currentLocation: Location =
      store.getters["AuthModule/currentLocation"];
    try {
      const response = await super.getWithHeaders(
        {
          location: currentLocation.id
        },
        query
      );
      return response.data.data;
    } catch (e) {
      if (e.response.status !== 404) {
        messagesService.renderErrorMessage(e);
      }
    }
  }

  public async integrateProduct(product: Product, model: WmProductModel) {
    this.uri = `${process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL}/api/v1/products`;
    try {
      const currentLocation: Location =
        store.getters["AuthModule/currentLocation"];
      let payload: { [key: string]: any } = {
        biotrack_product_id: String(product.sku),
        biotrack_brand_id: String(product.brand_id),
        msrp_in_cents: this.getBasePriceInCents(product)
      };

      if (product.image_thumb_url) {
        const imageUrl = this.getEncodedImageURLString(product.image_thumb_url);
        payload = {
          ...payload,
          image_url: imageUrl
        };
      }
      if (model.isVerified) {
        payload = {
          ...payload,
          weedmaps_product_id: model.verifiedModel.weedmaps_product!
            .weedmaps_product_id,
          weedmaps_product_variant_id: model.verifiedModel
            .weedmaps_product_variant!.weedmaps_product_variant_id
        };
      } else {
        payload = {
          ...payload,
          weedmaps_product_variant_id: "",
          name: model.nonVerifiedModel.name,
          attribute: model.nonVerifiedModel.attribute,
          unit: model.nonVerifiedModel.unit,
          quantity: String(product.weight_per_unit_value || 1),
          description: product.name
        };
      }
      const response = await super.postWithHeaders(
        { location: currentLocation.id },
        payload
      );
      return response.data;
    } catch (error) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",

        i18n.t("server_error").toString(),
        "error"
      );
    }
  }

  public async assignTagsToProduct(product: Product, tags: WmTag[]) {
    this.uri = `${
      process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL
    }/api/v1/products/${product.sku}/tags`;
    const currentLocation: Location =
      store.getters["AuthModule/currentLocation"];
    try {
      const payload = tags.map(t => t.weedmaps_tag_id);
      const response = await super.postWithHeaders(
        { location: currentLocation.id },
        { ids: payload }
      );
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getProduct(productId: string) {
    try {
      const currentLocation: Location =
        store.getters["AuthModule/currentLocation"];
      this.uri = `${
        process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL
      }/api/v1/products/${productId}`;
      const response = await super.getWithHeaders(
        { location: currentLocation.id },
        null
      );

      return response.data;
    } catch (e) {
      if (e.response && e.response.status !== 404) {
        messagesService.renderErrorMessage(e);
      }
    }
  }

  public async getProductVariants(
    weedmapsProductId: string
  ): Promise<WmVariant[]> {
    try {
      const currentLocation: Location =
        store.getters["AuthModule/currentLocation"];
      this.uri = `${
        process.env.VUE_APP_YODA_WEEDMAPS_BRAND_URL
      }/api/v1/weedmaps/products/${weedmapsProductId}/variants`;
      const response = await super.getWithHeaders(
        { location: currentLocation.id },
        null
      );

      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return [];
    }
  }
  /**
   * Gets base price in cents
   * Member Level Normal (null) and highest in price, returned in cents
   * @param prices
   * @returns  {integer}
   */
  protected getBasePriceInCents(product: Product) {
    // Adaptar al nuevo formato de precios
    // const currentPricePoint = product.prices.filter(
    //   p =>
    //     p.type !== "WHOLESALE_INBOUND" &&
    //     p.type !== "WHOLESALE_OUTBOUND" &&
    //     p.member_level_id === null
    // );
    // const usableWeight =
    //   (product.marijuana &&
    //     !product.requires_weighing &&
    //     product.price_settings.price_point_by_usable_weight &&
    //     product.usable_weight) ||
    //   1;
    // const base =
    //   maxBy(currentPricePoint, p => p.quantity <= usableWeight && p.quantity) ||
    //   minBy(currentPricePoint, "quantity");

    // return base
    //   ? Math.round(+usableWeight * (base.post_tax / base.quantity) * 100)
    //   : 1;
    return 1;
  }

  private getEncodedImageURLString(url: string) {
    const imageURLSplitted = url.split("/");
    const encodedImageName = encodeURI(
      imageURLSplitted[imageURLSplitted.length - 1]
    );
    const relevantPart = imageURLSplitted.slice(0, imageURLSplitted.length - 1);
    return relevantPart.join("/") + "/" + encodedImageName;
  }
}
export const weedmapsInfoService = new WeedmapsInfoService();
