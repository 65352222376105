import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import { InboundTransferDefault } from "@/components/inventory/BatchTransfer/declarations";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES,
  TRANSFER_SOURCE,
  TRANSFERABLE_TYPES
} from "@/interfaces/batchTransferManager";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import InboundTransferService from "@/services/BatchTransferManager/InboundTransfer.service";
import outboundTransferService from "@/services/BatchTransferManager/OutboundTransfer.service";
import purchaseOrdersService from "@/services/BatchTransferManager/PurchaseOrder.service";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import { roomService } from "@/services/room.service";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import InboundTransferMapper from "../../../EntityMappers/InboundTransfer.mapper";
import { inboundTransferTransferDetailsModelKeys } from "../../ModelKeys";
import TransferStep from "../../TransferStep.mixin";
import TransferValidators from "../../TransferValidators.mixin";
import Template from "./InboundTransferTransferDetails.template.vue";

interface ItemSource {
  id: string;
  name: string;
  vendor: string;
  license: string;
}
interface StepModel {
  manifest_id: string;
  pricing_enabled: boolean;
  source_type: TRANSFER_SOURCE;
  sourceable_transfer_type: string;
  source_id: number;
  transferable_type?: TRANSFERABLE_TYPES;
  transferable_id?: number;
  is_payable: boolean;
}
@Component({
  mixins: [Template]
})
export default class InboundTransferTransferDetails extends Mixins(
  TransferStep,
  StepsData,
  TransferValidators
) {
  /**
   * Gets sources
   */
  public get sources() {
    let lists: { [key: string]: any[] };
    if (this.transfer.id) {
      lists = {
        LOCATION_TRANSFER: [
          {
            id: this.transfer.source!.id,
            name: `OT - ${this.transfer.barcode}`
          }
        ],
        VENDOR_TRANSFER: [
          {
            id: this.transfer.source!.id,
            name: this.transfer.barcode,
            vendor: this.transfer.source!.name
          }
        ]
      };
    } else {
      lists = {
        LOCATION_TRANSFER: this.outboundTransfersList,
        VENDOR_TRANSFER: this.purchaseOrdersList,
        CANNABIS_VENDOR_TRANSFER: this.cannabisVendorsList,
        NON_CANNABIS_VENDOR_TRANSFER: this.vendorPurchaseOrdersList!
      };
    }
    return lists[this.model.source_type];
  }

  /**
   * Gets source label
   */
  public get sourceLabel() {
    const labels: { [key: string]: string } = {
      LOCATION_TRANSFER: String(
        this.$tc("batch_transfer_manager_module.labels.location", 1)
      ),
      VENDOR_TRANSFER: String(
        this.$tc("batch_transfer_manager_module.labels.vendor", 1)
      ),
      CANNABIS_VENDOR_TRANSFER: String(
        this.$tc("batch_transfer_manager_module.labels.cannabis_vendor", 1)
      ),
      NON_CANNABIS_VENDOR_TRANSFER: String(
        this.$tc("batch_transfer_manager_module.labels.non_cannabis_vendor", 1)
      )
    };
    return labels[this.model.source_type];
  }
  /**
   * Gets purchase id source label
   */
  public get purchaseIdSourceLabel() {
    const labels: { [key: string]: string } = {
      VENDOR_TRANSFER: String(
        this.$t("batch_transfer_manager_module.labels.purchase_id_label")
      ),
      CANNABIS_VENDOR_TRANSFER: String(
        this.$t(
          "batch_transfer_manager_module.labels.cannabis_vendor_purchase_id_label"
        )
      ),
      NON_CANNABIS_VENDOR_TRANSFER: String(
        this.$tc(
          "batch_transfer_manager_module.labels.non_cannabis_vendor_purchase_id_label",
          1
        )
      )
    };
    return labels[this.model.source_type];
  }
  /**
   * Gets wheter next step is available
   */
  public get nextStepEnabled() {
    return this.model.source_id > -1;
  }
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public modelKeys = inboundTransferTransferDetailsModelKeys;
  public model: StepModel = pick(
    InboundTransferDefault,
    this.modelKeys
  ) as StepModel;
  public transferTypes: BatchTransfer.SelectOption[] = [];
  public searchSourcesField = "";
  public searchSources = debounce(this.find, 500);
  public selectedLicense!: string;
  public selectedLocation!: string;
  public selectedPurchaseOrders!: BatchTransfer.PurchaseOrder[];
  public purchaseIdNumbers: BatchTransfer.SelectPID[] = [];
  public selectedManifestIds = [];
  public manifestIds: BatchTransfer.SelectPID[] = [];
  public selectedManifest = "";
  public manifestData = [];
  public filteredManifest = [];
  public loading = false;
  public dropdownData: BatchTransfer.SelectPID[] = [];
  public currentMID = "";
  public traceManifest!: any[];
  public qaCheck: any;
  public value: string = "";
  public initialLength!: any;
  public currentRoom!: any;
  public enteredNextStep: boolean = false;
  // Shows the string formatted in select
  public itemText(item: ItemSource) {
    return `${item.name}`;
  }

  /**
   * Updates show price
   */
  public updateShowPrice() {
    this.$emit("filterSteps", this.model.is_payable);
    this.transferChanges();
  }
  /**
   * Validates and go
   * @param {string} scope VeeValidate scope
   * @returns {void}
   */
  public async validateAndGo(scope: string) {
    if (this.enteredNextStep) {
      this.nextStep();
      return;
    }
    if (
      this.bioTrackTraceEnabled &&
      (this.model.source_type === "LOCATION_TRANSFER" ||
        this.model.source_type === "CANNABIS_VENDOR_TRANSFER")
    ) {
      try {
        this.loadingStep = true;
        this.filteredManifest = this.manifestData.filter(
          (item: { manifestid: string }) =>
            item.manifestid === this.selectedManifest
        );
        // GOT SELECTED MANIFEST DATA
        const traceManifest = await InboundTransferService.getSelectedManifestData(
          this.currentMID,
          this.showErrorPopUp
        );
        this.initialLength = traceManifest.data.data.length;
        EventBus.$emit("initiallength", this.initialLength);
        traceManifest.data.data.map((item: any) => {
          (item.currentProduct = item.product), (item.name = "");
          item.addedNew = false;
          item.id = "";
          item.sku = "";
          item.unit = "";
          item.strain = item.strain || "";
          item.atLocation = false;
          item.fromApi = false;
          item.tax_count = {
            pre_excise: 0,
            excise: 0,
            post_excise: 0,
            per_transaction: 0
          };
          item.priceUpdated = false;
          item.editedProduct = false;
          item.is_return = item.is_return || 0;
          (item.rooms = [
            {
              name: this.currentRoom! ? this.currentRoom!.name! : "",
              room_id: this.currentRoom! ? this.currentRoom!.id! : 0,
              quantity: 1
            }
          ]),
            (item.prices = {
              cost_per_unit: 0,
              price_per_unit: 0,
              subTotal: 0,
              total: 0,
              discount: {
                amount: 0,
                description: "",
                postTaxDiscount: false,
                type: DISCOUNT_FEE_TYPES.PERCENTAGE,
                value: 0
              },
              fee: {
                amount: 0,
                description: "",
                postTaxFee: false,
                type: DISCOUNT_FEE_TYPES.PERCENTAGE,
                value: 0
              },
              taxCollected: 0
            }),
            (item.product = {
              marijuana: 1,
              batch_type_id: "",
              unit: "",
              sku: "",
              strain: {
                name: ""
              }
            }),
            (item.lab_results = {
              laboratory_id: 0,
              testing_date: "",
              results: []
            });
        });
        if (this.model.source_type === "CANNABIS_VENDOR_TRANSFER") {
          const barcodes: any = [];
          traceManifest.data.data.forEach((item: any) => {
            barcodes.push(item.barcode_id);
          });
          this.qaCheck = await InboundTransferService.getLabData(barcodes);
          EventBus.$emit("labresultsdata", this.qaCheck.data);
          traceManifest.data.data.forEach((item: any) => {
            if (
              this.qaCheck.data[traceManifest.data.data.indexOf(item)]
                .lab_license
            ) {
              item.lab_results.results = this.qaCheck.data[
                traceManifest.data.data.indexOf(item)
              ].qa_check.test.type_2;
            }
          });
        }
        this.transferCustomChanges(traceManifest);
        this.nextStep();
      } catch (e) {
        return;
      } finally {
        this.loadingStep = false;
      }
    } else {
      if ((await this.$validator.validateAll(scope)) && this.nextStepEnabled) {
        try {
          this.loadingStep = true;
          if (!this.editingTransfer) {
            const inboundData = (await this.convertSource(
              this.model.source_id,
              this.model.source_type
            )) as BatchTransfer.Transfer;
            inboundData.transferable_type = this.model.transferable_type;
            inboundData.transferable_id = this.model.transferable_id;
            this.transferCustomChanges(inboundData);
          }
          this.nextStep();
        } catch (e) {
          return;
        } finally {
          this.loadingStep = false;
        }
      }
    }
  }

  public async selectVendor(license: string) {
    this.loading = true;
    this.selectedLicense = license;
    if (this.bioTrackTraceEnabled) {
      if (this.model.source_type === "CANNABIS_VENDOR_TRANSFER") {
        const traceManifest = await InboundTransferService.getTraceManifest(
          license
        );
        this.manifestData = traceManifest.data;
        this.selectedManifestIds = this.manifestData;
        this.manifestIds = this.selectedManifestIds.map(
          (item: { manifest_id: number; transfer_date: number }) => {
            return {
              text: `${String(item.manifest_id)} - ${item.transfer_date}`,
              value: item
            };
          }
        );
        this.dropdownData = this.manifestIds.reduce(
          (acc: any, current: any) => {
            const requiredId = acc.find(
              (item: { text: string }) => item.text === current.text
            );
            if (!requiredId) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );
      } else {
        const cannabisPos: BatchTransfer.PurchaseOrder[] = [];
        const nonCannabisPos: BatchTransfer.PurchaseOrder[] = [];
        this.purchaseOrders.map((po: BatchTransfer.PurchaseOrder) => {
          if (po.source!.license === license) {
            const poStatus: number[] = [];
            po.items.map(item => {
              if (item.product!.marijuana === 1) {
                poStatus.push(1);
              } else {
                poStatus.push(0);
              }
            });
            // purchase orders with only non cannabis products
            if (
              !poStatus.includes(1) &&
              this.model.source_type === "NON_CANNABIS_VENDOR_TRANSFER"
            ) {
              nonCannabisPos.push(po);
            }
            // purchase orders with atleast one cannabis products
            if (
              poStatus.includes(1) &&
              this.model.source_type === "CANNABIS_VENDOR_TRANSFER"
            ) {
              cannabisPos.push(po);
            }
            const selectedSourceType =
              this.model.source_type === "NON_CANNABIS_VENDOR_TRANSFER"
                ? nonCannabisPos
                : cannabisPos;
            this.purchaseIdNumbers = selectedSourceType.map(
              (item: BatchTransfer.PurchaseOrder) => {
                return {
                  text: "PID" + String(item.barcode),
                  value: item
                };
              }
            );
          }
        });
      }
      this.loading = false;
    } else {
      this.selectedPurchaseOrders = this.purchaseOrders.filter(
        (item: BatchTransfer.PurchaseOrder) => item.source!.license === license
      );
      this.purchaseIdNumbers = this.selectedPurchaseOrders.map(
        (item: BatchTransfer.PurchaseOrder) => {
          return {
            text: "PID" + String(item.barcode),
            value: item
          };
        }
      );
    }
  }

  public selectPID(value: { id: number }) {
    this.model.source_id = value!.id;
    this.model.transferable_type = TRANSFERABLE_TYPES.PURCHASE_ORDER;
    this.model.transferable_id = this.model.source_id;
    this.transferChanges();
  }

  public async selectLocation(license: string) {
    this.enteredNextStep = false;
    this.loading = true;
    const traceManifest = await InboundTransferService.getTraceManifest(
      license
    );
    this.manifestData = traceManifest.data;
    this.selectedManifestIds = this.manifestData;
    this.manifestIds = this.selectedManifestIds.map(
      (item: { manifest_id: string; transfer_date: string }) => {
        return {
          text: `${String(item.manifest_id)} - ${item.transfer_date}`,
          value: item
        };
      }
    );
    this.dropdownData = this.manifestIds.reduce((acc: any, current: any) => {
      const requiredId = acc.find(
        (item: { text: string }) => item.text === current.text
      );
      if (!requiredId) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    this.loading = false;
  }

  public createdDate(unixTimestamp: number) {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    const humanDateFormat = dateObject.toLocaleDateString();
    return humanDateFormat.split(" ")[0];
  }

  public selectMID(value: { manifest_id: string }) {
    this.currentMID = value.manifest_id;
    this.selectedManifest = value.manifest_id;
    this.model.manifest_id = this.selectedManifest;
    this.model.source_id = this.sources[0].id;
    this.model.transferable_type = TRANSFERABLE_TYPES.OUTBOUND_TRANSFER;
    this.model.transferable_id = Number("");
    this.transferChanges();
    // manifest id changed -> api call will happen
    this.enteredNextStep = false;
  }

  public sourceChanged() {
    if (this.model.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER) {
      this.model.transferable_type = TRANSFERABLE_TYPES.OUTBOUND_TRANSFER;
    } else {
      this.model.transferable_type = TRANSFERABLE_TYPES.PURCHASE_ORDER;
    }
    this.model.transferable_id = this.model.source_id;
    this.transferChanges();
  }

  public sourceTypeChanged() {
    this.enteredNextStep = false;
    // clearing fields and disabling next button when switching transfer type
    this.model.source_id = -1;
    this.value = "";
    const type: { [key: string]: (search: string) => Promise<void> } = {
      VENDOR_TRANSFER: this.loadPurchaseOrders,
      LOCATION_TRANSFER: this.loadOutboundTransfers,
      CANNABIS_VENDOR_TRANSFER: this.loadPurchaseOrders,
      NON_CANNABIS_VENDOR_TRANSFER: this.loadPurchaseOrders
    };
    this.model.transferable_id = Number("");
    if (this.bioTrackTraceEnabled) {
      if (this.model.source_type === "LOCATION_TRANSFER") {
        this.model.sourceable_transfer_type = "LOCATION_TRANSFER";
      } else if (this.model.source_type === "CANNABIS_VENDOR_TRANSFER") {
        this.model.sourceable_transfer_type = "CANNABIS_VENDOR_TRANFER";
      } else {
        this.model.sourceable_transfer_type = "NON_CANNABIS_VENDOR_TRANFER";
      }
    } else {
      if (this.model.source_type === "LOCATION_TRANSFER") {
        this.model.sourceable_transfer_type = "LOCATION_TRANSFER";
      } else if (this.model.source_type === "VENDOR_TRANSFER") {
        this.model.sourceable_transfer_type = "VENDOR_TRANSFER";
      }
    }
    type[this.model.source_type]("");
    if (
      this.model.sourceable_transfer_type === "NON_CANNABIS_VENDOR_TRANFER" ||
      this.model.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER"
    ) {
      EventBus.$emit("changedtransferTypes", this.model);
      this.loadNonCannabisProducts();
    }
  }
  public async renderPopupModal(
    titleName: string,
    messageName: string,
    descriptionName: string,
    isAcceptButton: boolean,
    isCancleButton: boolean,
    acceptButtonValue?: string,
    progressBar?: boolean
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: isAcceptButton
                ? "/img/icon_primary_menu_retail@2x.009e06e8.png"
                : "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            title: {
              name: i18n.t(titleName),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: i18n.t(messageName),
              style: "fontSize:28px ; fontWeight:600"
            },
            description: {
              name: i18n.t(descriptionName),
              style: "fontSize:23px"
            },
            acceptButton: isAcceptButton,
            cancelButton: isCancleButton,
            progressBar,
            acceptButtonValue
          }
        }
      )
      .catch(() => false)) as boolean;
    return confirm;
  }
  protected async loadRooms() {
    const res = await roomService.getAll();
    this.currentRoom = res.find(room => room.room_type === "BACKSTOCK_ROOM");
    this.resourcesLoaded.rooms = true;
  }

  @Watch("searchSourcesField")
  protected searchField(searchString: string) {
    searchString = "";
    if (this.nextStepEnabled || !searchString) {
      return;
    }
    this.searchSources(
      searchString,
      this.model.source_type === "VENDOR_TRANSFER"
        ? this.loadPurchaseOrders
        : this.loadOutboundTransfers
    );
  }

  protected mounted() {
    this.loadRooms();
    if (this.bioTrackTraceEnabled) {
      this.transferTypes = [
        {
          text: this.$tc(
            "batch_transfer_manager_module.labels.location_transfer",
            1
          ),
          value: "LOCATION_TRANSFER"
        },
        {
          text: this.$tc(
            "batch_transfer_manager_module.labels.cannabis_vendor_transfer",
            1
          ),
          value: "CANNABIS_VENDOR_TRANSFER"
        },
        {
          text: this.$tc(
            "batch_transfer_manager_module.labels.non_cannabis_vendor_transfer",
            1
          ),
          value: "NON_CANNABIS_VENDOR_TRANSFER"
        }
      ];
    } else {
      this.transferTypes = [
        {
          text: this.$tc(
            "batch_transfer_manager_module.labels.location_transfer",
            1
          ),
          value: "LOCATION_TRANSFER"
        },
        {
          text: this.$tc(
            "batch_transfer_manager_module.labels.vendor_transfer",
            1
          ),
          value: "VENDOR_TRANSFER"
        }
      ];
    }
    this.sourceTypeChanged();
    this.updateShowPrice();
    EventBus.$on("enteredNextStep", (value: boolean) => {
      this.enteredNextStep = value;
    });
  }
  /**
   * Converts source
   * @param {number} id
   * @param {TransferSource} type
   * @returns {BatchTransfer.InboundTransfer} Inbound transfer after mapper
   */
  protected async convertSource(id: number, type: TRANSFER_SOURCE) {
    const types = {
      LOCATION_TRANSFER: async () => await this.inboundFromOutbound(id),
      VENDOR_TRANSFER: async () => await this.inboundFromPurchaseOrder(id),
      CANNABIS_VENDOR_TRANSFER: async () =>
        await this.inboundFromPurchaseOrder(id),
      EXISTING_INVENTORY: async () => await this.inboundFromOutbound(id),
      NON_CANNABIS_VENDOR_TRANSFER: async () =>
        await this.inboundFromPurchaseOrder(id)
    };
    return types[type]();
  }
  /**
   * Inbounds from outbound
   * @param {number} id
   * @returns {BatchTransfer.InboundTransfer} Inbound transfer after mapper
   */
  protected async inboundFromOutbound(id: number) {
    try {
      const transfer = await outboundTransferService.getIncomingById(id, {
        embed: "location,transferItems.batch",
        no_pagination: true
      });
      const mapper = new InboundTransferMapper();
      return mapper.converteOutboundTransfer(transfer!);
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.resource_not_found")
        ),
        "error"
      );
    }
  }
  /**
   * Inbounds from purchase order
   * @param {number} id
   * @returns {BatchTransfer.InboundTransfer} Inobund transfer after mapper
   */
  protected async inboundFromPurchaseOrder(id: number) {
    try {
      const purchaseOrder = await purchaseOrdersService.getById(id);
      const mapper = new InboundTransferMapper();
      return mapper.convertPurchaseOrder(purchaseOrder!);
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.resource_not_found")
        ),
        "error"
      );
    }
  }
  private async showErrorPopUp(errorMessage: string) {
    if (await this.$validator.validateAll()) {
      return await this.renderPopupModal(
        "biotrack_traceability.biotrack_traceability",
        "inbound_transfers_trace_error",
        i18n.t("inbound_transfers_trace_error_desc") + ` : ${errorMessage}`,
        true,
        false,
        "OK"
      );
    }
  }
}
