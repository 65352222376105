import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import { PurchaseOrderDefault } from "@/components/inventory/BatchTransfer/declarations";
import {
  BatchTransfer,
  TRANSFER_SOURCE
} from "@/interfaces/batchTransferManager";
import { HelixDatePickerComponent } from "helix-vue-components";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { purchaseOrderTransferDetailModelKeys } from "../../ModelKeys";
import TransferValidators from "../../TransferValidators.mixin";
import Template from "./PurchaseOrderTransferDetail.template.vue";

interface StepModel {
  pricing_enabled: boolean;
  source_id: number;
  source_type: TRANSFER_SOURCE;
  request_on_or_before: string;
}
@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class PurchaseOrderTransferDetail extends Mixins(
  TransferStep,
  StepsData,
  TransferValidators
) {
  public searchSourcesField = "";
  public searchSources = debounce(this.find, 500);
  public modelKeys = purchaseOrderTransferDetailModelKeys;
  public model: StepModel = pick(
    PurchaseOrderDefault,
    this.modelKeys
  ) as StepModel;

  public sourceTypes: BatchTransfer.SelectOption[] = [
    { text: "Location Transfer", value: "LOCATION_TRANSFER" },
    { text: "Vendor Transfer", value: "VENDOR_TRANSFER" }
  ];

  /**
   * Gets list
   */
  get list() {
    return (this.transfer as BatchTransfer.PurchaseOrder).source_type ===
      "VENDOR_TRANSFER"
      ? this.vendors
      : this.locations;
  }

  /**
   * Gets source label
   */
  get sourceLabel() {
    return (this.transfer as BatchTransfer.PurchaseOrder).source_type ===
      "VENDOR_TRANSFER"
      ? this.$tc("batch_transfer_manager_module.labels.vendor", 1)
      : this.$tc("batch_transfer_manager_module.labels.location", 1);
  }

  /**
   * Updates transfer type
   */
  public updateTransferType() {
    this.model.source_id = -1;
    this.callbackType[this.model.source_type]("");
    this.transferChanges();
  }

  /**
   * Updates date and emits transfer changes
   * @param {string} date
   */
  public updateDate(date: string) {
    this.model.request_on_or_before = date;
    this.transferChanges();
  }

  /**
   * Validates and go
   * @param {string} scope VeeValidate scope
   */
  public async validateAndGo(scope: string) {
    if ((await this.$validator.validateAll()) && this.nextStepEnabled) {
      this.nextStep();
    }
  }

  /**
   * Determines whether the field has an error
   * @param {string} field
   * @returns {boolean} If the form has errors or not
   */
  public hasError(field: string) {
    return this.$validator.errors.has(field);
  }

  /**
   * Getter of next step availability
   */
  public get nextStepEnabled() {
    if (
      this.model.source_type === "VENDOR_TRANSFER" &&
      typeof this.model.source_id === "string"
    ) {
      return true;
    }
    return this.model.source_id > -1;
  }

  @Watch("searchSourcesField")
  protected searchField(searchString: string) {
    this.searchSources(searchString, this.callbackType[this.model.source_type]);
  }
}
