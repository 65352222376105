import ComputeTransfer from "@/components/inventory/BatchTransfer/BatchTransferManager/ComputeTransfer.mixin";
import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchDetailsContainerComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsContainer/BatchDetailsContainer.component";
import BatchDetailsContainerSectionComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsSection/BatchDetailsSection.component";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import PricingBreakdownForm from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownForm/PricingBreakdownForm.component";
import PricingBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownSummary/PricingBreakdownSummary.component";
import {
  QuoteDefault,
  QuoteItemDefault
} from "@/components/inventory/BatchTransfer/declarations";
import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import { InventoryBatch } from "@/interfaces/batch";
import {
  BatchTransfer,
  PRICE_POINTS_TYPE,
  PRODUCT_UNIT
} from "@/interfaces/batchTransferManager";
import { Product } from "@/interfaces/product";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import { Component, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { batchDetailsModelKeys } from "../../ModelKeys";
import TransferValidators from "../../TransferValidators.mixin";
import Template from "./QuoteBatchDetails.template.vue";

const DEFAULT_BATCH = {
  batch_uid: "",
  secondary_id: "",
  quantity: 0
};
interface StepModel {
  items: BatchTransfer.QuoteItem[];
  notes: BatchTransfer.TransferNote[];
  prices: BatchTransfer.TransferPrices;
}
@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "pricing-breakdown-form": PricingBreakdownForm,
    "batch-details-container": BatchDetailsContainerComponent,
    "pricing-breakdown-summary": PricingBreakdownSummary,
    "batch-detail-section": BatchDetailsContainerSectionComponent,
    "payment-breakdown-summary": PaymentBreakdownSummary
  }
})
export default class QuoteBatchDetails extends Mixins(
  TransferStep,
  ComputeTransfer,
  StepsData,
  TransferValidators
) {
  public weighableUnits: Array<{ label: string; value: PRODUCT_UNIT }> = [
    { label: "Grams", value: PRODUCT_UNIT.GRAMS },
    { label: "Milligrams", value: PRODUCT_UNIT.MG },
    { label: "Ounces", value: PRODUCT_UNIT.OZ }
  ];
  public modelKeys = batchDetailsModelKeys;
  public model: StepModel = pick(QuoteDefault, this.modelKeys) as StepModel;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public selectedBatch: number = -1;
  public editFromInventory = false;
  public loadingItems = false;
  public currentProductBatches: InventoryBatch[] = [];
  /**
   * Adds new batch
   */
  public addNewBatch() {
    const batch = cloneDeep(QuoteItemDefault);
    batch.id = Date.now();
    this.model.items.push(batch);
    this.selectedBatch = this.model.items.length - 1;
  }
  /**
   * Validates and save
   */
  public async validateAndSave() {
    if (await this.$validator.validateAll("edit-batch")) {
      this.validateListAndEmitChanges();
    }
  }
  /**
   * Edits batch
   * @param {number} id
   */
  public editBatch(id: number) {
    this.selectedBatch = this.model.items.findIndex(batch => batch.id === id);
    this.editingBatch = this.transferItems[
      this.selectedBatch
    ] as BatchTransfer.QuoteItem;
    this.editFromInventory =
      this.$route.params.batches &&
      Object(this.$route.params.batches).find(
        (item: InventoryBatch) => item.batch_uid === this.editingBatch.batch_uid
      );

    this.editingBatch.sku =
      this.editingBatch.product!.concrete_sku || this.editingBatch.product!.sku;
    this.getCurrentProductBatches();
  }
  public get getProducts() {
    return this.editFromInventory && this.editingBatch.product
      ? [this.editingBatch.product]
      : this.productsList;
  }

  /**
   * Cancels edition
   */
  public cancelEdition() {
    this.editingBatch.batch = undefined;
    if (this.transferItems.length) {
      this.selectedBatch = -1;
      this.model.items = this.model.items.filter(item => item.batch_uid);
    }
  }

  /**
   * Removes batch
   * @param {number} index
   */
  public removeBatch(index: number) {
    if (
      this.selectedBatch ===
      this.model.items.findIndex(item => index === item.id)
    ) {
      this.selectedBatch = -1;
    }
    this.model.items.find(item => item.id === index)!.destroy = true;
    this.transferChanges();
  }

  /**
   * Changes product. Handler for product form field.
   */
  public async changeProduct() {
    this.editingBatch.product =
      (this.editingBatch.sku &&
        this.productsList.find(p => p.sku === this.editingBatch.sku)) ||
      undefined;
    this.editingBatch = (await this.resetProduct(
      this.editingBatch
    )) as BatchTransfer.QuoteItem;
    this.getCurrentProductBatches();
  }
  public async getCurrentProductBatches() {
    // call for fetching current product batches
    const batches = await this.getProductBatches(null, true);
    this.currentProductBatches = batches.filter(
      (batch: InventoryBatch) => batch.in_store_quantity_value
    );
  }

  public get getBatchItems() {
    const productBatches = cloneDeep(this.currentProductBatches);
    productBatches.map(item => {
      if (this.bioTrackTraceEnabled && item.biotrack_traceability_id !== "") {
        item.batchUid = item.biotrack_traceability_id;
      } else {
        item.batchUid = item.batch_uid;
      }
    });
    return productBatches;
  }
  public batchSelectedIsInvalid(batchSelected: InventoryBatch) {
    const currentBatch = this.transferItems[
      this.selectedBatch
    ] as BatchTransfer.QuoteItem;
    const invalidBatch =
      batchSelected &&
      this.transferItems.find(
        p =>
          (p as BatchTransfer.QuoteItem).batch!.batch_uid ===
          batchSelected.batch_uid
      ) &&
      (!currentBatch ||
        (currentBatch &&
          currentBatch.batch!.batch_uid !== batchSelected.batch_uid));
    if (invalidBatch) {
      const editingBatch = currentBatch || {
        ...this.setBatch(DEFAULT_BATCH as BatchTransfer.QuoteItem),
        batch: undefined
      };
      (this.$refs.batchesAutocomplete as HTMLFormElement).setValue(
        editingBatch.batch
      );
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("batch_transfer_manager_module.messages.batch_already")),
        "warning"
      );
      return editingBatch;
    }
    return null;
  }

  /**
   * Selects item batch
   * @param {InventoryBatch} batchSelected
   */
  public selectItemBatch(batchSelected: InventoryBatch) {
    const batch =
      (batchSelected &&
        (this.batchSelectedIsInvalid(batchSelected) ||
          this.setBatch(batchSelected as InventoryBatch))) ||
      this.setBatch(DEFAULT_BATCH as BatchTransfer.QuoteItem);
    this.editingBatch = batch;
  }
  /**
   * Getter show table label
   */
  public get showTableLabel() {
    return this.transfer.pricing_enabled
      ? []
      : ["total", "sub_total", "tax", "price_per_unit"];
  }
  /**
   * Getter editing batch
   */
  public get editingBatch(): BatchTransfer.QuoteItem {
    const lineItem =
      (this.model.items[this.selectedBatch] as BatchTransfer.QuoteItem) ||
      false;
    const calculated = lineItem
      ? (this.recalculateBatchPrices(lineItem) as BatchTransfer.QuoteItem)
      : false;
    return calculated ? calculated : lineItem;
  }
  /**
   * Setter editing batch
   */
  public set editingBatch(batch: BatchTransfer.QuoteItem) {
    const items = cloneDeep(this.model.items);
    items[this.selectedBatch] = this.recalculateBatchPrices(
      batch
    ) as BatchTransfer.QuoteItem;
    this.model.items = items;
  }
  /**
   * Handler for tax category form field.
   * @param {TaxCategory} category
   */
  public async setTax() {
    this.editingBatch = (await this.setupBatchTaxCategories(
      this.editingBatch
    )) as BatchTransfer.QuoteItem;
    this.editingBatch = this.processTaxCategory(
      this.editingBatch,
      this.editingBatch.tax_category!
    ) as BatchTransfer.QuoteItem;
  }

  /**
   * Handler for manual input of tax on Pricing component.
   * @param {number} collected
   */
  public nullTaxCategory(collected: number) {
    const clone = this.voidTaxCategory(this.editingBatch);
    clone.prices.taxCollected = collected;
    this.editingBatch = clone as BatchTransfer.QuoteItem;
  }

  /**
   * Prints barcode
   */
  public printBarcode() {
    EventBus.$emit("print", {
      component: BarcodeListComponent,
      props: {
        batches: [this.editingBatch.batch]
      }
    });
  }

  /**
   * Prints label
   */
  public printLabel() {
    EventBus.$emit("print", {
      component: InventoryLabelListComponent,
      props: {
        batches: [this.editingBatch.batch],
        labelType: "INVENTORY"
      }
    });
  }

  /**
   * Updates editing batch
   */
  public async updateEditingBatch() {
    this.editingBatch = (await this.resetProduct(
      this.editingBatch
    )) as BatchTransfer.QuoteItem;
  }

  protected async initModelfromInventory() {
    const items: InventoryBatch[] = Object(this.$route.params.batches);
    if (items.length && !this.transfer.items.length) {
      this.loadingItems = true;
      this.model.items = await Promise.all(
        items.map(async (b: InventoryBatch) => {
          const newQuoteItem: BatchTransfer.QuoteItem = {
            ...QuoteItemDefault,
            id: Date.now(),
            usable_weight_value:
              (b.product_variant && b.product_variant.usable_weight_value) ||
              undefined,
            usable_weight_unit:
              (b.product_variant && b.product_variant.usable_weight_unit) ||
              undefined,
            sku: b.product!.sku,
            batch: b,
            product: b.product! as Product
          };
          const formatted = this.setBatch(b, newQuoteItem);
          return this.patchBatchPrices(formatted) as Promise<
            BatchTransfer.OutboundTransferItem
          >;
        })
      );
      this.transferChanges();
    }
    this.loadingItems = false;
    return items.length;
  }

  protected mounted() {
    this.editFromInventory = !!this.initModelfromInventory();
    this.pricePointType = PRICE_POINTS_TYPE.WHOLESALE_OUTBOUND;
    this.loadProducts().then(() => {
      Promise.all(
        this.model.items.map(async batch => {
          const b = (await this.setupBatchProduct(
            batch
          )) as BatchTransfer.QuoteItem;
          return b;
        })
      ).then(values => (this.model.items = values));
      if (this.transfer.items.length) {
        this.transferChanges();
      }
    });
    this.loadTaxCategories().then(() => {
      Promise.all(
        this.model.items.map(
          async batch =>
            (await this.setupBatchTaxCategories(
              batch
            )) as BatchTransfer.QuoteItem
        )
      ).then(values => (this.model.items = values));
      if (this.transfer.items.length) {
        this.transferChanges();
      }
    });

    if (!this.modelItems.length) {
      this.addNewBatch();
    }
    this.loadBatchLevels(); // It will load Batch Level Pricing List
  }

  /**
   * Validates step
   * @returns {void}
   */
  protected validateStep() {
    if (this.transferItems.find(item => !item.sku)) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.missing_form_data")
        ),
        "error"
      );
      return;
    }
    if (!this.transferItems.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("batch_transfer_manager_module.messages.no_items")),
        "warning"
      );
      return;
    }
    this.nextStep();
  }

  /**
   * Validates list and emit changes
   */
  protected validateListAndEmitChanges() {
    this.selectedBatch = -1;
    this.model.items = this.model.items.filter(item => item.batch_uid);
    this.transferChanges();
  }

  /**
   * set Current Batch with PricePoints.
   */

  protected setBatch(
    batchSelected: InventoryBatch | BatchTransfer.QuoteItem,
    newQuoteItem?: BatchTransfer.QuoteItem
  ) {
    const batch = cloneDeep(newQuoteItem || this.editingBatch);
    batch.batch_uid = (batchSelected && batchSelected.batch_uid) || "";
    batch.secondary_id =
      (batchSelected && (batchSelected as InventoryBatch).tracking_id) || "";
    batch.quantity =
      (batchSelected &&
        (batchSelected as InventoryBatch).in_store_quantity_value) ||
      (batchSelected as BatchTransfer.QuoteItem).quantity ||
      0;
    // if (
    //   batchSelected &&
    //   (batchSelected as InventoryBatch).product_variant.strain
    // ) {
    //   batch.batch!.strain = (batchSelected as InventoryBatch).product_variant.strain;
    // }
    const pricePoint = this.getPricePoint(
      PRICE_POINTS_TYPE.WHOLESALE_OUTBOUND,
      this.currentRules,
      batch.quantity
    );
    batch.prices.price_per_unit =
      (pricePoint &&
        pricePoint.break &&
        parseFloat(
          Number(pricePoint.break.price / pricePoint.break.quantity).toFixed(2)
        )) ||
      0;
    return batch;
  }
}
