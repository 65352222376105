import { policyList } from "@/enums/permissions";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { BatchTransferService } from "@/services/BatchTransferManager/batchTransferManager.service";
import { BooleanCheck } from "helix-vue-components";
import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import keys from "lodash/keys";
import pick from "lodash/pick";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class TransferStep extends Vue {
  @Prop({ required: true }) public transfer!: BatchTransfer.Transfer;
  @Prop() public service!: BatchTransferService;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public model!: Partial<BatchTransfer.Transfer>;
  public modelKeys: string[] = [];
  public policyList = policyList;

  @Prop() protected resetedModel: number = 0;
  @Prop() protected editedModel: number = 0;
  @Prop({ default: false }) protected editingTransfer!: boolean;
  protected editing: boolean = false;
  protected disabled: boolean = false;

  /**
   * Transfers changes
   */
  public transferChanges() {
    this.$emit("transferChanges", this.model);
  }

  /**
   * Transfers custom changes
   * @param {BatchTransfer.Transfer} data
   */
  public transferCustomChanges(data: BatchTransfer.Transfer) {
    this.$emit("transferChanges", data);
  }
  /**
   * Updates on collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public updateOnCollection(transfer: BatchTransfer.Transfer) {
    this.$emit("updateOnCollection", transfer);
  }
  /**
   * Saves to collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public saveToCollection(transfer: BatchTransfer.Transfer) {
    this.$emit("saveToCollection", transfer);
  }

  /**
   * Removes from collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public removeFromCollection(transfer: BatchTransfer.Transfer) {
    this.$emit("removeFromCollection", transfer);
  }

  /**
   * Resets transfer
   */
  public resetTransfer() {
    this.$emit("resetTransfer");
    this.editing = false;
  }

  /**
   * Edits from collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public editFromCollection(transfer: BatchTransfer.Transfer) {
    this.$emit("editFromCollection", transfer);
    this.nextStep();
  }

  /**
   * Next step
   */
  public nextStep() {
    this.$emit("next");
  }

  /**
   * Prev step
   */
  public prevStep() {
    this.$emit("prev");
  }

  /**
   * Finishs transfer step
   */
  public finish() {
    this.$emit("finish");
  }

  /**
   * Gets transfer items
   */
  public get transferItems() {
    return this.transfer && this.transfer.items
      ? this.transfer.items.filter(item => !item.destroy && item.product)
      : [];
  }

  /**
   * Gets model items
   */
  public get modelItems() {
    return this.model && this.model.items
      ? this.model.items.filter(item => !item.destroy)
      : [];
  }

  /**
   * Merges changes
   * @param {BatchTransfer.Transfer} value
   */
  public mergeChanges(value: Partial<BatchTransfer.Transfer>) {
    this.model = { ...this.model, ...cloneDeep(value) };
    this.transferChanges();
  }

  /**
   * Mounted transfer step
   */
  protected mounted() {
    this.cloneModel();
    this.$watch("resetedModel", () => {
      this.editing = false;
      this.resetModel();
    });
    this.$watch("editedModel", () => {
      this.editing = true;
      this.editModel();
    });
  }

  /**
   * Resets model
   */
  protected resetModel() {
    this.cloneModel();
  }

  /**
   * Edits model
   */
  protected editModel() {
    this.cloneModel();
  }

  /**
   * Clones model
   */
  protected cloneModel() {
    this.modelKeys =
      this.modelKeys && !this.modelKeys.length
        ? keys(this.model)
        : this.modelKeys;
    this.model = assign(
      cloneDeep(this.model),
      pick(cloneDeep(this.transfer), this.modelKeys)
    );
  }
}
