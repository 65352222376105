import { render, staticRenderFns } from "./NewBatchReview.template.vue?vue&type=template&id=5ef818e8&scoped=true&"
var script = {}
import style0 from "./NewBatchReview.template.vue?vue&type=style&index=0&id=5ef818e8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef818e8",
  null
  
)

export default component.exports