import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import { QuoteDefault } from "@/components/inventory/BatchTransfer/declarations";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { EventBus } from "@/internal";
import { QuoteService } from "@/services/BatchTransferManager/Quote.service";
import { messagesService } from "@/services/messages.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import { Component, Mixins, Prop } from "vue-property-decorator";
import QuotePrintComponent from "../../../BatchTransferList/QuotesDataTable/print/QuotePrint.component";
import TransferValidators from "../../TransferValidators.mixin";
import Template from "./QuoteReviewPrint.template.vue";

@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "payment-breakdown-summary": PaymentBreakdownSummary
  },
  filters: {
    date: (date: string) => {
      return date
        ? fnsFormatDate(new Date(date), FNS_DATE_FORMATS.BARS_DEFAULT)
        : "--";
    }
  }
})
export default class QuoteReviewPrint extends Mixins(
  TransferStep,
  TransferValidators
) {
  @Prop() public transfer!: BatchTransfer.Quote;
  public model: BatchTransfer.Quote = QuoteDefault;

  /**
   * Gets destination label
   */
  public get destinationLabel() {
    const labels: { [key: string]: string } = {
      VENDOR_TRANSFER: this.$tc(
        "batch_transfer_manager_module.labels.vendor",
        1
      ),
      LOCATION_TRANSFER: this.$tc(
        "batch_transfer_manager_module.labels.location",
        1
      )
    };
    return labels[(this.transfer as BatchTransfer.Quote).destination_type];
  }
  /**
   * Creates or updates a quote then prints it
   */
  public async save() {
    this.disabled = true;
    const isValid = await this.$validator.validateAll("purchase_order");
    let quote;
    if (isValid) {
      if (this.$route.params.id) {
        quote = await (this.service as QuoteService).update(
          this.$route.params.id,
          this.transfer
        );
      } else {
        this.transfer.destination_id = String(this.transfer.destination_id);
        quote = await (this.service as QuoteService).create(this.transfer);
      }

      if (!quote) {
        this.disabled = false;
        return;
      }
      if (this.hasPermission(this.policyList.printQuote)) {
        EventBus.$emit("print", {
          component: QuotePrintComponent,
          props: {
            data: quote.data
          }
        });
      }

      this.$router.push({ name: "batch-transfer", params: { type: "quote" } });
    } else {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.whole_process_failed")
        ),
        "red"
      );
      this.disabled = false;
    }
  }
  /**
   * Gets show table label
   */
  get showTableLabel() {
    return this.transfer.pricing_enabled
      ? []
      : ["total", "sub_total", "tax", "price_per_unit"];
  }
}
