var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.sourceType === 'LOCATION_TRANSFER' ||
      _vm.sourceType === 'CANNABIS_VENDOR_TRANSFER'
    )?_c('div',[_c('div',[_c('v-data-table',{attrs:{"headers":_vm.tableData.headers,"items":_vm.tableData.items},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [(props.header.tooltip)?[_c('span',[_vm._v(_vm._s(props.header.text))]),_vm._v(" "),_c('v-tooltip',{attrs:{"max-width":"250px","bottom":""}},[_c('template',{slot:"activator"},[_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fal fa-question-circle")])],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(props.header.tooltip)))])],2)]:_c('span',[_vm._v(_vm._s(props.header.text))])]}},{key:"items",fn:function(props){return [_c('tr',{class:{ 'removed-tr': !!props.item.destroy }},[_vm._l((_vm.filteredItems),function(key,index){return _c('td',{key:index},[(key !== 'name')?_c('div',[_vm._v("\n                "+_vm._s(props.item[key])+"\n              ")]):_vm._e(),_vm._v(" "),_c('div',[(props.item['name'] === '' && key === 'name')?_c('div',[_c('div',{staticClass:"tooltip"},[(props.item[key] === '')?_c('v-tooltip',{staticClass:"mt-3",attrs:{"top":""}},[_c('v-icon',{attrs:{"slot":"activator","size":"25","color":"yellow"},slot:"activator"},[_vm._v("fas fa-exclamation-triangle")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.$t("product_unavailable")))])],1):_vm._e(),_vm._v(" "),_c('v-autocomplete',{ref:"inputProduct",refInFor:true,staticClass:"dropdown-size",attrs:{"name":"product","item-text":"name","item-value":"item","id":"name","items":_vm.selectedInventoryType,"placeholder":_vm.$t('type_to_search_product')},on:{"click":function($event){_vm.productsName(props.item)},"change":function($event){_vm.selectedName(
                          $event,
                          $event.target,
                          props.index,
                          props.item.id
                        )}}}),_vm._v(" "),_c('div',{staticClass:"product_name"},[_vm._v("\n                      "+_vm._s(props.item[key])+"\n                    ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""}},[_c('div',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-btn',{staticClass:"mt-3 circular-button-dropdown",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.addNewProduct(
                              props.index,
                              props.item,
                              props.item.id
                            )}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("fal fa-plus")])],1)],1),_vm._v(" "),_c('div',[_vm._v("\n                        "+_vm._s(_vm.$t(
                            "batch_transfer_manager_module.labels.add_new_product"
                          ))+"\n                      ")])])],1)]):(props.item['name'] !== '' && key === 'name')?_c('div',[_c('div',{staticClass:"tooltip"},[(props.item['name'] === '')?_c('v-tooltip',{staticClass:"mt-3",attrs:{"top":""}},[_c('v-icon',{attrs:{"slot":"activator","size":"25","color":"yellow"},slot:"activator"},[_vm._v("fas fa-exclamation-triangle")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.$t("product_unavailable")))])],1):_vm._e(),_vm._v(" "),(props.item['name'] === '' && _vm.addedNew)?_c('v-autocomplete',{ref:"inputProduct",refInFor:true,staticClass:"dropdown-size",attrs:{"name":"product","item-text":"name","item-value":"item","id":"name","items":_vm.selectedInventoryType,"placeholder":_vm.$t('type_to_search_product')},on:{"click":function($event){_vm.productsName(props.item)},"change":function($event){_vm.selectedName(
                          $event,
                          $event.target,
                          props.index,
                          props.item.id
                        )}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"product_name"},[_vm._v("\n                      "+_vm._s(props.item[key])+"\n                    ")]),_vm._v(" "),(
                        props.item['name'] !== '' &&
                        !props.item.fromApi &&
                        _vm.page
                      )?_c('v-tooltip',{attrs:{"top":""}},[_c('div',{staticClass:"alignButton",attrs:{"slot":"activator"},slot:"activator"},[_c('v-btn',{staticClass:"mt-3 circular-button",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.addNewProduct(
                              props.index,
                              props.item,
                              props.item.id
                            )}}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fal fa-plus")])],1)],1),_vm._v(" "),_c('div',[_vm._v("\n                        "+_vm._s(_vm.$t(
                            "batch_transfer_manager_module.labels.add_new_product"
                          ))+"\n                      ")])]):_vm._e()],1)]):_vm._e()])])}),_vm._v(" "),(_vm.actions.length > 0)?_c('td',{staticClass:"table-actions justify-center layout px-0"},[(_vm.editable)?_c('v-icon',{staticClass:"table-actions__icon",attrs:{"small":""},on:{"click":function($event){_vm.editBatchForTrace(
                    props.item.id,
                    props.item.sku,
                    props.item.atLocation,
                    props.item.name,
                    props.index
                  )}}},[_vm._v("fal fa-edit")]):_vm._e(),_vm._v(" "),(_vm.removable)?_c('v-icon',{staticClass:"table-actions__icon",attrs:{"small":""},on:{"click":function($event){_vm.remove(props.item.id)}}},[_vm._v("fal fa-trash")]):_vm._e()],1):_vm._e()],2)]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"no-data-alert",attrs:{"value":true,"color":"#F1F1F1"}},[_vm._v(_vm._s(_vm.$t("add_batches")))])],1)],2)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }