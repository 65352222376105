import InboundBatchList from "@/components/inventory/BatchTransfer/BatchTransferManager/InboundTransfer/InboundBatchList/InboundBatchList.component";
import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchDetailsContainerComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsContainer/BatchDetailsContainer.component";
import BatchDetailsContainerSectionComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsSection/BatchDetailsSection.component";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import LabDetailsForm from "@/components/inventory/BatchTransfer/BuildBlocks/LabDetailsForm/LabDetailsForm.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import PricingBreakdownForm from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownForm/PricingBreakdownForm.component";
import PricingBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownSummary/PricingBreakdownSummary.component";
import {
  InboundCannabisVendorTransfer,
  InboundTransferDefault,
  InboundTransferItemDefault
} from "@/components/inventory/BatchTransfer/declarations";
import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import { EventBus } from "@/event-bus";
import { Batch } from "@/interfaces/batch";
import {
  BatchTransfer,
  PRICE_POINTS_TYPE,
  PRODUCT_UNIT,
  TRANSFER_SOURCE
} from "@/interfaces/batchTransferManager";
import { Product } from "@/interfaces/product";
import batchTransferManagerService from "@/services/BatchTransferManager/batchTransferManager.service";
import InboundTransferService from "@/services/BatchTransferManager/InboundTransfer.service";
import { messagesService } from "@/services/messages.service";
import differenceInDays from "date-fns/differenceInDays";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ComputeTransfer from "../../ComputeTransfer.mixin";
import { batchDetailsModelKeys } from "../../ModelKeys";
import { TABLEACTIONS } from "./../../../BuildBlocks/BatchesList/BatchesList.component";
import { INBOUNDTABLEACTIONS } from "./../InboundBatchList/InboundBatchList.component";
import Template from "./InboundTransferBatchDetails.template.vue";

interface StepModel {
  items: BatchTransfer.InboundTransferItem[];
  notes: BatchTransfer.TransferNote[];
  prices: BatchTransfer.TransferPrices;
}

@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "inbound-batch-list": InboundBatchList,
    "pricing-breakdown-form": PricingBreakdownForm,
    "pricing-breakdown-summary": PricingBreakdownSummary,
    "payment-breakdown-summary": PaymentBreakdownSummary,
    "batch-details-container": BatchDetailsContainerComponent,
    "batch-detail-section": BatchDetailsContainerSectionComponent,
    "date-input": HelixDatePickerComponent,
    "lab-details-form": LabDetailsForm
  }
})
export default class InboundTransferBatchDetails extends Mixins(
  TransferStep,
  ComputeTransfer,
  StepsData
) {
  public get canAddBatches(): boolean {
    if (
      this.bioTrackTraceEnabled &&
      this.transfer.source_type === TRANSFER_SOURCE.CANNABIS_VENDOR_TRANSFER
    ) {
      return true;
    } else if (
      this.transfer.source_type !== TRANSFER_SOURCE.LOCATION_TRANSFER
    ) {
      return !!(
        this.transfer.items!.length &&
        this.selectedBatch < 0 &&
        !this.editingTransfer
      );
    } else {
      return false;
    }
  }

  /**
   * Gets editing batch
   */
  public get editingBatch(): BatchTransfer.InboundTransferItem {
    if (this.active && !this.modelItems.length) {
      this.addNewBatch();
    }
    if (
      this.transfer.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER" &&
      this.fromAddNew
    ) {
      // @ts-ignore
      return this.productsList[0];
    } else {
      return this.model.items![this.selectedBatch];
    }
  }

  /**
   * Sets editing batch
   */
  public set editingBatch(batch: BatchTransfer.InboundTransferItem) {
    const items = cloneDeep(this.model.items);
    items![this.selectedBatch] = this.recalculateBatchPrices(
      batch
    ) as BatchTransfer.InboundTransferItem;
    this.model.items = items;
    if (this.bioTrackTraceEnabled) {
      this.priceEdited = items![this.selectedBatch];
      if (this.fromAddNew) {
        this.productsList[0].prices = this.priceEdited.prices;
        this.productsList[0].prices.taxCollected = this.priceEdited.prices.taxCollected;
      }
    }
  }

  /**
   * Gets show table label
   */
  get showTableLabel() {
    return this.transfer.pricing_enabled
      ? []
      : ["total", "sub_total", "tax", "price_per_unit"];
  }

  /**
   * Gets whether is cannabis product
   */
  public get isCannabisProduct() {
    if (this.transfer.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER") {
      return false;
    } else {
      return (
        this.editingBatch.product &&
        !this.editingBatch.product.requires_weighing &&
        this.editingBatch.product.marijuana
      );
    }
  }

  /**
   * Checks if current product type is present on the limits config
   * if so, then the package_weight is required
   */
  public get requirePackageWeight(): boolean {
    return !!(
      this.editingBatch &&
      this.editingBatch.product &&
      this.editingBatch.product.batch_type &&
      this.packageWeightTypes &&
      this.packageWeightTypes.includes(this.editingBatch.product.batch_type_id!)
    );
  }
  public enteredNextStep: boolean = false;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public weighableUnits: Array<{ label: string; value: PRODUCT_UNIT }> = [
    { label: "Grams", value: PRODUCT_UNIT.GRAMS },
    { label: "Milligrams", value: PRODUCT_UNIT.MG },
    { label: "Ounces", value: PRODUCT_UNIT.OZ }
  ];
  public modelKeys = batchDetailsModelKeys;
  public model: StepModel = pick(
    InboundTransferDefault,
    this.modelKeys
  ) as StepModel;
  public selectedBatch: number = -1;
  public editingCache: BatchTransfer.InboundTransferItem | null = null;
  public readonly tableActions: TABLEACTIONS[] = [TABLEACTIONS.EDIT];
  public readonly inboundTableActions: INBOUNDTABLEACTIONS[] = [
    INBOUNDTABLEACTIONS.EDIT
  ];
  public inboundBatchForm = false;
  public disableEdition = false;
  public disableProduct = false;
  public showEdit = false;
  public showDetails = true;
  public newQuantity = 0;
  public productsList2!: Product[];
  public priceEdited!: BatchTransfer.InboundTransferItem;
  public editedProduct = false;
  public loading = false;
  public batchDetails = true;
  public fromAddNew = false;
  public fromAddButton = false;
  public qaCheck!: any;
  public reqLength!: number;
  public currentBatchId!: number | string;
  public nonCana = "Non-Cannabis";
  public pickerOptions: Partial<HelixDatePickerOptions> = {
    "picker-options": {
      disabledDate: (date: Date) => {
        return differenceInDays(new Date(), date) > 0;
      }
    }
  };
  public updateQuantityD = debounce(
    (value: number, context: InboundTransferBatchDetails) => {
      const clone = cloneDeep(context.editingBatch);
      const pricePoint = context.getPricePoint(
        context.pricePointType,
        clone.product!.pricing || [],
        value
      );
      if (pricePoint.break) {
        clone.prices.price_per_unit = parseFloat(
          Number(pricePoint.break.price / pricePoint.break.quantity).toFixed(2)
        );
      }
      clone.rooms![0].quantity = +value;
      context.editingBatch = clone;
    },
    500
  );
  public updateQuantityForTrace = debounce(
    (value: number, context: InboundTransferBatchDetails) => {
      const clone = cloneDeep(context.editingBatch);
      const pricePoint = context.getPricePoint(
        context.pricePointType,
        (clone.product && clone.product!.pricing) || [],
        value
      );
      if (pricePoint.break) {
        clone.prices.price_per_unit = parseFloat(
          Number(pricePoint.break.price / pricePoint.break.quantity).toFixed(2)
        );
      }
      if (context.bioTrackTraceEnabled) {
        clone.quantity = +value;
      } else {
        clone.rooms![0].quantity = +value;
      }
      context.editingBatch = clone;
    },
    500
  );
  @Prop({ default: false }) protected active!: boolean;
  /**
   * Validates and save
   */
  public async validateAndSave() {
    if (
      this.bioTrackTraceEnabled &&
      (this.transfer.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER" ||
        this.transfer.sourceable_transfer_type === "LOCATION_TRANSFER")
    ) {
      if (await this.$validator.validateAll("edit-batch")) {
        if (this.fromAddNew) {
          this.priceEdited.sku = this.productsList[0].sku;
          // @ts-ignore
          this.priceEdited.product = this.productsList[0].product;
          this.priceEdited.product!.batch_type_id = this.productsList[0].batch_type_id;
          this.priceEdited.batch_type_id = this.productsList[0].batch_type_id;
          this.priceEdited.sale_price = this.productsList[0].prices.subTotal;
          this.priceEdited.tax = this.productsList[0].prices.taxCollected;
          this.priceEdited.addedNew = true;
          this.priceEdited.barcode_id = this.productsList[0].barcode_id || "";
          this.priceEdited.id = this.productsList[0].id;
          this.transfer.data!.data.push(this.priceEdited);
          this.fromAddNew = false;
          this.fromAddButton = true;
          this.productsList = [];
          this.productsFromInventory = [];
          this.validateListAndEmitChanges();
        } else {
          this.editedProduct = true;
          this.transfer.data!.data!.map((item: any, index: number) => {
            if (index === this.selectedBatch) {
              // @ts-ignore --> Diference between API and front end
              this.transfer.data!.data![
                this.selectedBatch
              ] = this.productsList[0];
            }
          });
          // @ts-ignore --> Diference between API and front end
          this.transfer.data!.data! = this.transfer.data!.data!.map(
            (item: {
              name?: string | null;
              product?: any;
              barcode_id?: string;
              id?: number | null;
              currentProduct?: any;
            }) =>
              (item = {
                ...item
              })
          );
          this.transfer.data!.data!.map(
            (
              item: {
                id?: number | null;
                barcode_id?: string;
                sale_price?: string | number;
                priceUpdated?: boolean;
                editedProduct?: boolean;
                prices?: any;
                sku?: string | null;
              },
              index: number
            ) => {
              if (index === this.selectedBatch) {
                if (item.sale_price !== this.priceEdited.prices.subTotal) {
                  item.priceUpdated = true;
                }
                item.editedProduct = true;
                item.prices = this.priceEdited.prices;
                item.sale_price = this.priceEdited.prices.subTotal;
              }
            }
          );
          this.editingCache = this.transfer.data!.data[this.selectedBatch];
          this.productsList = [];
          this.productsFromInventory = [];
          this.model.items = cloneDeep(this.transfer.data!.data);
          this.validateListAndEmitChanges();
        }
        this.currentBatchId = "";
      }
    } else {
      if (await this.$validator.validateAll("edit-batch")) {
        this.validateListAndEmitChanges();
      }
    }
  }
  /**
   * Adds new batch
   */
  public addNewBatch() {
    if (
      this.bioTrackTraceEnabled &&
      this.transfer.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER"
    ) {
      this.currentBatchId = this.nonCana;
      this.showEdit = true;
      this.fromAddNew = true;
      this.model.items = this.transfer.data!.data;
      // @ts-ignore
      this.productsList[0] = cloneDeep(InboundCannabisVendorTransfer);
      if (this.defaultRoom) {
        this.productsList[0]!.rooms[0].room_id = this.defaultRoom.id!;
      }
      // @ts-ignore
      this.productsList[0].id = this.nonCana;
      // @ts-ignore
      this.selectedBatch = this.transfer.data.data.length;
      this.loadNonCannabisProducts();
      this.productsFromInventory = this.fromInventory;
    } else {
      const item = cloneDeep(InboundTransferItemDefault);
      this.disableProduct = false;
      item.id = Date.now();
      if (this.defaultRoom) {
        item.rooms![0].room_id = this.defaultRoom.id!;
      }
      this.model.items!.push(item);
      this.selectedBatch = this.model.items!.length - 1;
      if (
        this.transfer.sourceable_transfer_type === "NON_CANNABIS_VENDOR_TRANFER"
      ) {
        this.showEdit = false;
        this.loadNonCannabisProducts();
        this.productsList = this.fromInventory;
      } else {
        this.loadProducts();
      }
    }
  }

  /**
   * Edits batch handler from BatchList
   * @param {number} id
   */
  public editBatch(id: number) {
    this.selectedBatch = this.model.items!.findIndex(batch => batch.id === id);
    const item = this.model.items![this.selectedBatch];
    this.editingCache = cloneDeep(this.model.items![this.selectedBatch]);
    this.productsList = [item.product!];
    if (item.tax_category) {
      this.taxCategoriesList = [item.tax_category];
    }
    this.disableProduct = true;
  }

  public async editBatchForTrace(
    id: number,
    sku: string,
    atLocation: boolean,
    name: string,
    reqIndex: number
  ) {
    this.atLocationInboubTransfer = atLocation;
    this.currentBatchId = id ? id : this.nonCana;
    if (
      this.transfer.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER" &&
      this.bioTrackTraceEnabled
    ) {
      this.fromAddNew = false;
      this.showEdit = true;
      this.selectedBatch = this.transfer.data!.data!.findIndex(
        (
          batch: {
            sku?: string | null;
            currentProduct?: string | null;
            name?: string | null;
            id?: number | string | null;
            barcode_id?: string | number;
          },
          index: any
        ) => batch.sku === sku
      );
    } else {
      this.showEdit = true;
      this.selectedBatch = this.transfer.data!.data!.findIndex(
        (batch: {
          name?: string | null;
          id?: number | string | null;
          barcode_id?: string | number;
          addedNew?: boolean;
        }) => batch.id === id || batch.barcode_id === id
      );
    }
    const selectedProductName: any = [
      this.transfer.data!.data![this.selectedBatch]
    ];
    selectedProductName[0].sku = sku;
    if (atLocation) {
      selectedProductName[0].name = selectedProductName[0].currentProduct;
    } else if (selectedProductName[0].name === "") {
      selectedProductName[0].notatLocation = true;
    }
    this.productsList2 = selectedProductName;
    this.productsList = cloneDeep(this.productsList2);
    // @ts-ignore
    this.priceEdited = this.productsList[0];
    this.model.items = this.transfer.data!.data;
    this.editingCache = cloneDeep(this.model.items![this.selectedBatch]);
    if (this.productsList[0].product!.marijuana) {
      await this.productsName(this.productsList[0]!.inventorytype);
    } else {
      this.productsFromInventory = await batchTransferManagerService.getMarijuanaProduct(
        this.productsList[0].name
      );
    }
    atLocation = false;
  }

  /**
   * Cancels edition
   */
  public cancelEdition() {
    this.editingCache = null;
    this.selectedBatch = -1;
    this.model.items = this.model.items!.filter(item => item.sku);
    if (this.bioTrackTraceEnabled) {
      this.currentBatchId = "";
      this.showEdit = false;
    } else if (this.transfer.source_type === "NON_CANNABIS_VENDOR_TRANSFER") {
      this.showEdit = false;
    }
  }

  /**
   * Undos inbound transfer batch details
   */
  public undo() {
    if (this.bioTrackTraceEnabled) {
      this.productsList[0].quantity = this.editingCache!.quantity;
      this.productsList[0].prices = this.editingCache!.prices;
      this.editingBatch.quantity = this.editingCache!.quantity;
      this.editingBatch.prices = this.editingCache!.prices;
      this.productsList[0].rooms = this.editingCache!.rooms;
      this.productsList[0].name = String(this.editingCache!.name);
      this.productsList[0].currentProduct = String(this.editingCache!.name);
      this.productsList[0].sku = String(this.editingCache!.sku);
      this.productsList[0].product!.sku = String(this.editingCache!.sku);
      this.productsList[0].addedNew = this.editingCache!.addedNew;
      this.productsList[0].product!.marijuana = this.editingCache!.product!.marijuana;
      this.productsList[0].atLocation = this.editingCache!.atLocation;
    } else {
      this.editingBatch = this.editingCache
        ? cloneDeep(this.editingCache)
        : InboundTransferItemDefault;
    }
  }

  /**
   * Changes product. Handler for product form field
   */
  public async changeProduct() {
    if (this.transfer.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER) {
      this.editingBatch = (await this.setupBatchProduct(
        this.editingBatch
      )) as BatchTransfer.InboundTransferItem;
    } else {
      this.editingBatch = (await this.resetProduct(
        this.editingBatch
      )) as BatchTransfer.InboundTransferItem;
    }
  }

  /**
   * Sets tax. Handler for tax category field
   */
  public async setTax() {
    // @ts-ignore --> Diference between API and front end
    this.editingBatch = (await this.setupBatchTaxCategories(
      this.editingBatch
    )) as BatchTransfer.NewBatchItem;
    this.editingBatch = this.processTaxCategory(
      this.editingBatch,
      this.editingBatch.tax_category!
    ) as BatchTransfer.InboundTransferItem;
  }
  /**
   * Updates quantity. Handler for quantity form field
   * @param {number} quantity
   */
  public updateQuantity(quantity: number) {
    if (this.bioTrackTraceEnabled) {
      this.updateQuantityForTrace(quantity, this);
    } else {
      this.updateQuantityD(quantity, this);
    }
  }

  /**
   * Updates expiration_date and emits transfer changes
   * @param {string} date
   */
  public updateDate(date: string) {
    const batch = cloneDeep(this.editingBatch);
    batch.expiration_date = date;
    this.editingBatch = batch;
  }

  public async updateEditingBatch(avoid: boolean) {
    this.editingBatch = (await this.resetProduct(
      this.editingBatch,
      avoid
    )) as BatchTransfer.InboundTransferItem;
  }
  /**
   * Handler for manual input of tax value
   * @param {number} collected
   */
  public nullTaxCategory(collected: number) {
    const clone = this.voidTaxCategory(this.editingBatch);
    clone.prices.taxCollected = collected;
    this.editingBatch = clone as BatchTransfer.InboundTransferItem;
  }

  /**
   * Sets results
   * @param {BatchTransfer.LabResults} results
   */
  public setResults(results: BatchTransfer.LabResults) {
    if (
      this.transfer.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER" &&
      this.bioTrackTraceEnabled
    ) {
      this.productsList[0].lab_results = results;
    }
    const batch = cloneDeep(this.editingBatch);
    batch.lab_results = results;
    this.editingBatch = batch;
  }

  /**
   * Prints barcode
   */
  public printBarcode() {
    EventBus.$emit("print", {
      component: BarcodeListComponent,
      props: {
        batches: [this.editingBatch.batch]
      }
    });
  }

  /**
   * Prints label
   */
  public printLabel() {
    EventBus.$emit("print", {
      component: InventoryLabelListComponent,
      props: {
        batches: [this.editingBatch.batch],
        labelType: "INVENTORY"
      }
    });
  }

  public selectedName(
    selectedName: string,
    selectedIndex: any,
    currentProduct: Batch
  ) {
    const barcode = currentProduct.barcode_id;
    const selectedProduct = this.productsFromInventory.filter(
      (item: { name: string }) => {
        if (item.name === selectedName) {
          return item;
        }
      }
    );
    if (this.fromAddNew) {
      this.productsList[0].name = selectedProduct[0].name;
      this.productsList[0].sku = selectedProduct[0].sku;
      this.productsList[0].product!.sku = selectedProduct[0].sku;
      this.productsList[0].strain = selectedProduct[0].strain;
      this.productsList[0].batch_type_id = selectedProduct[0]!.batch_type_id;
      this.productsList[0].unit = selectedProduct[0].unit;
      this.productsList[0].product!.unit = selectedProduct[0].unit;
      this.productsList[0].inventorytype =
        selectedProduct[0].state_reporting_type.inventory_type_id;
      this.productsList[0].addedNew = true;
    } else {
      this.productsList[0].currentProduct = selectedName;
      this.productsList[0].name = selectedName;
      this.productsList[0].sku = selectedProduct[0].sku;
      // @ts-ignore --> Diference between API and front end
      this.productsList[0].strain =
        selectedProduct[0].strain !== null
          ? selectedProduct[0].strain!.name
          : "";
      this.productsList[0].product!.batch_type_id =
        selectedProduct[0].batch_type_id;
      this.productsList[0].product!.unit = selectedProduct[0].unit;
      this.productsList[0].unit = selectedProduct[0].unit;
      this.productsList[0].product!.sku = selectedProduct[0].sku;
      this.productsList[0].product!.strain!.name =
        selectedProduct[0].strain !== null
          ? selectedProduct[0].strain!.name
          : "";
      this.productsList[0].inventorytype = selectedProduct[0].inventorytype;
    }
  }

  public async productsName(data: any) {
    this.loading = true;
    const stateProd = await InboundTransferService.getSelectedStateProductData(
      data
    );
    this.productsFromInventory = stateProd.data;
    this.loading = false;
  }
  public prevStep() {
    this.enteredNextStep = true;
    EventBus.$emit("enteredNextStep", this.enteredNextStep);
    this.$emit("prev");
  }
  public productexisting(data: any) {
    this.model.items = cloneDeep(data);
    this.transfer.items = cloneDeep(data);
  }

  protected mounted() {
    EventBus.$on("initiallength", (data: number) => {
      this.reqLength = data;
    });

    EventBus.$on("fromInventory", (data: any) => {
      this.fromInventory = data;
    });
    this.pricePointType = PRICE_POINTS_TYPE.WHOLESALE_INBOUND;
    this.loadBatchLevels();
    this.loadProducts();
    this.loadTaxCategories();
    this.setPackageWeightLimit();
    this.loadRooms().then(() => {
      this.defaultRoom = this.roomList.find(
        room => room.room_type === "BACKSTOCK_ROOM"
      );
    });
    this.$watch("active", value => {
      if (value) {
        this.cloneModel();
        this.assignProductsItems()
          .finally(this.assignTaxCategory)
          .finally(this.assignRoom);
        this.selectedBatch = -1;
        this.disableEdition =
          !this.editingTransfer &&
          this.transfer.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER;
        this.pickerOptions.disabled = this.disableEdition;
      }
    });
    if (this.bioTrackTraceEnabled) {
      EventBus.$on("labresultsdata", (data: any) => {
        this.qaCheck = data;
      });
      EventBus.$on(
        "addedProductName",
        (addedProduct: any, currentIndex: number) => {
          const addedData = addedProduct.filter((item: any, index: number) => {
            if (currentIndex === item.id) {
              return item;
            }
          });
          this.transfer.data!.data = this.transfer.data!.data.map(
            (item: any, index: number) => {
              if (currentIndex === (item.id || item.barcode_id)) {
                item = addedData[0];
                return item;
              }
              return item;
            }
          );
          this.model.items = this.transfer.data!.data;
        }
      );
    }
  }

  protected assignRoom() {
    if (this.model.items!.length) {
      const newItems = this.model.items!.map(item => {
        if (!item.rooms) {
          item.rooms = [
            {
              room_id: (this.defaultRoom && this.defaultRoom.id!) || -1,
              quantity: item.quantity
            }
          ];
        }
        return item;
      });
      this.model = { ...this.model, items: newItems };
      this.transferChanges();
    }
  }

  /**
   * Assigns products items
   * @returns {void}
   */
  protected async assignProductsItems() {
    if (this.transfer.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER) {
      return;
    }
    await this.assignProducts();
  }

  /**
   * Validates step
   * @returns {void}
   */
  protected validateStep() {
    if (
      this.bioTrackTraceEnabled &&
      this.transfer.sourceable_transfer_type === "CANNABIS_VENDOR_TRANFER"
    ) {
      this.transfer.data!.data.map((item: any, index: number) => {
        if (index < this.qaCheck.length) {
          if (item.editedProduct) {
            if (this.qaCheck[index].lab_license) {
              this.qaCheck[index].qa_check.test.type_2 =
                item.lab_results.results;
            }
          }
          if (this.qaCheck[index].lab_license) {
            const newResults: any = [];
            const arrayOfTest = Object.values(
              this.qaCheck[index].qa_check.test
            );
            arrayOfTest.forEach((data: any) => {
              data.forEach((element: any) => {
                newResults.push(element);
              });
            });
            item.lab_results.results = newResults;
            item.lab_results.laboratory_id = this.qaCheck[index].lab_license;
            item.lab_results.testing_date = this.qaCheck[
              index
            ].qa_check.result_date;
          }
        }
      });
      // // @ts-ignore
      // this.transfer.items = this.transfer.data!.data;
      this.transferChanges();
    } else if (
      this.bioTrackTraceEnabled &&
      this.transfer.sourceable_transfer_type === "LOCATION_TRANFER"
    ) {
      this.transferChanges();
    }
    if (this.model.items!.find(item => !item.sku)) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.missing_form_data")
        ),
        "error"
      );
      return;
    }
    if (!this.transfer.items!.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("batch_transfer_manager_module.messages.no_items")),
        "warning"
      );
      return;
    }
    this.nextStep();
  }

  /**
   * Validates list and emit changes
   */
  protected validateListAndEmitChanges() {
    this.selectedBatch = -1;
    if (this.bioTrackTraceEnabled) {
      this.showEdit = false;
    }
    this.transferChanges();
  }
}
