import OutboundTransferBatchDetails from "@/components/inventory/BatchTransfer/BatchTransferManager/OutboundTransfer/OutboundTransferBatchDetails/OutboundTransferBatchDetails.component";
import OutboundTransferDetails from "@/components/inventory/BatchTransfer/BatchTransferManager/OutboundTransfer/OutboundTransferDetails/OutboundTransferDetails.component";
import OutboundTransferPaymentMethods from "@/components/inventory/BatchTransfer/BatchTransferManager/OutboundTransfer/OutboundTransferPaymentMethods/OutboundTransferPaymentMethods.component";
import QuoteBatchDetails from "@/components/inventory/BatchTransfer/BatchTransferManager/Quote/QuoteBatchDetails/QuoteBatchDetails.component";
import QuoteReviewPrint from "@/components/inventory/BatchTransfer/BatchTransferManager/Quote/QuoteReviewPrint/QuoteReviewPrint.component";
import QuoteTransferDetails from "@/components/inventory/BatchTransfer/BatchTransferManager/Quote/QuoteTransferDetails/QuoteTransferDetails.component";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES,
  TRANSFER_SOURCE,
  TRANSFER_STATUS,
  TRANSFER_TYPES,
  TRANSFERABLE_TYPES
} from "@/interfaces/batchTransferManager";
import InboundTransferBatchDetails from "./BatchTransferManager/InboundTransfer/InboundTransferBatchDetails/InboundTransferBatchDetails.component";
import InboundTransferCompleteTransfer from "./BatchTransferManager/InboundTransfer/InboundTransferCompleteTransfer/InboundTransferCompleteTransfer.component";
import InboundTransferPaymentMethods from "./BatchTransferManager/InboundTransfer/InboundTransferPaymentMethods/InboundTransferPaymentMethods.component";
import InboundTransferPrintLabels from "./BatchTransferManager/InboundTransfer/InboundTransferPrintLabels/InboundTransferPrintLabels.component";
import InboundTransferTransferDetails from "./BatchTransferManager/InboundTransfer/InboundTransferTransferDetails/InboundTransferTransferDetails.component";
import NewBatchBatchDetails from "./BatchTransferManager/NewBatch/NewBatchBatchDetails/NewBatchBatchDetails.component";
import TraceNewBatchBatchDetails from "./BatchTransferManager/NewBatch/NewBatchBatchDetails/TraceNewBatchBatchDetails.component";
import NewBatchPaymentMethods from "./BatchTransferManager/NewBatch/NewBatchPaymentMethods/NewBatchPaymentMethods.component";
import NewBatchPrint from "./BatchTransferManager/NewBatch/NewBatchPrint/NewBatchPrint.component";
import NewBatchReview from "./BatchTransferManager/NewBatch/NewBatchReview/NewBatchReview.component";
import NewBatchTransferDetails from "./BatchTransferManager/NewBatch/NewBatchTransferDetails/NewBatchTransferDetails.component";
import PurchaseOrderBatchDetail from "./BatchTransferManager/PurchaseOrder/PurchaseOrderBatchDetail/PurchaseOrderBatchDetail.component";
import PurchaseOrderReviewPrint from "./BatchTransferManager/PurchaseOrder/PurchaseOrderReviewPrint/PurchaseOrderReviewPrint.component";
import PurchaseOrderTransferDetail from "./BatchTransferManager/PurchaseOrder/PurchaseOrderTransferDetail/PurchaseOrderTransferDetail.component";
export const stepsComponents = {
  PurchaseOrderTransferDetail,
  PurchaseOrderBatchDetail,
  PurchaseOrderReviewPrint,
  InboundTransferTransferDetails,
  InboundTransferBatchDetails,
  InboundTransferPaymentMethods,
  InboundTransferCompleteTransfer,
  InboundTransferPrintLabels,
  OutboundTransferBatchDetails,
  OutboundTransferPaymentMethods,
  OutboundTransferDetails,
  NewBatchTransferDetails,
  NewBatchBatchDetails,
  NewBatchPaymentMethods,
  NewBatchReview,
  NewBatchPrint,
  QuoteBatchDetails,
  QuoteReviewPrint,
  QuoteTransferDetails,
  TraceNewBatchBatchDetails
};

export const BatchTransferActions: BatchTransfer.Actions = {
  "purchase-order": {
    title: "purchase_order",
    steps: [
      {
        label: "transfer_detail",
        component: "PurchaseOrderTransferDetail"
      },
      {
        label: "batch_detail",
        component: "PurchaseOrderBatchDetail"
      },
      {
        label: "review_detail",
        component: "PurchaseOrderReviewPrint"
      }
    ]
  },
  "inbound-transfer": {
    title: "inbound_transfer",
    steps: [
      {
        label: "transfer_detail",
        component: "InboundTransferTransferDetails"
      },
      {
        label: "batch_detail",
        component: "InboundTransferBatchDetails"
      },
      {
        label: "payment_methods",
        component: "InboundTransferPaymentMethods"
      },
      {
        label: "complete_transfer",
        component: "InboundTransferCompleteTransfer"
      },
      {
        label: "print_lablels",
        component: "InboundTransferPrintLabels"
      }
    ]
  },
  "outbound-transfer": {
    title: "outbound_transfer",
    steps: [
      {
        label: "transfer_detail",
        component: "OutboundTransferDetails"
      },
      {
        label: "batch_detail",
        component: "OutboundTransferBatchDetails"
      },
      {
        label: "payment_methods",
        component: "OutboundTransferPaymentMethods"
      }
    ]
  },
  "new-batch": {
    title: "new_batch",
    steps: [
      {
        label: "transfer_detail",
        component: "NewBatchTransferDetails"
      },
      {
        label: "batch_detail",
        component: "NewBatchBatchDetails"
      },
      {
        label: "payment_methods",
        component: "NewBatchPaymentMethods"
      },
      {
        label: "review",
        component: "NewBatchReview"
      },
      {
        label: "print_lablels",
        component: "NewBatchPrint"
      }
    ]
  },
  quote: {
    title: "quote",
    steps: [
      {
        label: "transfer_detail",
        component: "QuoteTransferDetails"
      },
      {
        label: "batch_detail",
        component: "QuoteBatchDetails"
      },
      {
        label: "review_detail",
        component: "QuoteReviewPrint"
      }
    ]
  }
};

// DEFAULTS
export const TransferDefault: BatchTransfer.Transfer = {
  transfer_type: TRANSFER_TYPES.TRANSFER,
  items: [],
  updated_at: "",
  notes: [{ name: "", title: "", note: "" }],
  status: TRANSFER_STATUS.DRAFT,
  prices: {
    subTotal: 0,
    total: 0,
    discount: {
      description: "",
      amount: 0,
      type: DISCOUNT_FEE_TYPES.PERCENTAGE,
      value: 0,
      postTaxDiscount: true
    },
    fee: {
      description: "",
      amount: 0,
      type: DISCOUNT_FEE_TYPES.PERCENTAGE,
      value: 0,
      postTaxFee: true
    },
    taxCollected: 0
  },

  payment_methods_breakdown: {
    initial: 0,
    payment_and_methods: [],
    outstanding: 0
  },
  pricing_enabled: true,
  source_id: -1,
  data: {
    data: []
  },
  currentProduct: null
};
export const InboundTransferDefault: BatchTransfer.InboundTransfer = {
  ...TransferDefault,
  ...{
    items: [],
    user_id: -1,
    transfer_type: TRANSFER_TYPES.INBOUND,
    transferable_type: TRANSFERABLE_TYPES.PURCHASE_ORDER,
    transferable_id: -1,
    source_id: -1,
    is_payable: true,
    source_type: TRANSFER_SOURCE.LOCATION_TRANSFER,
    sourceable_id: 1,
    sourceable_transfer_type: "",
    transfer_id: "",
    updated_at: "",
    data: {
      data: []
    },
    payment_methods_breakdown: {
      initial: 0,
      payment_and_methods: [],
      outstanding: 0
    }
  }
};
export const OutboundTransferDefault: BatchTransfer.OutboundTransfer = {
  ...TransferDefault,
  ...{
    items: [],
    user_id: -1,
    transfer_type: TRANSFER_TYPES.OUTBOUND,
    transferable_type: TRANSFERABLE_TYPES.PURCHASE_ORDER,
    transferable_id: -1,
    destination_type: TRANSFER_SOURCE.LOCATION_TRANSFER,
    destination_id: -1,
    is_payable: true,
    transfer_id: "",
    updated_at: "",
    manifest: {
      barcode: "",
      location_id: 0,
      vehicle_id: 0,
      driver_id: 0,
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      drop_off_time: ""
    },
    payment_methods_breakdown: {
      initial: 0,
      payment_and_methods: [],
      outstanding: 0
    }
  }
};
export const PurchaseOrderDefault: BatchTransfer.PurchaseOrder = {
  ...TransferDefault,
  ...{
    items: [],
    transfer_type: TRANSFER_TYPES.PURCHASE_ORDER,
    source_id: -1,
    source_type: TRANSFER_SOURCE.LOCATION_TRANSFER,
    purchase_order_number: "",
    updated_at: "",
    request_on_or_before: ""
  }
};
export const QuoteDefault: BatchTransfer.Quote = {
  ...TransferDefault,
  ...{
    user_id: -1,
    items: [],
    destination_type: TRANSFER_SOURCE.LOCATION_TRANSFER,
    destination_id: -1,
    transfer_type: TRANSFER_TYPES.QUOTE,
    quote_number: "",
    updated_at: "",
    request_on_or_before: ""
  }
};

export const NewBatchDefault: BatchTransfer.NewBatch = {
  ...TransferDefault,
  ...{
    payment_methods_breakdown: {
      initial: 0,
      payment_and_methods: [],
      outstanding: 0
    },
    user_id: -1,
    items: [],
    is_payable: true,
    transfer_type: TRANSFER_TYPES.NEW_BATCH,
    source_type: TRANSFER_SOURCE.LOCATION_TRANSFER,
    source_id: -1,
    custom_transfer_id: "",
    transfer_id: "",
    updated_at: ""
  }
};
export const BatchDefault: BatchTransfer.Batch = {
  sku: null,
  tax_category_id: -1,
  tax_count: {
    pre_excise: 0,
    excise: 0,
    post_excise: 0,
    per_transaction: 0
  },
  quantity: 1,
  prices: {
    price_per_unit: 0,
    cost_per_unit: 0,
    subTotal: 0,
    total: 0,
    discount: {
      description: "",
      amount: 0,
      type: DISCOUNT_FEE_TYPES.PERCENTAGE,
      value: 0,
      postTaxDiscount: false
    },
    fee: {
      description: "",
      amount: 0,
      type: DISCOUNT_FEE_TYPES.PERCENTAGE,
      value: 0,
      postTaxFee: false
    },
    taxCollected: 0
  },
  currentProductName: "",
  currentProduct: "",
  is_return: 0,
  unit: null,
  strain: null,
  name: "",
  barcode_id: "",
  inventorytype: "",
  sale_price: ""
};
export const InboundTransferItemDefault: BatchTransfer.InboundTransferItem = {
  ...BatchDefault,
  rooms: [
    {
      room_id: -1,
      quantity: 1
    }
  ],
  ...{
    expiration_date: "",
    lab_results: {
      laboratory_id: null,
      testing_date: "",
      results: []
    }
  }
};

export const InboundCannabisVendorTransfer = {
  name: "",
  id: "",
  sku: "",
  unit: "",
  strain: "",
  quantity: 1,
  tax: 0,
  fromApi: false,
  priceUpdated: false,
  editedProduct: false,
  is_return: 1 || 0,
  tax_category_id: -1,
  rooms: [
    {
      name: "",
      room_id: 34,
      quantity: 1
    }
  ],
  prices: {
    cost_per_unit: 0,
    price_per_unit: 0,
    subTotal: 0,
    total: 0,
    discount: {
      amount: 0,
      description: "",
      postTaxDiscount: false,
      type: DISCOUNT_FEE_TYPES.PERCENTAGE,
      value: 0
    },
    fee: {
      amount: 0,
      description: "",
      postTaxFee: false,
      type: DISCOUNT_FEE_TYPES.PERCENTAGE,
      value: 0
    },
    taxCollected: 0
  },
  tax_count: {
    pre_excise: 0,
    excise: 0,
    post_excise: 0,
    per_transaction: 0
  },
  product: {
    marijuana: 0,
    batch_type_id: "",
    unit: "",
    sku: "",
    strain: {
      name: ""
    }
  },
  lab_results: {
    laboratory_id: 0,
    testing_date: "",
    results: []
  }
};
export const OutboundTransferItemDefault: BatchTransfer.OutboundTransferItem = {
  ...BatchDefault,
  ...{
    batch_uid: "",
    expiration_date: "",
    lab_results: {
      laboratory_id: null,
      testing_date: "",
      results: []
    }
  }
};

export const PurchaseOrderItemDefault: BatchTransfer.PurchaseOrderItem = {
  ...BatchDefault
};

export const QuoteItemDefault: BatchTransfer.QuoteItem = {
  ...BatchDefault,
  ...{ batch_uid: "", sku: null }
};

export const NewBatchItemDefault: BatchTransfer.NewBatchItem = {
  ...BatchDefault,
  rooms: [
    {
      room_id: -1,
      quantity: 1
    }
  ],
  ...{
    expiration_date: "",
    batch_level_pricing: -1,
    lab_results: {
      laboratory_id: null,
      testing_date: "",
      results: []
    }
  }
};
