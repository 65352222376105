import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PricingBreakdownSummary.template.vue";

@Component({
  mixins: [Template]
})
export default class PricingBreakdownSummary extends Vue {
  @Prop({ default: true }) public showPurchaseOrderPrice!: boolean;
  @Prop({ required: true }) public quantity!: number;
  @Prop({ required: true })
  public pricingBreakdownData!: BatchTransfer.BatchPrices;

  /**
   * Gets pricing breakdown data formated
   */
  get pricingBreakdownDataFormated() {
    let pricesdetails: Array<{ name: string; value?: number }> = [
      {
        name: "price_per_unit",
        value: this.pricingBreakdownData.price_per_unit
      },
      { name: "quantity", value: this.quantity },
      { name: "subtotal", value: this.pricingBreakdownData.subTotal },
      { name: "discount", value: this.pricingBreakdownData.discount!.value },
      { name: "fee", value: this.pricingBreakdownData.fee!.value },
      { name: "tax", value: this.pricingBreakdownData.taxCollected },
      { name: "total", value: this.pricingBreakdownData.total }
    ].filter(item => item.value);

    if (this.pricingBreakdownData.discount!.postTaxDiscount) {
      const discountPosition: number = pricesdetails.findIndex(
        item => item.name === "discount"
      );
      pricesdetails.splice(discountPosition, 1);
      pricesdetails.splice(pricesdetails.length - 1, 0, {
        name: "discount",
        value: this.pricingBreakdownData.discount!.value
      });
    }
    if (this.pricingBreakdownData.fee!.postTaxFee) {
      const feePosition: number = pricesdetails.findIndex(
        item => item.name === "fee"
      );
      pricesdetails.splice(feePosition, 1);
      pricesdetails.splice(pricesdetails.length - 1, 0, {
        name: "fee",
        value: this.pricingBreakdownData.fee!.value
      });
    }

    if (!this.showPurchaseOrderPrice) {
      pricesdetails = pricesdetails.filter(item =>
        ["quantity", "cost_per_unit"].includes(item.name)
      );
    }
    return pricesdetails;
  }

  /**
   * Sets color
   * @param {string} label
   * @returns {string} A hex depending on the label
   */
  public setColor(label: string) {
    return label === "discount"
      ? "#417505"
      : label === "fee"
      ? "#d0021b"
      : "#0091ff";
  }
}
