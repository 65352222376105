import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchDetailsContainerComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsContainer/BatchDetailsContainer.component";
import BatchDetailsContainerSectionComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsSection/BatchDetailsSection.component";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import PricingBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PricingBreakdownSummary/PricingBreakdownSummary.component";
import {
  PurchaseOrderDefault,
  PurchaseOrderItemDefault
} from "@/components/inventory/BatchTransfer/declarations";
import {
  BatchTransfer,
  PRICE_POINTS_TYPE,
  PRODUCT_UNIT
} from "@/interfaces/batchTransferManager";
import { messagesService } from "@/services/messages.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Mixins } from "vue-property-decorator";
import PricingBreakdownForm from "../../../BuildBlocks/PricingBreakdownForm/PricingBreakdownForm.component";
import ComputeTransfer from "../../ComputeTransfer.mixin";
import { batchDetailsModelKeys } from "../../ModelKeys";
import TransferValidators from "../../TransferValidators.mixin";
import Template from "./PurchaseOrderBatchDetail.template.vue";

interface StepModel {
  items: BatchTransfer.PurchaseOrderItem[];
  notes: BatchTransfer.TransferNote[];
  prices: BatchTransfer.TransferPrices;
}
@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "pricing-breakdown-form": PricingBreakdownForm,
    "batch-details-container": BatchDetailsContainerComponent,
    "pricing-breakdown-summary": PricingBreakdownSummary,
    "batch-detail-section": BatchDetailsContainerSectionComponent,
    "payment-breakdown-summary": PaymentBreakdownSummary
  }
})
export default class PurchaseOrderBatchDetail extends Mixins(
  TransferStep,
  ComputeTransfer,
  StepsData,
  TransferValidators
) {
  public weighableUnits: Array<{ label: string; value: PRODUCT_UNIT }> = [
    { label: "Grams", value: PRODUCT_UNIT.GRAMS },
    { label: "Milligrams", value: PRODUCT_UNIT.MG },
    { label: "Ounces", value: PRODUCT_UNIT.OZ }
  ];
  public modelKeys = batchDetailsModelKeys;
  public model: StepModel = pick(
    PurchaseOrderDefault,
    this.modelKeys
  ) as StepModel;

  public selectedBatch: number = -1;
  public updateQuantityD = debounce(
    async (context: PurchaseOrderBatchDetail) => {
      if (
        context.editingBatch.product &&
        context.editingBatch.product.pricing
      ) {
        const clone = cloneDeep(context.editingBatch);
        context.editingBatch = (await context.resetProduct(
          clone,
          true
        )) as BatchTransfer.PurchaseOrderItem;
      }
    },
    500
  );

  /**
   * Validates form. Emits changes if validation is ok.
   */
  public async validateAndSave() {
    if (await this.$validator.validateAll("edit-batch")) {
      this.validateListAndEmitChanges();
    }
  }
  /**
   * Adds new batch with default model for edition.
   */
  public addNewBatch() {
    const item = cloneDeep(PurchaseOrderItemDefault);
    item.id = Date.now();
    this.model.items.push(item);
    this.selectedBatch = this.model.items.length - 1;
    this.isNew = true;
  }
  /**
   * Edits batch. Handler for edit event on BatchList component.
   * @param {number} id
   */
  public editBatch(id: number) {
    this.selectedBatch = this.model.items.findIndex(batch => batch.id === id);
    const item = this.model.items[this.selectedBatch];
    this.productsList = [item.product!];
    if (item.tax_category) {
      this.taxCategoriesList = [item.tax_category];
    }
    this.isNew = false;
  }

  /**
   * Cancels edition
   */
  public cancelEdition() {
    this.model.items = this.model.items.filter(item => item.sku);
    this.selectedBatch = -1;
  }

  /**
   * Removes batch
   * @param {number} index
   */
  public removeBatch(index: number) {
    this.selectedBatch = -1;
    this.model.items.find(item => item.id === index)!.destroy = true;
    this.validateListAndEmitChanges();
  }

  /**
   * Getter hidden table columns
   */
  public get hiddenTableColumns() {
    return this.transfer.pricing_enabled
      ? []
      : ["total", "sub_total", "tax", "price_per_unit", "cost_per_unit"];
  }

  /**
   * Getter save button label
   */
  public get saveLabel() {
    return this.selectedBatch >= 0 && !this.isNew
      ? "update"
      : "batch_transfer_manager_module.labels.add_batch";
  }

  /**
   * Checks if current product type is present on the limits config
   * if so, then the package_weight is required
   */
  public get requirePackageWeight(): boolean {
    return !!(
      this.editingBatch &&
      this.editingBatch.product &&
      this.editingBatch.product.batch_type &&
      this.packageWeightTypes &&
      this.packageWeightTypes.includes(this.editingBatch.product.batch_type_id!)
    );
  }

  /**
   * Getter editing batch
   */
  public get editingBatch(): BatchTransfer.PurchaseOrderItem {
    if (!this.modelItems.length) {
      this.addNewBatch();
    }
    return this.model.items[
      this.selectedBatch
    ] as BatchTransfer.PurchaseOrderItem;
  }

  /**
   * Setter editing batch
   */
  public set editingBatch(batch: BatchTransfer.PurchaseOrderItem) {
    const items = cloneDeep(this.model.items);
    items[this.selectedBatch] = this.recalculateBatchPrices(
      batch
    ) as BatchTransfer.PurchaseOrderItem;
    this.model.items = items;
  }

  /**
   * Handler for product form field.
   */
  public async changeProduct() {
    this.editingBatch.product =
      (this.editingBatch.sku &&
        this.productsList.find(p => p.sku === this.editingBatch.sku)) ||
      undefined;
    this.editingBatch = (await this.resetProduct(
      this.editingBatch
    )) as BatchTransfer.PurchaseOrderItem;
  }

  /**
   * Handler for Tax category form field.
   */
  public async setTax() {
    if (this.editingBatch.tax_category_id) {
      this.editingBatch = (await this.setupBatchTaxCategories(
        this.editingBatch
      )) as BatchTransfer.PurchaseOrderItem;
      this.editingBatch = this.processTaxCategory(
        this.editingBatch,
        this.editingBatch.tax_category!
      ) as BatchTransfer.PurchaseOrderItem;
    } else {
      this.editingBatch.tax_category_id = -1;
      this.editingBatch.tax_category = undefined;
    }
  }

  /**
   * Handler for user input on tax field at Pricing component.
   * @param {number} collected
   */
  public nullTaxCategory(collected: number) {
    const clone = this.voidTaxCategory(this.editingBatch);
    clone.prices.taxCollected = collected;
    this.editingBatch = clone as BatchTransfer.PurchaseOrderItem;
  }

  /**
   * Handler for quantity form field.
   * @param {number} quantity
   */
  public async updateQuantity() {
    this.updateQuantityD(this);
  }

  /**
   * Validates and moves to next step.
   */
  public validateAndGo() {
    if (this.transferItems.length && !this.editingBatch) {
      this.nextStep();
    }
  }

  /**
   * Updates editing batch
   */
  public async updateEditingBatch(avoid: boolean) {
    this.editingBatch = (await this.resetProduct(
      this.editingBatch,
      avoid
    )) as BatchTransfer.PurchaseOrderItem;
  }

  protected mounted() {
    this.pricePointType = PRICE_POINTS_TYPE.WHOLESALE_INBOUND;
    this.loadBatchLevels();
    this.loadProducts().then(() => this.assignProducts());
    this.loadTaxCategories().then(() => this.assignTaxCategory());
    this.setPackageWeightLimit();
  }

  /**
   * Assigns products. Sets product attribue for each transfer item.
   */
  protected async assignProducts() {
    const newItems = [];
    for (const item of this.model.items) {
      const itemProduct = (await this.setupBatchProduct(
        item
      )) as BatchTransfer.NewBatchItem;
      newItems.push(itemProduct);
    }
    this.model.items = newItems;
    if (this.transfer.items.length) {
      this.validateListAndEmitChanges();
    }
  }
  /**
   * Assigns tax category. Stes tax_category for each transfer item.
   */
  protected async assignTaxCategory() {
    const newItems = [];
    for (const item of this.model.items) {
      const taxedItem = (await this.setupBatchTaxCategories(
        item
      )) as BatchTransfer.PurchaseOrderItem;
      newItems.push(taxedItem);
    }
    this.model.items = newItems;
    if (this.transfer.items.length) {
      this.validateListAndEmitChanges();
    }
  }
  /**
   * Validates step
   * @returns {void}
   */
  protected validateStep() {
    if (this.model.items.find(item => !item.sku)) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.missing_form_data")
        ),
        "error"
      );
      return;
    }
    if (!this.transfer.items.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("batch_transfer_manager_module.messages.no_items")),
        "warning"
      );
      return;
    }
    this.nextStep();
  }

  /**
   * Getter whether is cannabis product
   */
  public get isCannabisProduct() {
    return (
      this.editingBatch.product &&
      !this.editingBatch.product.requires_weighing &&
      this.editingBatch.product.marijuana
    );
  }
  /**
   * Validates items and emit changes
   */
  protected validateListAndEmitChanges() {
    this.selectedBatch = -1;
    this.model.items = this.model.items.filter(item => item.sku);
    this.transferChanges();
  }
}
