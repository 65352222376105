import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchDetailsContainerComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsContainer/BatchDetailsContainer.component";
import BatchDetailsContainerSectionComponent from "@/components/inventory/BatchTransfer/BuildBlocks/BatchDetailsSection/BatchDetailsSection.component";
import TraceBatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/TraceBatchesList/TraceBatchesList.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { EventBus } from "@/event-bus";
import { Batch } from "@/interfaces/batch";
import { BatchTransfer, PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import { batchOperationsService } from "@/services/batchOperations.service";
import NewBatchTransferService from "@/services/BatchTransferManager/NewBatchTransfer.service";
import { messagesService } from "@/services/messages.service";
import differenceInDays from "date-fns/differenceInDays";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import { Component, Mixins } from "vue-property-decorator";
import ComputeTransfer from "../../ComputeTransfer.mixin";
import Template from "./TraceNewBatchBatchDetails.template.vue";

interface Pagination {
  page: number;
  rowsPerPage: number;
  sortBy?: "";
  descending?: boolean;
  totalItems: number;
  pagesReached?: number;
}
interface StepModel {
  items: BatchTransfer.NewBatchItem[];
  notes: BatchTransfer.TransferNote[];
  prices: BatchTransfer.TransferPrices;
}
@Component({
  mixins: [Template],
  components: {
    "batches-list": TraceBatchesList,
    "batch-details-container": BatchDetailsContainerComponent,
    "batch-detail-section": BatchDetailsContainerSectionComponent,
    HelixDatePickerComponent,
    TraceabilityConfirm
  }
})
export default class TraceNewBatchBatchDetails extends Mixins(
  TransferStep,
  ComputeTransfer,
  StepsData
) {
  public weighableUnits: Array<{ label: string; value: PRODUCT_UNIT }> = [
    { label: "Grams", value: PRODUCT_UNIT.GRAMS },
    { label: "Milligrams", value: PRODUCT_UNIT.MG },
    { label: "Ounces", value: PRODUCT_UNIT.OZ }
  ];
  public editingBatchTrace!: Batch;
  public pickerOptions: Partial<HelixDatePickerOptions> = {
    "picker-options": {
      disabledDate: (date: Date) => {
        return differenceInDays(new Date(), date) > 0;
      }
    },
    "value-format": "yyyy-MM-dd"
  };
  public isEdit = false;
  public batchItems!: Batch[];
  public loading = false;
  public selectedRoom!: number | null;
  public pagination: Pagination = { rowsPerPage: 10, totalItems: 0, page: 1 };
  public batchInMultipleRooms: boolean = true;
  public batchInMultipleReservedRooms: boolean = true;
  public loadingRoom = false;
  /**
   * Checks if current product_variant type is present on the limits config
   * if so, then the package_weight is required
   */
  public get requirePackageWeight(): boolean {
    return !!(
      this.editingBatchTrace &&
      this.editingBatchTrace.product_variant &&
      this.editingBatchTrace.product_variant.batch_type &&
      this.packageWeightTypes &&
      this.packageWeightTypes.includes(
        String(this.editingBatchTrace.product_variant.batch_type_id)
      )
    );
  }

  public async validateAndSave() {
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      this.isEdit = false;
      // Here any interface needs to be used since editingBatchTrace has BAtch interface structure and batchOperationsService.createOperation which has BatchAction<CustomBatch> and this can't converted
      const reservation: any = this.editingBatchTrace.summary!.map(
        summaryIndex => {
          return {
            inventory_location_id: summaryIndex.inventory_location.id,
            batch_uid: this.editingBatchTrace.batch_uid,
            quantity: summaryIndex.quantity_value
          };
        }
      );
      const response = await batchOperationsService.createOperation(
        reservation,
        "move",
        true
      );
      if (response) {
        // Here any interface needs to be used since editingBatchTrace has BAtch interface structure and batchOperationsService.createOperation which has BatchAction<CustomBatch> and this can't converted
        const movements: any = this.editingBatchTrace.summary!.map(
          summaryIndex => {
            return {
              from_inventory_location_id: summaryIndex.inventory_location.id,
              batch_uid: this.editingBatchTrace.batch_uid,
              to_inventory_location_id: this.selectedRoom,
              quantity: summaryIndex.quantity_value
            };
          }
        );
        const success = await batchOperationsService.move(
          { movements },
          response.operation_uid,
          "",
          true
        );
        if (success) {
          this.getAllBatches();
        }
      }
      this.selectedRoom = null;
    }
  }

  public editBatch(id: string) {
    this.loadingRoom = true;
    const selectedBatch = this.batchItems.findIndex(
      batch => batch.batch_uid === id
    );

    this.editingBatchTrace = this.batchItems[selectedBatch];
    if (
      !this.editingBatchTrace.is_reserved &&
      !this.editingBatchTrace.is_multiple_room
    ) {
      this.selectedRoom = this.editingBatchTrace.summary![0].inventory_location.id;
    }
    this.isEdit = true;
    this.loadingRoom = false;
  }

  public cancelEdition() {
    if (this.batchItems.length) {
      this.isEdit = false;
      this.selectedRoom = null;
    }
  }

  public getRooms() {
    if (this.editingBatchTrace.by_pass_retail) {
      const salesFloor = this.editingBatchTrace.summary!.findIndex(
        summaryIndex => summaryIndex.inventory_location.name === "SalesFloor"
      );
      return {
        name: this.editingBatchTrace.summary![salesFloor].inventory_location
          .name,
        id: this.editingBatchTrace.summary![salesFloor].inventory_location.id
      };
    }
    return this.editingBatchTrace.summary!.map(summaryIndex => {
      if (summaryIndex.quantity_value) {
        return {
          name: summaryIndex.inventory_location.name,
          id: summaryIndex.inventory_location.id
        };
      }
    });
  }

  protected async onChangePagination(pagination: Pagination) {
    this.pagination = pagination;
    await this.getAllBatches();
    return;
  }

  protected async getAllBatches() {
    this.loading = true;
    const response = await NewBatchTransferService.getAllBatches(
      this.pagination.page,
      this.pagination.rowsPerPage,
      this.pagination.sortBy,
      this.pagination.descending
    );
    if (response) {
      this.batchItems = response.data.data;
      this.pagination.totalItems = response.data.total;
      this.pagination.pagesReached = response.data.to;
      this.batchInMultipleRooms = response.batch_in_multiple_rooms;
      this.batchInMultipleReservedRooms = response.batch_in_reserved;
    }
    this.loading = false;
  }

  protected showErrorMessage() {
    if (
      this.editingBatchTrace.is_reserved &&
      this.editingBatchTrace.is_multiple_room
    ) {
      return this.$t("biotrack_traceability.existing_inventory_reserved_error");
    }
    if (this.editingBatchTrace.is_multiple_room) {
      return this.$t("biotrack_traceability.existing_inventory_missing_error");
    }
    if (this.editingBatchTrace.is_reserved) {
      return this.$t("biotrack_traceability.existing_inventory_reserved_error");
    }
  }

  protected async validateStep() {
    if (!this.batchItems.length) {
      const message = String(i18n.t("please_add_batches"));
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        message,
        "error"
      );
      return;
    }
    if (this.batchInMultipleReservedRooms || this.batchInMultipleRooms) {
      this.$modals.load(
        TraceabilityConfirm,
        {
          size: "normal",
          positionX: "center",
          positionY: "center",
          style: "maxWidth: 810px;"
        },
        {
          modalData: {
            titleAvatar: {
              name: "/img/icon_primary_menu_retail@2x.009e06e8.png",
              size: "70"
            },
            title: {
              name: i18n.t("biotrack_traceability.name"),
              style: "fontSize:25px ; letter-spacing: 0px;"
            },
            message: {
              name:
                "There are multiple batches that are in more than one inventory room or have open batch action reservations. These batches will not be created in BioTrack Traceability if you proceed with the action.",
              style: "fontSize:23px;fontWeight:600"
            },
            description: {
              name:
                "Are you sure you would like to proceed without creating the batches that have these warnings?",
              style: "fontSize:23px;fontWeight:600"
            },
            cancelButton: false,
            acceptButtonValue: "Proceed"
          }
        }
      );
    }

    EventBus.$emit(
      "onChangeNewBatchReviewComponent",
      "TraceNewBatchBatchDetails"
    );
    this.nextStep();
  }
}
