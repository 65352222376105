import { render, staticRenderFns } from "./BatchDetailsContainer.template.vue?vue&type=template&id=295dfb3c&scoped=true&"
var script = {}
import style0 from "./BatchDetailsContainer.template.vue?vue&type=style&index=0&id=295dfb3c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295dfb3c",
  null
  
)

export default component.exports