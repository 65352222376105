import pick from "lodash/pick";
import { Component, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";

import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import { NewBatchDefault } from "@/components/inventory/BatchTransfer/declarations";
import { EventBus } from "@/event-bus";
import {
  BatchTransfer,
  TRANSFER_SOURCE
} from "@/interfaces/batchTransferManager";
import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { i18n } from "@/plugins/i18n";
import NewBatchTransferService from "@/services/BatchTransferManager/NewBatchTransfer.service";
import { newBatchTransferDetailsModelKeys } from "../../ModelKeys";
import TransferStep from "../../TransferStep.mixin";
import TransferValidators from "../../TransferValidators.mixin";
import CustomTraceabilityConfirm from "../NewBatchBatchDetails/CustomTraceabilityConfirm/CustomTraceabilityConfirm.component";
import Template from "./NewBatchTransferDetails.template.vue";

interface StepModel {
  items: BatchTransfer.NewBatchItem[];
  pricing_enabled: boolean;
  source_id: number;
  custom_transfer_id: string;
  source_type: TRANSFER_SOURCE;
  is_payable: boolean;
}

@Component({
  mixins: [Template]
})
export default class NewBatchTransferDetails extends Mixins(
  TransferStep,
  TransferValidators,
  StepsData
) {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  public modelKeys = newBatchTransferDetailsModelKeys;
  public model: StepModel = pick(NewBatchDefault, this.modelKeys) as StepModel;

  public transferTypes: BatchTransfer.SelectOption[] = [];
  public isResourceReset = false;

  /**
   * Gets sources
   */
  public get sources() {
    switch (this.model.source_type) {
      case "LOCATION_TRANSFER":
        return this.locations;
      case "VENDOR_TRANSFER":
        return this.vendors;
      default:
        return [];
    }
  }

  /**
   * Resets source
   */
  public resetSource() {
    this.model.source_id =
      this.model.source_type === "LOCATION_TRANSFER"
        ? Number(this.currentLocation.id)
        : -1;
    if (this.bioTrackTraceEnabled) {
      if (this.remainingDaysForTrace >= 0) {
        if (
          this.currentLocation.state!.name === "Connecticut"
            ? this.remainingDaysForTrace <= 30
            : this.remainingDaysForTrace <= 15
        ) {
          if (this.model.source_type === "LOCATION_TRANSFER") {
            this.transfer.sourceable_transfer_type =
              "INITIAL_INVENTORY_WITHOUT_VENDOR";
          } else if (this.model.source_type === "VENDOR_TRANSFER") {
            this.transfer.sourceable_transfer_type =
              "INITIAL_INVENTORY_WITH_VENDOR";
          } else {
            this.transfer.sourceable_transfer_type =
              "EXISTING_CANNABIS_INVENTORY_IN_TRACEABILITY";
          }
        }
      } else {
        if (this.model.source_type === "LOCATION_TRANSFER") {
          this.transfer.sourceable_transfer_type =
            "NON_CANNABIS_INVENTORY_WITHOUT_VENDOR";
        } else {
          this.transfer.sourceable_transfer_type =
            "NON_CANNABIS_INVENTORY_WITH_VENDOR";
        }
      }
    } else {
      if (this.model.source_type === "LOCATION_TRANSFER") {
        this.transfer.sourceable_transfer_type = "INVENTORY_WITHOUT_VENDOR";
      } else {
        this.transfer.sourceable_transfer_type = "INVENTORY_WITH_VENDOR";
      }
    }
    this.model.is_payable = this.model.source_type === "VENDOR_TRANSFER";
    this.model.items = this.transfer.items as BatchTransfer.NewBatchItem[];
    this.$emit("filterSteps", this.model.source_type === "VENDOR_TRANSFER");
    this.transferChanges();
    if (this.transferTypes) {
      EventBus.$emit("changedtransferTypes", this.transfer);
    }
    // set isResourceReset flag to true when model source(local ) and transfer(global)
    if (this.model.source_type !== this.transfer.source_type) {
      this.isResourceReset = true;
    }
    this.loadProducts();
  }

  /**
   * Validates and go to next step
   */
  public validateAndGo() {
    if (this.nextStepEnabled) {
      if (this.isResourceReset) {
        EventBus.$emit("changeInResource");
        this.isResourceReset = false;
      }
      this.nextStep();
      this.changeBatchComponent();
    }
  }

  /**
   * Emits filterSteps and transferChanges
   */
  public updateShowPrice() {
    this.$emit("filterSteps", this.model.is_payable);
    this.model.items = this.transfer.items as BatchTransfer.NewBatchItem[];
    this.transferChanges();
  }
  public async changeBatchComponent() {
    // set initial value for component
    this.$emit("changeBatchActionComponent", true);
    if (
      this.bioTrackTraceEnabled &&
      this.model.source_type === "EXISTING_INVENTORY" &&
      this.remainingDaysForTrace >= 0 &&
      this.remainingDaysForTrace >= 0 &&
      (this.currentLocation.state!.name === "Connecticut"
        ? this.remainingDaysForTrace <= 30
        : this.remainingDaysForTrace <= 15)
    ) {
      this.$emit("changeBatchActionComponent");
      const response = await NewBatchTransferService.getAllBatches(1, 10, "");
      if (
        response &&
        (response.batch_in_multiple_rooms || response.batch_in_reserved)
      ) {
        this.$modals.load(
          CustomTraceabilityConfirm,
          {
            size: "normal",
            positionX: "center",
            positionY: "center",
            style: "maxWidth: 900px;maxHeight: 730px"
          },
          {
            modalData: {
              titleAvatar: {
                name: "/img/icon_primary_menu_retail@2x.009e06e8.png",
                size: "70"
              },
              title: {
                name: this.$t("biotrack_traceability.name"),
                style: "fontSize:25px ; letter-spacing: 0px;"
              }
            }
          }
        );
      }
    }
    if (this.transferTypes) {
      EventBus.$emit("changedtransferTypes", this.transfer);
    }
  }

  public get nextStepEnabled() {
    return (
      this.model.source_type !== "VENDOR_TRANSFER" ||
      typeof this.model.source_id === "string" ||
      this.model.source_id > -1
    );
  }
  // When vendor is changed assigning back the changed value
  public vendorChange() {
    this.model.items = this.transfer.items as BatchTransfer.NewBatchItem[];
    this.transferChanges();
  }
  // When custom transfer id is changed assigning back the changed value
  public customTransferIdChange() {
    this.model.items = this.transfer.items as BatchTransfer.NewBatchItem[];
    this.transferChanges();
  }

  public daysCounter(days: number) {
    const inventoryStartingDate: Date = this.currentRetailSettings.integrations!
      .biotrack_traceability.starting_inventory_date;
    const date = new Date(inventoryStartingDate);
    const differnceInMS = Date.now() - date.getTime();
    const remainingDays = days - Math.floor(differnceInMS / (1000 * 3600 * 24));
    return remainingDays;
  }
  public get remainingDaysForTrace() {
    const dayCount =
      this.currentLocation.state!.name === "Connecticut"
        ? this.daysCounter(30)
        : this.daysCounter(15);
    return dayCount;
  }
  protected mounted() {
    if (this.bioTrackTraceEnabled) {
      if (this.remainingDaysForTrace >= 0) {
        if (
          this.currentLocation.state!.name === "Connecticut"
            ? this.remainingDaysForTrace <= 30
            : this.remainingDaysForTrace <= 15
        ) {
          this.model.source_type = TRANSFER_SOURCE.EXISTING_INVENTORY;
          this.transferTypes = [
            {
              text: String(
                i18n.t(
                  "batch_transfer_manager_module.labels.create_existing_cannabis_inventory_tracebility"
                )
              ),
              value: "EXISTING_INVENTORY"
            },
            {
              text: String(
                i18n.t(
                  "batch_transfer_manager_module.labels.new/existing_initial_inventory_without_vendor"
                )
              ),
              value: "LOCATION_TRANSFER"
            },
            {
              text: String(
                i18n.t(
                  "batch_transfer_manager_module.labels.new/existing_initial_inventory_from_vendor"
                )
              ),
              value: "VENDOR_TRANSFER"
            }
          ];
        }
      } else {
        this.transferTypes = [
          {
            text: String(
              i18n.t(
                "batch_transfer_manager_module.labels.new/existing_non_cannabis_inventory_without_vendor"
              )
            ),
            value: "LOCATION_TRANSFER"
          },
          {
            text: String(
              i18n.t(
                "batch_transfer_manager_module.labels.new/existing_non_cannabis_inventory_from_vendor"
              )
            ),
            value: "VENDOR_TRANSFER"
          }
        ];
      }
    } else {
      this.transferTypes = [
        {
          text: String(
            i18n.t("batch_transfer_manager_module.labels.existing_inventory")
          ),
          value: "LOCATION_TRANSFER"
        },
        {
          text: String(
            i18n.t(
              "batch_transfer_manager_module.labels.new/existing_inventory_from_vendor"
            )
          ),
          value: "VENDOR_TRANSFER"
        }
      ];
    }
    this.loadVendors();
    this.resetSource();
  }

  protected created() {
    this.$validator.extend("requiredIfVendor", (value: any) => {
      return (
        this.model.source_type === "VENDOR_TRANSFER" && value && value !== -1
      );
    });
  }

  protected isVendor() {
    return this.model.source_type === "VENDOR_TRANSFER";
  }
}
