import { currencyFilter } from "@/filters/currency.filter";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./BatchesList.template.vue";

export enum TABLEACTIONS {
  EDIT,
  DELETE
}
const defaultActions: TABLEACTIONS[] = [TABLEACTIONS.EDIT, TABLEACTIONS.DELETE];
const tableHeaders = [
  {
    text: "product",
    value: "name"
  },
  {
    text: "product_category",
    value: "product_category",
    width: "200"
  },
  {
    text: "batch_type",
    value: "batch_type",
    width: "200"
  },
  {
    text: "Strain",
    value: "strain",
    width: "200"
  },
  {
    text: "quantity",
    value: "quantity"
  },
  {
    text: "sub_total",
    sortable: false,
    value: "sub_total"
  },
  {
    text: "tax",
    sortable: false,
    value: "tax"
  },
  {
    text: "total",
    sortable: false,
    value: "total"
  },
  {
    text: "cost_per_unit",
    sortable: false,
    value: "cost_per_unit",
    width: "120",
    tooltip: "batch_transfer_manager_module.messages.not_accurate_cost"
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

const tableHeadersTraceDisable = [
  {
    text: "product",
    value: "name",
    width: "340"
  },
  {
    text: "product_category",
    value: "product_category",
    width: "200"
  },
  {
    text: "batch_type",
    value: "batch_type",
    width: "200"
  },
  {
    text: "Strain",
    value: "strain",
    width: "200"
  },
  {
    text: "quantity",
    value: "quantity",
    width: "200"
  },
  {
    text: "sub_total",
    sortable: false,
    value: "sub_total"
  },
  {
    text: "tax",
    sortable: false,
    value: "tax"
  },
  {
    text: "total",
    sortable: false,
    value: "total"
  },
  {
    text: "cost_per_unit",
    sortable: false,
    value: "cost_per_unit",
    width: "120",
    tooltip: "batch_transfer_manager_module.messages.not_accurate_cost"
  }
];

@Component({
  mixins: [Template]
})
export default class BatchesList extends Vue {
  /**
   * Getter editable
   */
  public get editable() {
    return this.actions.indexOf(TABLEACTIONS.EDIT) > -1;
  }
  /**
   * Getter removable
   */
  public get removable() {
    return this.actions.indexOf(TABLEACTIONS.DELETE) > -1;
  }

  /**
   * Getter filtered headers
   */
  protected get filteredHeaders() {
    if (this.bioTrackTraceEnabled === true) {
      return tableHeadersTraceDisable.filter(header => {
        return this.cellsToHide.indexOf(header.value) === -1;
      });
    }
    return tableHeaders.filter(header => {
      return this.cellsToHide.indexOf(header.value) === -1;
    });
  }
  /**
   * Getter filtered items
   */
  public get filteredItems() {
    if (this.bioTrackTraceEnabled === true) {
      if (this.noOfStrain === 0) {
        return tableHeadersTraceDisable
          .filter(
            header =>
              this.cellsToHide.indexOf(header.value) === -1 &&
              header.value !== "actions" &&
              header.value !== "strain"
          )
          .map(item => item.value);
      } else {
        return tableHeadersTraceDisable
          .filter(
            header =>
              this.cellsToHide.indexOf(header.value) === -1 &&
              header.value !== "actions"
          )
          .map(item => item.value);
      }
    } else {
      if (this.noOfStrain === 0) {
        return tableHeaders
          .filter(
            header =>
              this.cellsToHide.indexOf(header.value) === -1 &&
              header.value !== "actions" &&
              header.value !== "strain"
          )
          .map(item => item.value);
      } else {
        return tableHeaders
          .filter(
            header =>
              this.cellsToHide.indexOf(header.value) === -1 &&
              header.value !== "actions"
          )
          .map(item => item.value);
      }
    }
  }

  /**
   * Getter table data
   */
  public get tableData() {
    this.fillDataTable();
    this.items.forEach((item: { strain: string }) => {
      if (item.strain && item.strain !== "-") {
        this.noOfStrain++;
      }
    });
    if (this.noOfStrain === 0) {
      this.items.forEach((item: any) => delete item.strain);
    }
    return {
      items: this.items,
      headers: this.headers
    };
  }
  @Prop() public bioTrackTraceEnabled!: boolean;
  @Prop({ default: false }) public hideAction: boolean = false;
  public noOfStrain = 0;
  @Prop() protected batches!: BatchTransfer.Batch[];
  @Prop({ default: () => defaultActions }) protected actions!: TABLEACTIONS[];
  @Prop({ default: () => [] }) protected cellsToHide!: string[];

  protected items: any[] = [];
  protected headers: any[] = [];

  /**
   * Emits batch index to be edited.
   * @param {number} index
   */
  public edit(index: number) {
    this.$emit("edit", index);
  }

  /**
   * Emits remove event with batch index to be removed.
   * @param {number} index
   */
  public remove(index: number) {
    this.$emit("remove", index);
  }

  public getHeaders() {
    let newHeaders: Array<{}> = cloneDeep(this.filteredHeaders);
    if (this.noOfStrain === 0) {
      newHeaders = newHeaders.filter((item: any) => item.value !== "strain");
    }
    return newHeaders;
  }
  protected mounted() {
    if (this.actions.length === 0) {
      this.cellsToHide.push("actions");
    }
    this.fillDataTable();
  }

  /**
   * Fills data table. Parses batches to match table structure.
   */
  protected fillDataTable() {
    const filteredHeaders: Array<{}> = this.filteredHeaders; // code to remove linting Error
    this.headers = filteredHeaders.map((header: any) => {
      header.text = String(this.$t(header.text));
      return header;
    });
    this.items = map(this.batches, batch => {
      return {
        id: batch.id,
        destroy: batch.destroy,
        name: batch.product ? batch.product.name : "",
        sku: batch.sku,
        quantity: batch.quantity,
        sub_total: currencyFilter(batch.prices.subTotal),
        tax: currencyFilter(batch.prices.taxCollected),
        total: currencyFilter(batch.prices.total),
        cost_per_unit: currencyFilter(batch.prices.cost_per_unit),
        product_category:
          batch.product!.category !== null
            ? batch.product!.category!.name
            : "-",
        strain:
          batch.product!.strain !== null ? batch.product!.strain.name : "-",
        batch_type: batch.product!.batch_type!.name
      };
    });
  }
}
