import { render, staticRenderFns } from "./QuoteReviewPrint.template.vue?vue&type=template&id=96a94d6c&scoped=true&"
var script = {}
import style0 from "./QuoteReviewPrint.template.vue?vue&type=style&index=0&id=96a94d6c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96a94d6c",
  null
  
)

export default component.exports