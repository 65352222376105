import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import { OutboundTransferDefault } from "@/components/inventory/BatchTransfer/declarations";
import {
  BatchTransfer,
  TRANSFER_SOURCE,
  TRANSFERABLE_TYPES
} from "@/interfaces/batchTransferManager";
import { Location } from "@/interfaces/location";
import { Vendor } from "@/interfaces/vendor";
import QuoteService from "@/services/BatchTransferManager/Quote.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import OutboundTransferMapper from "../../../EntityMappers/OutboundTransfer.mapper";
import { outboundTransferDetailsModelKeys } from "../../ModelKeys";
import TransferValidators from "../../TransferValidators.mixin";
import Template from "./OutboundTransferDetails.template.vue";

interface StepModel {
  items: BatchTransfer.OutboundTransferItem[];
  is_payable: boolean;
  pricing_enabled: boolean;
  destination_id: number | string;
  destination?: Location | Vendor;
  destination_type?: TRANSFER_SOURCE;
  transferable?: BatchTransfer.Quote;
  transferable_id: number;
  transferable_type?: TRANSFERABLE_TYPES;
}

@Component({
  mixins: [Template]
})
export default class OutboundTransferDetails extends Mixins(
  TransferStep,
  StepsData,
  TransferValidators
) {
  /**
   * Gets used locations
   */
  public get usedLocations(): Array<number | string> {
    return this.collection.reduce(
      (arr: Array<number | string>, transfer) =>
        transfer.destination_type === TRANSFER_SOURCE.LOCATION_TRANSFER
          ? [...arr, transfer.destination_id]
          : arr,
      []
    );
  }
  /**
   * Gets used vendors
   */
  public get usedVendors(): Array<number | string> {
    return this.collection.reduce(
      (arr: Array<number | string>, transfer) =>
        transfer.destination_type === TRANSFER_SOURCE.VENDOR_TRANSFER
          ? [...arr, transfer.destination_id]
          : arr,
      []
    );
  }
  /**
   * Gets used quotes
   */
  public get usedQuotes(): number[] {
    return this.collection.reduce(
      (arr: number[], transfer) =>
        transfer.transferable_type === TRANSFERABLE_TYPES.QUOTE &&
        transfer.transferable_id
          ? [...arr, transfer.transferable_id]
          : arr,
      []
    );
  }

  /**
   * Gets list
   */
  public get list() {
    const lists: { [key: string]: BatchTransfer.List[] } = {
      LOCATION_TRANSFER: this.locations.filter(
        location =>
          this.editing || this.usedLocations.indexOf(location.id) === -1
      ),
      VENDOR_TRANSFER: this.vendors.filter(
        vendor => this.editing || this.usedVendors.indexOf(vendor.id) === -1
      ),
      TRANSFER_FROM_QUOTE: this.quotesList.filter(
        quote => this.editing || this.usedQuotes.indexOf(quote.id) === -1
      )
    };
    return lists[this.typeSelection];
  }

  /**
   * Gets whether is created from quote
   */
  public get isCreatedFromQuote() {
    return this.typeSelection === "TRANSFER_FROM_QUOTE";
  }

  /**
   * Gets destination
   */
  public get destination() {
    return this.model.destination_id;
  }

  /**
   * Sets destination
   */
  public set destination(value: number | string) {
    this.model.destination_id = value;
    this.model.destination = this.list.find(
      (item: BatchTransfer.List) => item.id === value
    ) as Location | Vendor;
    this.transferChanges();
  }

  /**
   * Gets next step enabled
   */
  public get nextStepEnabled() {
    let checkDestId: boolean = false;
    if (
      this.model.destination_type === "VENDOR_TRANSFER" &&
      typeof this.model.destination_id === "string"
    ) {
      checkDestId = true;
    } else if (this.model.destination_type === "LOCATION_TRANSFER") {
      checkDestId = this.model.destination_id > -1;
    }
    return (
      (this.isCreatedFromQuote && this.model.transferable_id > -1) ||
      (!this.isCreatedFromQuote && checkDestId)
    );
  }
  public searchDestinationField = "";
  public searchDestination = debounce(this.find, 500);
  @Prop() public collection!: BatchTransfer.OutboundTransfer[];

  public typeSelection: string = "LOCATION_TRANSFER";
  public modelKeys = outboundTransferDetailsModelKeys;
  public model: StepModel = pick(
    OutboundTransferDefault,
    this.modelKeys
  ) as StepModel;
  public tranferTypeOptions: Array<{ value: string; text: string }> = [];

  /**
   * Sets transfer from quote
   */
  public fromQuoteChanged() {
    this.model.transferable_type = TRANSFERABLE_TYPES.QUOTE;
    // FOR SOME REASON, ALL OF THE ABOVE MODIFICATIONS ARE NOT MAKING COMPUTED PROPERTIES RECALCULATE. NEXT LINE FORCES IT.
    this.model = cloneDeep(this.model);
    this.transferChanges();
  }

  /**
   * Assigns type and set model
   * @param {string} type
   */
  public assignTypeAndSetModel(type: string) {
    this.typeSelection = type;
    this.callbackType[type]("");
    if (type === "TRANSFER_FROM_QUOTE") {
      this.model.destination_type = undefined;
      this.model.transferable_type = TRANSFERABLE_TYPES.QUOTE;
      this.model.destination_id = -1;
      this.model.destination = undefined;
    } else {
      this.model.transferable_type = undefined;
      this.model.destination_type = type as TRANSFER_SOURCE;
      this.model.transferable_id = -1;
      this.model.transferable_type = undefined;
    }
    this.transferChanges();
    this.$emit("resetTransfer");
  }

  /**
   * Creates manifest
   */
  public createManifest() {
    this.$emit("createManifest");
  }
  public async findQuoteAndSetDestination() {
    const quote = await QuoteService.getById(this.model.transferable_id);
    if (quote) {
      this.model.transferable = quote;
      this.model.destination_id = this.model.transferable.destination_id;
      this.model.destination_type = this.model.transferable.destination_type;
      this.model.destination = this.model.transferable.destination;
      this.transferChanges();
    }
  }

  /**
   * Validates and go
   * @returns {void}
   */
  public async validateAndGo() {
    if ((await this.$validator.validateAll("step-1")) && this.nextStepEnabled) {
      if (this.isCreatedFromQuote && !this.model.transferable) {
        try {
          await this.findQuoteAndSetDestination();
          if (this.model.transferable) {
            const mapper = new OutboundTransferMapper();
            this.transferCustomChanges(mapper.convertQuote(
              this.model.transferable
            ) as BatchTransfer.Transfer);
          }
        } catch (e) {
          return;
        }
      }
      this.nextStep();
    }
  }

  /**
   * Emits filterSteps and transferChanges
   */
  public updateShowPrice() {
    this.$emit("filterSteps", this.model.is_payable);
    this.transferChanges();
  }

  /**
   * Finds quote and set destination
   */
  @Watch("searchDestinationField")
  protected searchField(searchString: string) {
    this.searchDestination(searchString, this.callbackType[this.typeSelection]);
  }

  protected mounted() {
    this.tranferTypeOptions = [
      {
        value: TRANSFER_SOURCE.LOCATION_TRANSFER,
        text: this.$tc("batch_transfer_manager_module.labels.location", 1)
      },
      {
        value: TRANSFER_SOURCE.VENDOR_TRANSFER,
        text: this.$tc("batch_transfer_manager_module.labels.vendor", 1)
      },
      {
        value: "TRANSFER_FROM_QUOTE",
        text: this.$tc("batch_transfer_manager_module.labels.from_quote")
      }
    ];
    this.typeSelection = "LOCATION_TRANSFER";
    this.model.items = [];
    this.updateShowPrice();
  }
}
