import {
  BatchTransferActions,
  stepsComponents,
  TransferDefault
} from "@/components/inventory/BatchTransfer/declarations";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { EventBus } from "@/internal";
import RouterUtils from "@/router.utils";
import batchTransferManagerService, {
  BatchTransferManagerService,
  BatchTransferService
} from "@/services/BatchTransferManager/batchTransferManager.service";
import NewBatchTransferService from "@/services/BatchTransferManager/NewBatchTransfer.service";
import { BooleanCheck, Callback, PageNavAction } from "@/types/types";
import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import { Validator } from "vee-validate";
import {
  Component,
  Inject,
  Mixins,
  Prop,
  Provide,
  Vue
} from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchTransferManager.template.vue";
import ComputeTransfer from "./ComputeTransfer.mixin";
import TransferCollectionMixin from "./TransferCollection.mixin";

@Component({
  mixins: [Template],
  components: { ...stepsComponents }
})
export default class BatchTransferManager extends Mixins(
  Vue,
  ComputeTransfer,
  TransferCollectionMixin
) {
  public action: BatchTransfer.Action = { title: "", steps: [] };
  public actionCopy!: BatchTransfer.Action;
  public stepper: number = 0;
  public transfer: BatchTransfer.Transfer = cloneDeep(TransferDefault);
  public transferService!: BatchTransferService | null;
  public loading: boolean = true;
  public type!: string;
  public reseted: number = 0;
  public edited: number = 0;
  public editingTransfer = false;

  @Inject() public $validator!: Validator;
  @Provide() public validator: Validator = this.$validator;

  @Prop() protected transferType!: string;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  protected hasPermission!: BooleanCheck;
  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;

  protected routerUtils = new RouterUtils();
  protected unwatch!: Callback;
  protected changeNewBatchReviewComponent!: string;
  protected loadingBatch!: boolean;

  /**
   * Titles batch transfer manager
   * @param title: string
   * @returns string
   */
  public title(title: string): string {
    return this.loading
      ? ""
      : title
      ? "  " +
        this.$t(`batch_transfer_manager_module.titles.${title}`).toString()
      : "";
  }
  /**
   * Labels batch transfer manager
   * @param {string} label
   * @returns {string} A translated label
   */
  public label(label: string) {
    return this.$t(`batch_transfer_manager_module.labels.${label}`);
  }
  /**
   * Gets steps
   */
  public get steps() {
    return this.action.steps.length > 0 ? this.action.steps : [];
  }

  /**
   * Updates transfer. Handler for transferChanges
   * @param {BatchTransfer.Transfer} transfer
   */
  public updateTransfer(transfer: Partial<BatchTransfer.Transfer>) {
    this.transfer = this.recalculateAll(
      assign(cloneDeep(this.transfer), transfer)
    );
  }

  /**
   * Finishs batch transfer manager
   */
  public async finish() {
    this.$router.push({
      name: "batch-transfer",
      params: { type: this.$route.params.type }
    });
  }

  /**
   * Creates manifest
   */
  public async createManifest() {
    this.$router.push({
      name: "manifest",
      params: { transfers: JSON.stringify(this.transfersCollection) }
    });
  }

  /**
   * Handles save to collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public handleSaveToCollection(transfer: BatchTransfer.Transfer) {
    this.addToCollection(transfer);
    this.stepper = 1;
    this.resetModel();
  }
  /**
   * Handles edit from collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public handleEditFromCollection(transfer: BatchTransfer.Transfer) {
    this.transfer = cloneDeep(transfer);
    this.editModel();
  }
  /**
   * Handles update on collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public handleUpdateOnCollection(transfer: BatchTransfer.Transfer) {
    this.updateOnCollection(transfer);
    this.stepper = 1;
    this.resetModel();
  }
  /**
   * Handles remove from collection
   * @param {BatchTransfer.Transfer} transfer
   */
  public handleRemoveFromCollection(transfer: BatchTransfer.Transfer) {
    this.removeFromCollection(transfer);
    this.resetModel();
  }

  /**
   * Resets model
   */
  public resetModel() {
    this.transfer = cloneDeep(TransferDefault);
    EventBus.$emit("modelReseted", this.transfer);
    this.$emit("filterSteps", this.transfer.is_payable);
  }

  /**
   * Edits model
   */
  public editModel() {
    this.edited++;
  }
  /**
   * Navigates to previous route
   */
  public back() {
    this.$router.push("/inventory/batch/transfer");
  }

  /**
   * Loads type
   */
  protected async loadType() {
    this.type = this.$route.params.type;
    this.transferService = (batchTransferManagerService as BatchTransferManagerService).service(
      this.$route.params.type
    );
    if (this.$route.params.id) {
      const transfer = await this.transferService.getById(
        this.$route.params.id
      );
      this.transfer = this.recalculateAll(transfer);
      this.editingTransfer = true;
    } else {
      this.transfer = cloneDeep(TransferDefault);
      this.editingTransfer = false;
    }
    this.loading = false;
    this.loadSteps();
    this.stepper = 1;
  }

  /**
   * Loads steps
   */
  protected loadSteps() {
    this.action = cloneDeep(BatchTransferActions[this.type]);
    this.actionCopy = cloneDeep(this.action);
  }

  /**
   * Filters steps
   * @param {boolean} enable
   */
  protected filterSteps(enable: boolean) {
    const steps = this.action.steps.filter(
      step =>
        ![
          "OutboundTransferPaymentMethods",
          "InboundTransferPaymentMethods",
          "NewBatchPaymentMethods"
        ].includes(step.component)
    );
    !enable
      ? (this.action.steps = cloneDeep(steps))
      : (this.action.steps = cloneDeep(this.actionCopy.steps));
  }

  /**
   * Loads transfer
   * @returns {Array} Empty array
   */
  protected async loadTransfer() {
    return [];
  }

  protected async changeBatchActionComponent(initial = false) {
    const actionCopyIndex = this.actionCopy.steps.findIndex(
      index => index.label === "batch_detail"
    );
    if (initial) {
      // set inital value for component which is NewBatchBatchDetails
      this.action.steps[actionCopyIndex].component = "NewBatchBatchDetails";
      this.actionCopy.steps[actionCopyIndex].component = "NewBatchBatchDetails";
      return;
    }
    this.action.steps[actionCopyIndex].component = "TraceNewBatchBatchDetails";
    this.actionCopy.steps[actionCopyIndex].component =
      "TraceNewBatchBatchDetails";
  }
  protected mounted() {
    this.unwatch = this.$watch(
      "$route.params",
      () => {
        const check =
          this.$route.name === "transfer-manager"
            ? this.routerUtils.transfersTypePolicies
            : this.routerUtils.transfersTypePoliciesEdit;
        if (!this.hasPermission(check[this.$route.params.type])) {
          this.$router.push({ name: "forbidden" });
        }
        this.loadType();
        this.setPageNav({
          title: this.action ? this.title(this.action.title) : "",
          isLoading: () => this.loading,
          rightActions: {
            generalActions: () => [
              {
                icon: "fal fa-times",
                id: "btn_cancel",
                action: this.back,
                vuetifyProps: () => ({
                  loading: this.loading,
                  fab: true,
                  small: true
                })
              }
            ]
          }
        });
      },
      { immediate: true }
    );
    EventBus.$on("onChangeNewBatchReviewComponent", () => {
      this.changeNewBatchReviewComponent = "TraceNewBatchBatchDetails";
    });
  }

  protected beforeDestroy() {
    if (this.unwatch) {
      this.unwatch();
    }
  }
}
