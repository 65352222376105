export const batchDetailsModelKeys = [
  "items",
  "notes",
  "prices.discount",
  "prices.fee"
];

export const inboundTransferTransferDetailsModelKeys = [
  "pricing_enabled",
  "source_type",
  "source_id",
  "is_payable"
];
export const newBatchTransferDetailsModelKeys = [
  "items",
  "pricing_enabled",
  "source_id",
  "source_type",
  "custom_transfer_id",
  "is_payable"
];

export const outboundTransferDetailsModelKeys = [
  "items",
  "pricing_enabled",
  "destination_id",
  "destination_type",
  "request_on_or_before",
  "is_payable"
];

export const paymentMethodsModelKeys = ["payment_methods_breakdown"];

export const purchaseOrderTransferDetailModelKeys = [
  "pricing_enabled",
  "source_id",
  "source_type",
  "request_on_or_before"
];

export const quoteTransferDetailsModelKeys = [
  "pricing_enabled",
  "destination_id",
  "destination_type",
  "request_on_or_before"
];
