import StepsData from "@/components/inventory/BatchTransfer/BatchTransferManager/StepsData.mixin";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import { QuoteDefault } from "@/components/inventory/BatchTransfer/declarations";
import {
  BatchTransfer,
  SourceType,
  TRANSFER_SOURCE
} from "@/interfaces/batchTransferManager";
import { HelixDatePickerComponent } from "helix-vue-components";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { quoteTransferDetailsModelKeys } from "../../ModelKeys";
import TransferValidators from "../../TransferValidators.mixin";
import Template from "./QuoteTransferDetails.template.vue";
interface StepModel {
  pricing_enabled: boolean;
  destination_id: number;
  destination_type: TRANSFER_SOURCE;
  request_on_or_before: string;
}
@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class QuoteTransferDetails extends Mixins(
  TransferStep,
  StepsData,
  TransferValidators
) {
  /**
   * Gets destination label
   */
  get destinationLabel() {
    const labels: { [key: string]: string } = {
      VENDOR_TRANSFER: this.$tc(
        "batch_transfer_manager_module.labels.vendor",
        1
      ),
      LOCATION_TRANSFER: this.$tc(
        "batch_transfer_manager_module.labels.location",
        1
      )
    };
    return labels[(this.model as BatchTransfer.Quote).destination_type];
  }
  /**
   * Gets list
   */
  get list() {
    return (this.model as BatchTransfer.Quote).destination_type ===
      TRANSFER_SOURCE.VENDOR_TRANSFER
      ? this.vendors
      : this.locations;
  }
  /**
   * Gets next step enabled
   */
  public get nextStepEnabled() {
    if (
      this.model.destination_type === "VENDOR_TRANSFER" &&
      typeof this.model.destination_id === "string"
    ) {
      return true;
    }
    return this.model.destination_id > -1;
  }
  public searchDestinationField = "";
  public searchDestination = debounce(this.find, 500);
  public modelKeys = quoteTransferDetailsModelKeys;
  public model: StepModel = pick(QuoteDefault, this.modelKeys) as StepModel;
  public sourceTypes: SourceType[] = [];

  /**
   * Validates and go
   * @param {string} scope VeeValidate scope
   */
  public async validateAndGo(scope: string) {
    if ((await this.$validator.validateAll(scope)) && this.nextStepEnabled) {
      this.nextStep();
    }
  }

  /**
   * Updates transfer type
   */
  public updateTransferType() {
    this.model.destination_id = -1;
    this.callbackType[this.model.destination_type]("");
    this.transferChanges();
  }

  public mounted() {
    this.sourceTypes = [
      {
        value: "VENDOR_TRANSFER",
        text: this.$tc("batch_transfer_manager_module.labels.vendor")
      },
      {
        value: "LOCATION_TRANSFER",
        text: this.$tc("batch_transfer_manager_module.labels.location")
      }
    ];
  }
  @Watch("searchDestinationField")
  protected searchField(searchString: string) {
    this.searchDestination(
      searchString,
      this.callbackType[this.model.destination_type]
    );
  }
}
