import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { EventBus } from "@/event-bus";
import { Batch } from "@/interfaces/batch";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { TRANSFER_SOURCE } from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import { NewBatchTransferService } from "@/services/BatchTransferManager/NewBatchTransfer.service";
import { messagesService } from "@/services/messages.service";
import flatten from "lodash/flatten";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import TraceBatchesList from "../../../BuildBlocks/TraceBatchesList/TraceBatchesList.component";
import Template from "./NewBatchReview.template.vue";
interface Pagination {
  page: number;
  rowsPerPage: number;
  sortBy?: "";
  descending?: boolean;
  totalItems: number;
  pagesReached?: number;
}

@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "trace-batch-list": TraceBatchesList,
    "payment-breakdown-summary": PaymentBreakdownSummary
  }
})
export default class NewBatchReview extends Mixins(TransferStep) {
  /**
   * gitGets show table label
   */
  get showTableLabel() {
    return this.transfer.pricing_enabled
      ? ["actions"]
      : ["actions", "total", "sub_total", "tax", "price_per_unit"];
  }
  /**
   * Saves new batch review
   * @returns {void}
   */
  get isVendor() {
    return this.transfer.source_type === TRANSFER_SOURCE.VENDOR_TRANSFER;
  }
  // public batchDetail
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  // @ts-ignore
  @Prop() public transfer!: BatchTransfer.NewBatch;

  public get traceEnableWindowPeriod() {
    return (
      this.bioTrackTraceEnabled &&
      (this.transfer.sourceable_transfer_type ===
        "INITIAL_INVENTORY_WITHOUT_VENDOR" ||
        this.transfer.sourceable_transfer_type ===
          "INITIAL_INVENTORY_WITH_VENDOR")
    );
  }
  // @ts-ignore
  @Prop() public transfer!: BatchTransfer.NewBatch;
  public progressBarPercentage!: number;
  public errorMessage!: string;
  public isCannabisBatchesExists = true;
  @Prop() public changeNewBatchReviewComponent!: string;
  public pagination: Pagination = { rowsPerPage: 10, totalItems: 0, page: 1 };
  public loading = false;
  public batchItems!: Batch[];

  public async connectionModalCallback() {
    const batchItems = await this.getAllBatches("No", true);
    const data: any = flatten(
      batchItems.data.map((item: any) => {
        return {
          batch_uid: item.batch_uid,
          invtype: item.inv_type && item.inv_type.inventory_type_id,
          quantity: item.in_store_quantity_value,
          strain:
            item.product_variant.strain && item.product_variant.strain.name
        };
      })
    );
    const resp = await (this
      .service as NewBatchTransferService).currentExistingInventory(data);
    return resp;
  }
  public errorMessageFormatter(msg: string) {
    if ((msg.includes(":") || msg.includes(": ")) && msg.includes(".")) {
      const data = ": " + msg.split(":")[1].split(".")[0];
      return data;
    } else if (
      !(msg.includes(":") || msg.includes(": ")) &&
      msg.includes(".")
    ) {
      const data = msg.split(".")[0];
      return data;
    } else if (
      (msg.includes(":") || msg.includes(": ")) &&
      !msg.includes(".")
    ) {
      const data = ": ";
      return data;
    }
    return msg;
  }

  public async save() {
    if (this.changeNewBatchReviewComponent === "TraceNewBatchBatchDetails") {
      await this.callPopups();
      const response = await this.connectionModalCallback();
      response.data.error = response.data;
      if (response.data.status === "success") {
        EventBus.$emit("onChangeCustomApiCall", {
          changeNewBatchReviewComponent: this.changeNewBatchReviewComponent,
          transactionId: response.data.data[0].transaction_id
        });
        this.successProgressBar();
      } else {
        this.errorPopup(response);
      }
      this.nextStep();
      return;
    } else {
      this.disabled = true;
      // Payment methods validation
      let totalAmount: number = 0;
      this.transfer.payment_methods_breakdown.payment_and_methods.forEach(
        payment => {
          totalAmount += +payment.applied;
        }
      );
      if (this.transfer.payment_methods_breakdown.initial < totalAmount) {
        messagesService.renderWarningMessage(
          "batch_transfer_manager_module.messages.the_entered_amount_is_greater_than_the_total"
        );
        this.disabled = false;
        return;
      }
      if (this.traceEnableWindowPeriod && this.isCannabisBatchesExists) {
        this.callPopups();
        const response = await (this
          .service as NewBatchTransferService).createBioTrackEnabled(
          this.transfer
        );
        if (response.data.status === "success") {
          EventBus.$emit(
            "newBatchPrintResponse",
            response.data.data.transfer_items
          );
          this.successProgressBar();
          this.nextStep();
        } else {
          this.errorPopup(response);
        }
        return;
      }
      const isValid = await this.$validator.validateAll("inbound_transfer");
      if (isValid) {
        this.transfer.source_id = String(this.transfer.source_id);
        const resp = await (this.service as NewBatchTransferService).create(
          this.transfer
        );
        if (!resp) {
          this.disabled = false;
          return;
        }
        EventBus.$emit("newBatchPrintResponse", resp.data.data.transfer_items);
      } else {
        messagesService.showMessage(
          "fas fa-exclamation-triangle",
          "batch_transfer_manager_module.messages.whole_process_failed",
          "red"
        );
        this.disabled = false;
      }
    }
    this.nextStep();
  }
  public async callPopups() {
    this.progressBarPopup();
  }
  public randomInteger(min: number, max: number) {
    return Math.round((Math.random() * (max - min + 1) + min) * 10);
  }
  public successProgressBar() {
    this.progressBarPercentage = 100;
    EventBus.$emit("increaseProgressBarPercentage", this.progressBarPercentage);
    setTimeout(() => {
      EventBus.$emit("removePopup", true);
    }, 400);
  }
  public errorPopup(response: any) {
    this.progressBarPercentage = this.randomInteger(4, 6);
    EventBus.$emit("increaseProgressBarPercentage", this.progressBarPercentage);
    this.errorMessage = response.data.error.message;
    const formattedMessage = this.errorMessageFormatter(this.errorMessage);
    setTimeout(() => {
      EventBus.$emit("removePopup", true);
      this.showErrorPopUp(formattedMessage).then(value => {
        if (value) {
          this.nextStep();
          this.callNonCannabis();
        }
      });
    }, 100);
  }
  public async callNonCannabis() {
    const nonCannabisBatches = this.transfer.items.filter(item => {
      if (item.product!.marijuana === 0) {
        return item;
      }
    });
    this.transfer!.items = nonCannabisBatches;
    EventBus.$emit("loadingPrintLabels", true);
    const nonCannabisBatchesResponse =
      nonCannabisBatches.length &&
      (await (this.service as NewBatchTransferService).createBioTrackEnabled(
        this.transfer
      ));
    EventBus.$emit("loadingPrintLabels", false);
    EventBus.$emit(
      "newBatchPrintResponse",
      nonCannabisBatchesResponse.data.data.transfer_items
    );
  }
  public mounted() {
    EventBus.$on(
      "isCannabisBatches",
      (value: boolean) => (this.isCannabisBatchesExists = value)
    );
  }

  public async renderPopupModal(
    titleName: string,
    messageName: string,
    descriptionName: string,
    isAcceptButton: boolean,
    isCancleButton: boolean,
    acceptButtonValue?: string,
    progressBar?: boolean
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: isAcceptButton
                ? "/img/icon_primary_menu_retail@2x.009e06e8.png"
                : "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            title: {
              name: this.$t(titleName),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: this.$t(messageName),
              style: "fontSize:28px ; fontWeight:600"
            },
            description: {
              name: this.$t(descriptionName),
              style: "fontSize:23px"
            },
            acceptButton: isAcceptButton,
            cancelButton: isCancleButton,
            progressBar,
            acceptButtonValue
          }
        }
      )
      .catch(() => false)) as boolean;
    return confirm;
  }
  protected async onChangePagination(pagination: Pagination) {
    this.pagination = pagination;
    await this.getAllBatches();
  }

  protected async getAllBatches(
    isPaginate: string = "yes",
    paginationNotRequired?: boolean
  ) {
    this.loading = true;
    const response = await (this
      .service as NewBatchTransferService).getAllBatches(
      this.pagination.page,
      this.pagination.rowsPerPage,
      this.pagination.sortBy,
      this.pagination.descending,
      "filterbatches",
      isPaginate
    );
    if (response && !paginationNotRequired) {
      this.batchItems = response.data.data;
      this.pagination.totalItems = response.data.total;
    }
    this.loading = false;
    return response;
  }

  private async progressBarPopup() {
    if (await this.$validator.validateAll()) {
      return await this.renderPopupModal(
        "biotrack_traceability.biotrack_traceability",
        "biotrack_traceability.creating_existing_inventory",
        "biotrack_traceability.waiting_response",
        false,
        false,
        "",
        true
      );
    }
  }
  private async showErrorPopUp(errorMessage: string) {
    if (await this.$validator.validateAll()) {
      return await this.renderPopupModal(
        "biotrack_traceability.biotrack_traceability",
        "biotrack_traceability.new_batch_error",
        i18n.t("biotrack_traceability.new_batch_error_response") +
          ` : ${errorMessage}`,
        true,
        false,
        "OK"
      );
    }
  }
}
