import InboundBatchList from "@/components/inventory/BatchTransfer/BatchTransferManager/InboundTransfer/InboundBatchList/InboundBatchList.component";
import TransferStep from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferStep.mixin";
import BatchesList from "@/components/inventory/BatchTransfer/BuildBlocks/BatchesList/BatchesList.component";
import PaymentBreakdownSummary from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdownSummary/PaymentBreakdownSummary.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import { EventBus } from "@/event-bus";
import {
  BatchTransfer,
  TRANSFER_SOURCE
} from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import { InboundTransferService } from "@/services/BatchTransferManager/InboundTransfer.service";
import { messagesService } from "@/services/messages.service";
import cloneDeep from "lodash/cloneDeep";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./InboundTransferCompleteTransfer.template.vue";

@Component({
  mixins: [Template],
  components: {
    "batches-list": BatchesList,
    "inbound-batch-list": InboundBatchList,
    "payment-breakdown-summary": PaymentBreakdownSummary
  }
})
export default class InboundTransferCompleteTransfer extends Mixins(
  TransferStep
) {
  /**
   * Gets show table label
   */
  get showTableLabel() {
    return this.transfer.pricing_enabled
      ? ["actions"]
      : ["actions", "total", "sub_total", "tax", "price_per_unit"];
  }
  @Prop() public transfer!: BatchTransfer.InboundTransfer;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public progressBarPercentage!: number;
  public batchDetails = false;
  public errorMessage!: string;
  public transactionId: any;
  public apiSuccess = false;
  /**
   * Creates or updates an inbound transfer
   */
  public async save() {
    this.disabled = true;
    const isValid = await this.$validator.validateAll("inbound_transfer");
    if (isValid) {
      let res;
      if (this.$route.params.id) {
        res = await (this.service as InboundTransferService).update(
          this.transfer
        );
      } else {
        if (
          this.bioTrackTraceEnabled &&
          (this.transfer.source_type === TRANSFER_SOURCE.LOCATION_TRANSFER ||
            this.transfer.source_type ===
              TRANSFER_SOURCE.CANNABIS_VENDOR_TRANSFER)
        ) {
          this.transfer.items.map((item: any, itemIndex: number) => {
            this.transfer.data.data.map((price: any, priceIndex: number) => {
              if (itemIndex === priceIndex) {
                item.prices = price.prices;
                item.rooms = price.rooms;
                item.lab_results = price.lab_results;
              }
            });
          });
          await this.callPopups();
          let batchesNotAdded = cloneDeep(this.transfer.data.data);
          batchesNotAdded = batchesNotAdded.filter((item: any) => {
            if (item.product.marijuana === 1) {
              return item;
            }
          });
          const obj = batchesNotAdded.map(
            (item: {
              id: number | string;
              barcode_id: string;
              quantity: string;
              product: { unit: string };
            }) => {
              return {
                barcodeid: item.id || item.barcode_id,
                quantity: item.quantity,
                uom: item.product.unit
              };
            }
          );
          const firstAPI = await (this
            .service as InboundTransferService).getlocationTransfer(obj);

          this.transactionId = firstAPI.data.data.transactionid;
          if (firstAPI.data.status === "success") {
            this.progressBarPercentage = 100;
            EventBus.$emit(
              "increaseProgressBarPercentage",
              this.progressBarPercentage
            );
            setTimeout(() => {
              EventBus.$emit("removePopup", true);
            }, 500);
          } else {
            this.errorPopup(firstAPI);
          }
          const updatedPriceObject = this.transfer.items.filter(
            (item: { is_return?: number; priceUpdated?: boolean }) =>
              item.is_return && item.priceUpdated
          );
          const priceObj = updatedPriceObject.map(
            (item: {
              barcode_id?: string;
              id?: number | null;
              sale_price?: string | number;
            }) => {
              return {
                barcode_id: item.barcode_id || item.id,
                transaction_id: this.transactionId,
                price: item.sale_price
              };
            }
          );
          if (priceObj.length) {
            const secondAPI = await (this
              .service as InboundTransferService).getPriceUpdate(priceObj);
            if (secondAPI.data.status === "success") {
              this.apiSuccess = true;
            }
          }
          // @ts-ignore --> Diference between API and front end
          const filteredItems = [...this.transfer.items].filter((item: any) => {
            if (item.product.marijuana && Number(item.quantity) !== 0) {
              return item;
            } else if (!item.product.marijuana) {
              return item;
            }
          });
          const data = cloneDeep(this.transfer);
          data.items = filteredItems;
          if (firstAPI.data.status === "success" || this.apiSuccess) {
            if (data.items.length) {
              res = await (this.service as InboundTransferService).create(data);
            } else {
              setTimeout(() => {
                this.$router.push({
                  name: "batch-transfer",
                  params: { type: "inbound-transfer" }
                });
              }, 500);
            }
          }
        } else {
          res = await (this.service as InboundTransferService).create(
            cloneDeep(this.transfer)
          );
        }
      }
      if (!res) {
        this.disabled = false;
        return;
      }
      EventBus.$emit("BatchTransferListTouched", "inbound-transfer");
      EventBus.$emit(
        "inboundTransferPrintLabels",
        res.data.data.transfer_items
      );
    } else {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.whole_process_failed")
        ),
        "red"
      );
      this.disabled = false;
    }
    this.nextStep();
  }

  public async callPopups() {
    this.progressBarPopup();
  }
  public randomInteger(min: number, max: number) {
    return Math.round((Math.random() * (max - min + 1) + min) * 10);
  }
  public errorMessageFormatter(msg: string) {
    if ((msg.includes(":") || msg.includes(": ")) && msg.includes(".")) {
      const data = ": " + msg.split(":")[1].split(".")[0];
      return data;
    } else if (
      !(msg.includes(":") || msg.includes(": ")) &&
      msg.includes(".")
    ) {
      const data = msg.split(".")[0];
      return data;
    } else if (
      (msg.includes(":") || msg.includes(": ")) &&
      !msg.includes(".")
    ) {
      const data = ": ";
      return data;
    }
    return msg;
  }

  public async renderPopupModal(
    titleName: string,
    messageName: string,
    descriptionName: string,
    isAcceptButton: boolean,
    isCancleButton: boolean,
    acceptButtonValue?: string,
    progressBar?: boolean
  ) {
    const confirm = (await this.$modals
      .load(
        TraceabilityConfirm,
        { size: "normal", positionX: "center", positionY: "center" },
        {
          modalData: {
            titleAvatar: {
              name: isAcceptButton
                ? "/img/icon_primary_menu_retail@2x.009e06e8.png"
                : "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
              size: "100"
            },
            title: {
              name: this.$t(titleName),
              style: "fontSize:35px ; letter-spacing: 0px;"
            },
            message: {
              name: this.$t(messageName),
              style: "fontSize:28px ; fontWeight:600"
            },
            description: {
              name: this.$t(descriptionName),
              style: "fontSize:23px"
            },
            acceptButton: isAcceptButton,
            cancelButton: isCancleButton,
            progressBar,
            acceptButtonValue
          }
        }
      )
      .catch(() => false)) as boolean;
    return confirm;
  }
  public successProgressBar() {
    this.progressBarPercentage = 100;
    EventBus.$emit("increaseProgressBarPercentage", this.progressBarPercentage);
    setTimeout(() => {
      EventBus.$emit("removePopup", true);
    }, 2100);
  }
  public errorPopup(response: any) {
    this.progressBarPercentage = this.randomInteger(4, 6);
    EventBus.$emit("increaseProgressBarPercentage", this.progressBarPercentage);
    this.errorMessage = response.data.data;
    const formattedMessage = this.errorMessageFormatter(this.errorMessage);
    setTimeout(() => {
      EventBus.$emit("removePopup", true);
      this.showErrorPopUp(formattedMessage);
    }, 100);
  }
  private async progressBarPopup() {
    if (await this.$validator.validateAll()) {
      return await this.renderPopupModal(
        "biotrack_traceability.biotrack_traceability",
        "biotrack_traceability.inbound_transfer_from_traceability",
        "biotrack_traceability.waiting_response",
        false,
        false,
        "",
        true
      );
    }
  }
  private async showErrorPopUp(errorMessage: string) {
    if (await this.$validator.validateAll()) {
      return await this.renderPopupModal(
        "biotrack_traceability.biotrack_traceability",
        "biotrack_traceability.new_batch_error",
        i18n.t("biotrack_traceability.inbound_transfer_error_response") +
          ` : ${errorMessage}`,
        true,
        false,
        "OK"
      );
    }
  }
}
