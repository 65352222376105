import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./BatchDetailsSection.template.vue";

@Component({
  mixins: [Template]
})
export default class BatchDetailsSectionComponent extends Vue {
  @Prop({ default: false }) public expandable!: boolean;
  @Prop({ default: "" }) public name!: string;
  @Prop({ default: false }) public opened!: boolean;
  public status: number | null = 0;

  protected mounted() {
    this.status = this.opened ? 0 : null;
  }
}
