import { TransferDefault } from "@/components/inventory/BatchTransfer/declarations";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES
} from "@/interfaces/batchTransferManager";
import cloneDeep from "lodash/cloneDeep";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./PaymentBreakdownSummary.template.vue";

@Component({
  mixins: [Template]
})
export default class PaymentBreakdownSummary extends Vue {
  @Inject("validator") public $validator!: Validator;

  @Prop({ default: false }) public showPurchaseOrderPrice!: boolean;
  @Prop({ default: true }) public editable!: boolean;
  @Prop({ default: () => [] })
  public notes!: BatchTransfer.TransferNote[];
  @Prop({ default: null })
  public payments!: BatchTransfer.PaymentMethodsBreakdown;
  public options!: Array<{ label: string; value: string }>;
  @Prop({ required: false })
  public paymentBreakdownData!: BatchTransfer.TransferPrices;

  public model: {
    prices: BatchTransfer.TransferPrices;
    notes: BatchTransfer.TransferNote[];
  } = { prices: TransferDefault.prices, notes: TransferDefault.notes };

  public discountModel: BatchTransfer.TransferFlyingDiscount = {
    amount: 0,
    description: "",
    type: DISCOUNT_FEE_TYPES.PERCENTAGE,
    postTaxDiscount: true,
    value: 0
  };
  public feeModel: BatchTransfer.TransferFlyingFee = {
    amount: 0,
    description: "",
    type: DISCOUNT_FEE_TYPES.PERCENTAGE,
    postTaxFee: true,
    value: 0
  };

  public editingNote: boolean = false;
  public editingDiscount: boolean = false;
  public editingFee: boolean = false;

  public discountButtonLabel: string = "";
  public feeButtonLabel: string = "";

  /**
   * Emits change event
   */
  public onChange() {
    this.$emit("change", this.model);
  }

  /**
   * Updates note
   */
  public updateNote() {
    this.editingNote = false;
    this.onChange();
  }

  /**
   * Validates discount form
   */
  public async validateDiscount() {
    if (await this.$validator.validateAll("order-discount")) {
      this.editingDiscount = false;
      this.model.prices.discount = cloneDeep(this.discountModel);
      this.onChange();
    }
  }
  /**
   * Validates fee form
   */
  public async validateFee() {
    if (await this.$validator.validateAll("order-fee")) {
      this.editingFee = false;
      this.model.prices.fee = cloneDeep(this.feeModel);
      this.onChange();
    }
  }

  public editDiscount() {
    if (this.editable) {
      this.editingDiscount = !this.editingDiscount;
    }
  }

  public editFee() {
    if (this.editable) {
      this.editingFee = !this.editingFee;
    }
  }

  /**
   * Watchs payment breakdown prices
   * @param {BatchTransfer.TransferPrices} value
   */
  @Watch("paymentBreakdownData")
  protected onPricesChange(value: BatchTransfer.TransferPrices) {
    this.model.prices = value
      ? cloneDeep(value)
      : { ...TransferDefault.prices };
    this.discountModel = cloneDeep(this.model.prices.discount);
    this.feeModel = cloneDeep(this.model.prices.fee);
  }

  /**
   * Watchs payment breakdown notes
   * @param {BatchTransfer.TransferNote[]} value
   */
  @Watch("notes")
  protected onNoteChange(value: BatchTransfer.TransferNote[]) {
    this.model.notes =
      value && value.length ? cloneDeep(value) : [...TransferDefault.notes];
  }

  protected mounted() {
    this.options = [
      { label: "$", value: DISCOUNT_FEE_TYPES.FIXED },
      { label: "%", value: DISCOUNT_FEE_TYPES.PERCENTAGE }
    ];

    this.discountModel = cloneDeep(this.model.prices.discount);
    this.feeModel = cloneDeep(this.model.prices.fee);
  }
}
