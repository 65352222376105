import PaymentBreakdown from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdown/PaymentBreakdown.component";
import { NewBatchDefault } from "@/components/inventory/BatchTransfer/declarations";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { paymentMethodsModelKeys } from "../../ModelKeys";
import TransferStep from "../../TransferStep.mixin";
import Template from "./NewBatchPaymentMethods.template.vue";
interface StepModel {
  payment_methods_breakdown: BatchTransfer.PaymentMethodsBreakdown;
}

@Component({
  mixins: [Template],
  components: {
    "payment-breakdown": PaymentBreakdown
  }
})
export default class NewBatchPaymentMethods extends Mixins(TransferStep) {
  @Prop() public transfer!: BatchTransfer.NewBatch;
  public modelKeys = paymentMethodsModelKeys;
  public model: StepModel = pick(NewBatchDefault, this.modelKeys) as StepModel;

  /**
   * Gets payments
   */
  public get payments() {
    return this.transfer.payment_methods_breakdown;
  }

  /**
   * Emits changes on payment methods.
   * @param {BatchTransfer.PaymentMethodsBreakdown} payments
   */
  public paymentsChange(payments: BatchTransfer.PaymentMethodsBreakdown) {
    this.model = { payment_methods_breakdown: cloneDeep(payments) };
    this.transferChanges();
  }
  protected mounted() {
    this.model = {
      payment_methods_breakdown: {
        ...this.model.payment_methods_breakdown,
        ...cloneDeep(this.transfer.payment_methods_breakdown)
      }
    };
  }
}
